
import { Options, Vue } from "vue-class-component";
import {
  DryGasSealType,
  EquipmentComment,
  FailureType,
  InformationType,
} from "../../types/equipment-comment";
import { EquipmentSystem } from "../../types/equipment-model";
import EquipmentStatusSelector from "../equipment-status/EquipmentStatusSelector.vue";
import QualityKpi from "./QualityKpi.vue";
import { formatDateWithoutHours } from "../../helpers/equipment-status-helpers";
import { mapGetters } from "vuex";
import { Equipment } from "@/types/equipment";
import { convertEnumFailureType } from "@/helpers/equipment-comment-helper";
import _ from "lodash";

@Options({
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
  },
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  components: {
    EquipmentStatusSelector,
    QualityKpi,
  },
  name: "StatusCurrentCard",
})
export default class StatusCard extends Vue {
  informationType = InformationType;
  equipmentSystem = EquipmentSystem;
  dryGasSealType = DryGasSealType;
  getEquipmentDetails!: Equipment;
  failureTypologies?: string;
  informationTypeEnum = InformationType;

  formatDate(date: Date): string {
    return formatDateWithoutHours(date);
  }

  get getLastFailureType(): string | undefined {
    const commentsFiltered = _.orderBy(
      _.filter(
        this.getEquipmentDetails?.equipmentComments,
        (comment: EquipmentComment) => {
          return comment.informationType == this.informationTypeEnum.OIL;
        }
      ),
      "date",
      "desc"
    );
    if (commentsFiltered.length > 0 && commentsFiltered[0].failureType) {
      const failureTypes = commentsFiltered[0].failureType?.map(
        (failureType: FailureType) => {
          return convertEnumFailureType(failureType);
        }
      );
      this.failureTypologies = failureTypes?.join(", ");
    } else {
      this.failureTypologies = "No failure identified";
    }
    return this.failureTypologies;
  }
}
