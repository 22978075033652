import { Equipment } from "@/types/equipment";
import { EquipmentComment } from "@/types/equipment-comment";
import { EquipmentCommentFile } from "@/types/equipment-comment-file";
import { EquipmentLink } from "@/types/equipment-link";
import { EquipmentMlStatus, FeedbackEnum } from "@/types/equipment-ml-status";
import { EquipmentStatus, EquipmentStatuses } from "@/types/equipment-status";
import { EquipmentOffShore } from "@/types/quick-dashboard-equipment";
import { RealAlertValues } from "@/types/real-alert-values";
import { StatusEventEnum } from "@/types/status-events";
import axios, { AxiosResponse } from "axios";
import moment, { Moment } from "moment-timezone";
import _ from "lodash";
import { treatErrors } from "@/helpers/http-status-helper";
import { EquipmentParamDetails } from "@/types/equipment-tree";
import { AutomaticStatusType } from "@/types/automatic-status";

export default class EquipmentApi {
  static async getEquipmentList(
    countryBloc: string,
    site: string
  ): Promise<{ equipments: Equipment[] | undefined; httpStatus: number }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment/${countryBloc}/${site}`
      );
      return {
        equipments: _.orderBy(
          response.data,
          [
            "criticality",
            "equipmentModel.system",
            "equipmentModel.name",
            "tag",
          ],
          ["asc", "asc", "asc", "asc"]
        ),
        httpStatus: response.status,
      };
    } catch (err: any) {
      return { equipments: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async getEquipmentListForStats(
    fromDate: Date,
    toDate: Date,
    countryBloc: string,
    site: string
  ): Promise<{ equipments: Equipment[] | undefined; httpStatus: number }> {
    try {
      const timezone = moment.tz.guess();
      const response = await axios.get(
        `/api/v1/equipment/reporting/${countryBloc}/${site}`,
        {
          params: { timezone, fromDate, toDate },
        }
      );

      return {
        equipments: _.orderBy(
          response.data,
          [
            "criticality",
            "equipmentModel.system",
            "equipmentModel.name",
            "tag",
          ],
          ["asc", "asc", "asc", "asc"]
        ),
        httpStatus: response.status,
      };
    } catch (err: any) {
      return { equipments: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async getEquipmentBasicInfos(
    equipmentModelName: string,
    equipmentTag: string,
    countryBloc: string,
    site: string
  ): Promise<{ equipment: Equipment | undefined; status: number }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment/${countryBloc}/${site}/${equipmentModelName}/${equipmentTag}`
      );
      return { equipment: response.data, status: response.status };
    } catch (err: any) {
      return { equipment: undefined, status: treatErrors(err) };
    }
  }

  static async getEquipmentStatuses(
    id: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentStatuses: EquipmentStatus[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-status/all/${countryBloc}/${site}/equipment/${id}`
      );
      return { equipmentStatuses: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentStatuses: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async getEquipmentStatusesParameters(
    id: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentStatusesParam: EquipmentParamDetails | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-status/parameters/${countryBloc}/${site}/equipment/${id}`
      );
      return {
        equipmentStatusesParam: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        equipmentStatusesParam: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async getEquipmentComments(
    id: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentComments: EquipmentComment[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-comment/all/${countryBloc}/${site}/equipment/${id}`
      );
      return { equipmentComments: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentComments: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async getEquipmentLinks(
    id: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentLinks: EquipmentLink[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-link/all/${countryBloc}/${site}/equipment/${id}`
      );
      return { equipmentLinks: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentLinks: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async addEquipmentStatus(
    status: EquipmentStatuses,
    equipmentId: number,
    date: Moment,
    event: StatusEventEnum,
    equipmentMajorFailure: boolean | undefined,
    gasOrOilLeak: boolean | undefined,
    fireConfirmed: boolean | undefined,
    equipmentDetailsId: number | undefined,
    tradeTypeId: number | undefined,
    productionShortfall: number | undefined,
    waterInjectShortfall: number | undefined,
    flaring: number | undefined,
    commentId: number | undefined,
    serialNumber: string | null,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentStatus: EquipmentStatus | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.post(
        `/api/v1/equipment-status/create/${countryBloc}/${site}/equipment/${equipmentId}`,
        {
          date,
          status,
          event,
          equipmentMajorFailure,
          gasOrOilLeak,
          fireConfirmed,
          equipmentDetailsId,
          tradeTypeId,
          productionShortfall,
          waterInjectShortfall,
          flaring,
          commentId,
          serialNumber,
        }
      );
      return { equipmentStatus: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentStatus: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async updateEquipmentStatus(
    id: number,
    status: EquipmentStatuses,
    date: Moment,
    event: StatusEventEnum,
    equipmentMajorFailure: boolean | undefined,
    gasOrOilLeak: boolean | undefined,
    fireConfirmed: boolean | undefined,
    equipmentDetailsId: number | undefined,
    tradeTypeId: number | undefined,
    productionShortfall: number | undefined,
    waterInjectShortfall: number | undefined,
    flaring: number | undefined,
    commentId: number | undefined,
    serialNumber: string | null,
    countryBloc: string,
    site: string,
    automaticStatus: boolean
  ): Promise<{
    updatedEquipmentStatus: EquipmentStatus | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.put(
        `/api/v1/equipment-status/update/${countryBloc}/${site}/${id}`,
        {
          date,
          status,
          event,
          equipmentMajorFailure,
          gasOrOilLeak,
          fireConfirmed,
          equipmentDetailsId,
          tradeTypeId,
          productionShortfall,
          waterInjectShortfall,
          flaring,
          commentId,
          serialNumber,
          automaticStatus,
        }
      );
      return {
        updatedEquipmentStatus: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        updatedEquipmentStatus: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async deleteEquipmentStatus(
    id: number,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/equipment-status/delete/${countryBloc}/${site}/${id}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async addEquipmentLink(
    equipmentLink: EquipmentLink,
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<{ equipmentLink: EquipmentLink | undefined; httpStatus: number }> {
    try {
      const response = await axios.post(
        `/api/v1/equipment-link/create/${countryBloc}/${site}/equipment/${equipmentId}`,
        {
          link: equipmentLink.link,
          title: equipmentLink.title,
        }
      );
      return { equipmentLink: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentLink: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async updateEquipmentLink(
    equipmentLink: EquipmentLink,
    countryBloc: string,
    site: string
  ): Promise<{ equipmentLink: EquipmentLink | undefined; httpStatus: number }> {
    try {
      const response = await axios.put(
        `/api/v1/equipment-link/update/${countryBloc}/${site}/${equipmentLink.id}`,
        {
          link: equipmentLink.link,
          title: equipmentLink.title,
        }
      );
      return { equipmentLink: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentLink: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async deleteEquipmentLink(
    equipmentLink: EquipmentLink,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/equipment-link/delete/${countryBloc}/${site}/${equipmentLink.id}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async addEquipmentComment(
    equipmentComment: EquipmentComment,
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentComment: EquipmentComment | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.post(
        `/api/v1/equipment-comment/create/${countryBloc}/${site}/equipment/${equipmentId}`,
        {
          date: equipmentComment.date,
          comment: equipmentComment.comment,
          woNumber: equipmentComment.woNumber,
          woStatus: equipmentComment.woStatus,
          equipmentCommentFiles: equipmentComment.equipmentCommentFiles,
          author: equipmentComment.author,
          informationType: equipmentComment.informationType,
          analysisType: equipmentComment.analysisType,
          oilType: equipmentComment.oilType,
          dryGasSealComponent: equipmentComment.dryGasSealComponent,
          qualityKpi: equipmentComment.qualityKpi,
          elecMoistureWater: equipmentComment.elecMoistureWater,
          elecDissolvedGasHydrogen: equipmentComment.elecDissolvedGasHydrogen,
          elecDissolvedGasMethane: equipmentComment.elecDissolvedGasMethane,
          elecDissolvedGasAcethylene:
            equipmentComment.elecDissolvedGasAcethylene,
          elecDissolvedGasEthylene: equipmentComment.elecDissolvedGasEthylene,
          elecDissolvedGasEthane: equipmentComment.elecDissolvedGasEthane,
          elecDissolvedGasMonoxide: equipmentComment.elecDissolvedGasMonoxide,
          elecDissolvedGasDioxide: equipmentComment.elecDissolvedGasDioxide,
          elecDissolvedGasTdcg: equipmentComment.elecDissolvedGasTdcg,
          mean: equipmentComment.mean,
          standardDeviation: equipmentComment.standardDeviation,
          ratioMeanStandardDeviation:
            equipmentComment.ratioMeanStandardDeviation,
          addToWeeklyReport: equipmentComment.addToWeeklyReport,
          isAlert: equipmentComment.isAlert,
        }
      );
      return { equipmentComment: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { equipmentComment: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async deleteEquipmentComment(
    equipmentComment: EquipmentComment,
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/equipment-comment/delete/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentComment.id}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async addEquipmentCommentFile(
    equipmentCommentFile: EquipmentCommentFile,
    equipmentComment: EquipmentComment,
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentCommentFile: EquipmentCommentFile | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.post(
        `/api/v1/equipment-comment-file/create/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentComment.id}`,
        {
          name: equipmentCommentFile.name,
        }
      );
      return {
        equipmentCommentFile: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        equipmentCommentFile: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async deleteEquipmentCommentFile(
    equipmentCommentFile: EquipmentCommentFile,
    equipmentComment: EquipmentComment,
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/equipment-comment-file/delete/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentComment.id}/file/${equipmentCommentFile.id}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async getEquipmentCommentDetails(
    equipmentId: number,
    equipmentCommentId: number,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentComment: EquipmentComment | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-comment/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentCommentId}`
      );
      return { equipmentComment: response.data, httpStatus: response.status };
    } catch (err: any) {
      return {
        equipmentComment: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async updateEquipmentComment(
    equipmentId: number,
    equipmentCommentId: number,
    equipmentComment: EquipmentComment,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentComment: EquipmentComment | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.patch(
        `/api/v1/equipment-comment/update/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentCommentId}`,
        equipmentComment
      );
      return { equipmentComment: response.data, httpStatus: response.status };
    } catch (err: any) {
      return {
        equipmentComment: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async getSasToken(): Promise<{
    accountSasQueryParams: string | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-comment-file/sas-token`
      );
      return {
        accountSasQueryParams: response.data.accountSasQueryParams,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        accountSasQueryParams: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async getAllEquipmentMlStatus(
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentMlStatuses: EquipmentMlStatus[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-ml-status/all/${countryBloc}/${site}`
      );
      return {
        equipmentMlStatuses: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        equipmentMlStatuses: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async updateEquipmentMlStatus(
    equipmentId: number,
    equipmentMlStatusId: number,
    unread: boolean,
    feedback: FeedbackEnum,
    startDate: string,
    endDate: string,
    realAlertValues: RealAlertValues,
    countryBloc: string,
    site: string
  ): Promise<{
    equipmentMlStatus: EquipmentMlStatus | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.patch(
        `/api/v1/equipment-ml-status/update/${countryBloc}/${site}/equipment/${equipmentId}/ml-status/${equipmentMlStatusId}`,
        { unread, feedback, startDate, endDate, realAlertValues }
      );
      return { equipmentMlStatus: response.data, httpStatus: response.status };
    } catch (err: any) {
      return {
        equipmentMlStatus: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async getEquipmentListOffshore(
    countryBloc: string,
    site: string
  ): Promise<{
    offshoreEquipments: EquipmentOffShore[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment/offshore/${countryBloc}/${site}`
      );
      return {
        offshoreEquipments: _.orderBy(
          response.data,
          ["criticality", "system", "tag"],
          ["asc", "asc", "asc"]
        ),
        httpStatus: response.status,
      };
    } catch (err: any) {
      return {
        offshoreEquipments: undefined,
        httpStatus: treatErrors(err),
      };
    }
  }

  static async updateEquipmentListOffshore(
    countryBloc: string,
    site: string,
    quickDashboardEquipments: EquipmentOffShore[]
  ): Promise<number> {
    try {
      const response = await axios.post(
        `/api/v1/equipment/offshore/update/${countryBloc}/${site}`,
        quickDashboardEquipments
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async deleteEquipmentOffshoreComment(
    equipmentId: number,
    equipmentCommentId: number,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/equipment-comment/offshore/delete/${countryBloc}/${site}/equipment/${equipmentId}/comment/${equipmentCommentId}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async exportEquipmentsOffshore(
    countryBloc: string,
    site: string
  ): Promise<AxiosResponse<any>> {
    return await axios.get(
      `/api/v1/equipment/export-offshore/${countryBloc}/${site}`,
      {
        responseType: "blob",
      }
    );
  }

  static async exportEquipmentAlertData(
    countryBloc: string,
    site: string
  ): Promise<AxiosResponse<any>> {
    return await axios.get(
      `/api/v1/equipment-ml-status/export/all/${countryBloc}/${site}`,
      {
        responseType: "blob",
      }
    );
  }

  static async getAutomaticStatusUpdate(
    countryBloc: string,
    site: string
  ): Promise<{
    AutomaticStatus: AutomaticStatusType[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(
        `/api/v1/equipment-status/all/automatic-dashboard/${countryBloc}/${site}/`
      );
      console.log(response.data);
      return { AutomaticStatus: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { AutomaticStatus: undefined, httpStatus: treatErrors(err) };
    }
  }
}
