
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import {
  EstimatedAvoidedShutdown,
  ImpactType,
  RealAlertValues,
} from "../../types/real-alert-values";
import moment from "moment-timezone";
import {
  EquipmentMlStatus,
  FeedbackEnum,
} from "../../types/equipment-ml-status";

const impactTypesOptions = [
  {
    label: "Production loss",
    value: ImpactType.PRODUCTION_LOSS,
  },
  {
    label: "Maintenance cost",
    value: ImpactType.MAINTENANCE_COST,
  },
  {
    label: "GHG emission",
    value: ImpactType.GHG_EMISSION,
  },
  {
    label: "Other (specify in comments)",
    value: ImpactType.OTHER,
  },
  {
    label: "No impact",
    value: ImpactType.NO_IMPACT,
  },
];

const estimatedAvoidedShutdownOptions = [
  {
    label: "Less than 1 day",
    value: EstimatedAvoidedShutdown.LESS_THAN_ONE_DAY,
  },
  {
    label: "1 day to 1 week",
    value: EstimatedAvoidedShutdown.ONE_DAY_TO_ONE_WEEK,
  },
  {
    label: "1 week to 1 month",
    value: EstimatedAvoidedShutdown.ONE_WEEK_TO_ONE_MONTH,
  },
  {
    label: "More than 1 month",
    value: EstimatedAvoidedShutdown.MORE_THAN_ONE_MONTH,
  },
];

@Options({
  name: "RealAlertPopin",
  methods: {
    ...mapActions(["displayRealAlertPopin", "updateEquipmentMlStatus"]),
  },
  computed: {
    ...mapGetters(["getDisplayRealAlertPopin", "getSelectedEquipmentMlStatus"]),
  },
  data() {
    return {
      impactTypesOptions,
      estimatedAvoidedShutdownOptions,
      selectedEstimatedAvoidedShutdown: undefined,
    };
  },
})
export default class RealAlertPopin extends Vue {
  equipmentMlStatus!: EquipmentMlStatus;
  displayRealAlertPopin!: (displayRealAlertPopin: boolean) => void;
  actionsComments?: string;
  estimatedMap?: number;
  estimatedMai?: number;
  estimatedFlaring?: number;
  estimatedCost?: number;
  estimatedGhg?: number;
  selectedImpactType: ImpactType[] | undefined = [];
  updateEquipmentMlStatus!: ({
    equipmentId,
    equipmentMlStatusId,
    unread,
    feedback,
    startDate,
    endDate,
    realAlertValues,
  }: any) => Promise<boolean>;
  getSelectedEquipmentMlStatus!: EquipmentMlStatus;

  get selectedEstimatedAvoidedShutdown(): EstimatedAvoidedShutdown | undefined {
    return this.selectedEstimatedAvoidedShutdown;
  }

  set selectedEstimatedAvoidedShutdown(
    estimatedAvoidedShutdown: EstimatedAvoidedShutdown | undefined
  ) {
    this.selectedEstimatedAvoidedShutdown = estimatedAvoidedShutdown;
  }

  onClose(): void {
    this.selectedImpactType = undefined;
    this.actionsComments = undefined;
    this.selectedEstimatedAvoidedShutdown = undefined;
    this.estimatedMap = undefined;
    this.estimatedMai = undefined;
    this.estimatedFlaring = undefined;
    this.estimatedCost = undefined;
    this.estimatedGhg = undefined;
    this.displayRealAlertPopin(false);
  }

  async onSubmit(): Promise<void> {
    this.$q.loading.show();
    const tz = moment.tz.guess();
    const formattedDate = moment.tz(new Date(), tz).toString();
    if (this.selectedImpactType?.length == 0) {
      this.$q.notify({
        message: "You should select at least one Impact Type",
        type: "negative",
        actions: [{ icon: "close", color: "white" }],
      });
      this.$q.loading.hide();
    } else {
      const updateRealAlertValues: RealAlertValues = {
        date: formattedDate,
        impactType: this.selectedImpactType,
        actionsComments: this.actionsComments,
        estimatedAvoidedShutdown: this.selectedEstimatedAvoidedShutdown,
        estimatedMap: this.estimatedMap ? this.estimatedMap : undefined,
        estimatedMai: this.estimatedMai ? this.estimatedMai : undefined,
        estimatedFlaring: this.estimatedFlaring
          ? this.estimatedFlaring
          : undefined,
        estimatedCost: this.estimatedCost ? this.estimatedCost : undefined,
        estimatedGhg: this.estimatedGhg ? this.estimatedGhg : undefined,
      };
      const isUpdated = await this.updateEquipmentMlStatus({
        equipmentId: this.getSelectedEquipmentMlStatus.equipment.id,
        equipmentMlStatusId: this.getSelectedEquipmentMlStatus.id,
        unread: false,
        feedback: FeedbackEnum.REAL,
        startDate: null,
        endDate: null,
        realAlertValues: updateRealAlertValues,
      });
      if (isUpdated) {
        this.$q.notify({
          message: "This value has been well saved in OneCBM",
          type: "positive",
          textColor: "black",
          actions: [{ icon: "close", color: "black" }],
        });
      }
      this.$q.loading.hide();
      this.onClose();
    }
  }
}
