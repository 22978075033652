
import { Options, Vue } from "vue-class-component";
import { getLegend } from "../../helpers/equipment-status-helpers";

@Options({
  name: "LegendTooltip",
  props: {
    text: {
      type: String,
      required: false,
    },
  },
})
export default class LegendTooltip extends Vue {
  text!: string;
  get tooltipText(): string {
    const legend = getLegend(this.text);
    return legend !== "" ? legend : this.text;
  }
}
