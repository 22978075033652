
import { Options, Vue } from "vue-class-component";
import { convertEnumStatus } from "../../helpers/equipment-status-helpers";

@Options({
  props: {
    hours: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: false,
    },
  },
  name: "HoursStats",
})
export default class HoursStats extends Vue {
  convertEnumToStatus(status: string): string {
    return convertEnumStatus(status);
  }
}
