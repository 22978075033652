import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab7364c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-mr-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn, {
      dense: "",
      round: "",
      flat: "",
      icon: _ctx.icon,
      size: "md",
      class: _normalizeClass([_ctx.displayTotal ? 'total' : 'icon-button'])
    }, {
      default: _withCtx(() => [
        (_ctx.numberAlerts > 0)
          ? (_openBlock(), _createBlock(_component_q_badge, {
              key: 0,
              floating: "",
              transparent: "",
              rounded: "",
              label: _ctx.numberAlerts,
              class: _normalizeClass([_ctx.displayTotal ? '' : 'badge'])
            }, null, 8 /* PROPS */, ["label", "class"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["icon", "class"])
  ]))
}