export enum StockStatusEnum {
  MISSING_FROM_STOCK = "missing_from_stock",
  STOCK_MINI = "stock_mini",
  IN_STOCK = "in_stock",
  NOT_STOCKABLE = "not_stockable",
}

export type SparePart = {
  materialNumber: number;
  materialName: string;
  currentStock?: number;
  status?: StockStatusEnum;
  site: string;
  plant: string;
};
