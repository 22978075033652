
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { ProductReference } from "../../types/product-references";
import EditProductMenu from "./EditProductMenu.vue";
import { AssignedCountryAndSite, UserRole } from "../../types/user-info";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import AddOrUpdateProductReferencePopin from "./AddOrUpdateProductReferencePopin.vue";
import AddButton, { PopinType } from "../equipment-details/AddButton.vue";
@Options({
  methods: {
    ...mapActions([
      "displayAddOrUpdateProductReferencePopin",
      "deleteProductReference",
      "productReferencesLoaded",
    ]),
  },
  computed: {
    ...mapGetters([
      "getUserRole",
      "getProductReferences",
      "getAssignedCountriesAndSites",
    ]),
  },
  components: {
    AddButton,
    ConfirmDialog,
    EditProductMenu,
    AddOrUpdateProductReferencePopin,
  },
  data() {
    return {
      currentProductReference: undefined,
    };
  },
  name: "ProductReferenceCard",
})
export default class ProductReferenceCard extends Vue {
  popinTypeEnum = PopinType;
  currentProductReference!: ProductReference | undefined;
  displayAddOrUpdateProductReferencePopin!: (
    displayAddOrUpdateProductReferencePopin: boolean
  ) => void;
  deleteProductReference!: (productReference: ProductReference) => void;
  productReferencesLoaded!: () => void;
  displayConfirmDeleteDialog = false;
  getUserRole!: UserRole;
  isEditMode = false;
  getAssignedCountriesAndSites!: AssignedCountryAndSite[];

  mounted(): void {
    this.productReferencesLoaded();
  }

  onClickEditProductReference(productReference: ProductReference): void {
    this.currentProductReference = productReference;
    this.isEditMode = true;
    this.displayAddOrUpdateProductReferencePopin(true);
  }

  onClosePopin(): void {
    this.currentProductReference = undefined;
    this.isEditMode = false;
  }

  onDeleteProductReference(productReference: ProductReference): void {
    this.currentProductReference = productReference;
    this.displayConfirmDeleteDialog = true;
  }

  onConfirmDelete(): void {
    if (this.currentProductReference) {
      this.deleteProductReference(this.currentProductReference);
      this.onClose();
    }
  }

  onClose(): void {
    this.displayConfirmDeleteDialog = false;
  }

  get confirmDeleteReferenceMessage(): string {
    return (
      "Do you confirm to delete the product reference " +
      this.currentProductReference?.linkTitle +
      " ?"
    );
  }

  isUserAdmin(): boolean {
    if (this.getAssignedCountriesAndSites) {
      const result = this.getAssignedCountriesAndSites.find(
        (countryAndSite) => {
          return (
            countryAndSite.sitesAndRoles.find((siteAndRole) => {
              return siteAndRole.role === UserRole.ADMIN;
            }) !== null
          );
        }
      );
      return result !== null;
    } else return false;
  }
}
