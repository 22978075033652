
import { Options, Vue } from "vue-class-component";
import {
  ElecInsulationAnalysis,
  ElecOilAnalysis,
  InformationType,
} from "../../types/equipment-comment";

@Options({
  name: "ElecAnalysis",
  props: {
    oilAnalysis: Object,
    insulationAnalysis: Object,
    informationType: Number,
    isReadOnly: Boolean,
  },
})
export default class ElecAnalysis extends Vue {
  oilAnalysis!: ElecOilAnalysis;
  insulationAnalysis!: ElecInsulationAnalysis;
  informationType!: InformationType;
  informationTypeEnum = InformationType;

  onChangeInsulationParams(): void {
    if (
      this.insulationAnalysis.mean !== undefined &&
      this.insulationAnalysis.standardDeviation !== undefined
    ) {
      if (this.insulationAnalysis.mean === 0) {
        this.insulationAnalysis.ratioMeanStandardDeviation = 0;
      } else {
        const fixedRatio = parseFloat(
          (
            this.insulationAnalysis.standardDeviation /
            this.insulationAnalysis.mean
          ).toFixed(2)
        );
        this.insulationAnalysis.ratioMeanStandardDeviation = fixedRatio;
      }
    }
  }
}
