
import { QuickDashboardEquipment } from "@/types/quick-dashboard-equipment";
import { any } from "lodash/fp";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
@Options({
  computed: {
    ...mapGetters(["getSelectedCountryBloc", "getSelectedCountryBlocInShort"]),
  },
  name: "QuickDashboardLink",
  params: {
    params: {
      type: any,
      required: true,
    },
  },
})
export default class QuickDashboardLink extends Vue {
  params!: any;
  getSelectedCountryBloc!: any;
  rowData: QuickDashboardEquipment[] = [];
}
