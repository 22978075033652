
import { RowNode } from "ag-grid-community";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import { QuickDashboardEquipment } from "../../types/quick-dashboard-equipment";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { UserRole } from "../../types/user-info";
import {
  isUserUnknown,
  isUserGuest,
  isUserOnshore,
} from "../../helpers/user-role-helper";

@Options({
  name: "QuickDashboardActions",
  params: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getSelectedCountryBloc", "getSelectedSite", "getUserRole"]),
  },
  methods: {
    ...mapActions(["deleteQuickDashboardEquipment"]),
  },
  components: { ConfirmDialog },
})
export default class QuickDashboardActions extends Vue {
  params!: any;
  getUserRole!: UserRole;
  getSelectedCountryBloc!: string;
  getSelectedSite!: string;
  deleteQuickDashboardEquipment!: ({
    quickDashboardEquipment,
    quickDashboardEquipmentsList,
  }: {
    quickDashboardEquipment: QuickDashboardEquipment;
    quickDashboardEquipmentsList: QuickDashboardEquipment[];
  }) => Promise<void>;
  isConfirmDeleteOffshoreOpen = false;

  get confirmMessage(): string {
    return (
      "Do you confirm the deletion of the comment " +
      this.params.data.comments +
      " on the equipment " +
      this.params.data.tag +
      "?"
    );
  }

  addRow(): void {
    this.params.api.applyTransaction({
      add: [
        {
          system: this.params.data.system,
          description: this.params.data.description,
          equipmentModelName: this.params.data.equipmentModelName,
          equipmentTag: this.params.data.equipmentTag,
          equipmentId: this.params.data.equipmentId,
          lastStatus: this.params.data.lastStatus,
        },
      ],
      addIndex: this.params.rowIndex + 1,
    });
  }

  async removeRow(): Promise<void> {
    this.isConfirmDeleteOffshoreOpen = true;
  }

  goToDetails(): void {
    router.push({
      name: "EquipmentDetails",
      params: {
        id: this.params.data.equipmentId,
        equipmentModelName: this.params.data.equipmentModelName,
        equipmentTag: this.params.data.equipmentTag,
        countryBloc: this.getSelectedCountryBloc,
        site: this.getSelectedSite,
      },
    });
  }

  async onConfirm(): Promise<void> {
    this.params.api.applyTransaction({
      remove: [this.params.data],
    });
    this.$q.loading.show();
    let rowData: QuickDashboardEquipment[] = [];
    this.params.api.forEachNode((node: RowNode) => rowData.push(node.data));
    await this.deleteQuickDashboardEquipment({
      quickDashboardEquipment: this.params.data,
      quickDashboardEquipmentsList: rowData,
    });
    this.$q.loading.hide();
    this.onClose();
  }

  onClose(): void {
    this.isConfirmDeleteOffshoreOpen = false;
  }

  isUserUnknownOrGuestOrOnshore(): boolean {
    return (
      isUserUnknown(this.getUserRole) ||
      isUserGuest(this.getUserRole) ||
      isUserOnshore(this.getUserRole)
    );
  }
}
