
import { Options, Vue } from "vue-class-component";
import { ReportingViewType } from "../../types/reporting-view";
import StatisticsApi from "../../api/statistics";
import { formatDateWithoutHours } from "../../helpers/equipment-status-helpers";
import { mapActions, mapGetters } from "vuex";
import { UserRole } from "../../types/user-info";
import { isUserUnknown, isUserGuest } from "../../helpers/user-role-helper";
import {
  getMessageByStatusCode,
  isSuccess,
  treatErrors,
} from "../../helpers/http-status-helper";
import SparePartsApi from "../../api/spare-part";

@Options({
  name: "ReportExport",
  computed: {
    ...mapGetters(["getSelectedSite", "getUserRole", "getSelectedCountryBloc"]),
  },
  methods: {
    ...mapActions(["displayHttpErrorDialog"]),
  },
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
})
export default class ReportExport extends Vue {
  ReportingViewType = ReportingViewType;
  startDate!: Date;
  endDate!: Date;
  getSelectedSite!: string;
  getUserRole!: UserRole;
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  getSelectedCountryBloc!: string;

  async exportExcel(): Promise<void> {
    if (this.endDate && this.startDate) {
      if (this.endDate <= this.startDate) {
        this.$q.notify({
          message: `End date must be superior to start date.`,
          type: "negative",
          actions: [{ icon: "close", color: "white" }],
        });
      } else {
        this.$q.loading.show();
        try {
          const res = await StatisticsApi.exportReport(
            this.getSelectedSite,
            new Date(this.startDate),
            new Date(this.endDate),
            this.getSelectedCountryBloc
          );
          this.$q.loading.hide();
          if (isSuccess(res.status)) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `OneCBM-Report-${this.getSelectedCountryBloc}-${
                this.getSelectedSite
              }-${formatDateWithoutHours(
                new Date(this.startDate)
              )}-${formatDateWithoutHours(new Date(this.endDate))}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          }
        } catch (err: any) {
          this.$q.loading.hide();
          this.displayHttpErrorDialog({
            displayHttpErrorDialog: true,
            httpErrorMessage:
              "Export report statistics: " +
              getMessageByStatusCode(treatErrors(err)),
          });
        }
      }
    }
  }

  async exportMissingSpareParts(): Promise<void> {
    this.$q.loading.show();
    try {
      const res = await SparePartsApi.exportMissingSpareParts(
        this.getSelectedCountryBloc,
        this.getSelectedSite
      );
      this.$q.loading.hide();
      if (isSuccess(res.status)) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `${this.getSelectedCountryBloc}-${
            this.getSelectedSite
          }-${formatDateWithoutHours(new Date())}-MissingSpareParts.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (err: any) {
      this.$q.loading.hide();
      this.displayHttpErrorDialog({
        displayHttpErrorDialog: true,
        httpErrorMessage:
          "Export Missing Spare Parts: " +
          getMessageByStatusCode(treatErrors(err)),
      });
    }
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }
}
