import { BlobServiceClient } from "@azure/storage-blob";
import moment from "moment-timezone";

export async function uploadFile(
  token: string,
  file: File,
  equipmentCommentId: number | undefined,
  timezone: string
): Promise<string> {
  const account = process.env.VUE_APP_AZURE_ACCOUNT_STORAGE_NAME;
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${token}`
  );
  const containerClient = blobServiceClient.getContainerClient("file-upload");
  const blobDate = moment.tz(new Date(), timezone).format("YYYY-MM-DD");
  const blobName = `${blobDate}-${equipmentCommentId}-${file.name}`;
  const blobClient = containerClient.getBlockBlobClient(blobName);
  await blobClient.upload(file, file.size, {
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
  });
  return blobName;
}

export async function downloadFile(
  token: string,
  containerClientName: string,
  fileName: string
): Promise<void> {
  const account = process.env.VUE_APP_AZURE_ACCOUNT_STORAGE_NAME;
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${token}`
  );
  const containerClient =
    blobServiceClient.getContainerClient(containerClientName);
  if (containerClient) {
    try {
      const blobResponse = await containerClient
        .getBlobClient(fileName)
        .download();
      const blob = await blobResponse.blobBody;
      if (blob) {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } catch (e: any) {
      alert("No file found.");
    }
  }
}

export async function deleteFiles(
  token: string,
  fileNames: string[]
): Promise<void> {
  const account = process.env.VUE_APP_AZURE_ACCOUNT_STORAGE_NAME;
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${token}`
  );
  const containerClient = blobServiceClient.getContainerClient("file-upload");
  fileNames.forEach(async (fileName: string) => {
    containerClient.getBlockBlobClient(fileName).deleteIfExists();
  });
}
