import { StockStatusEnum } from "@/types/spare-part";

enum SparePartsStockKpiChipIcon {
  missing_from_stock = "cancel",
  stock_mini = "error",
  in_stock = "check_circle",
  not_stockable = "remove_circle",
}

export function getClassForSparePartsStockKpiChip(
  sparePartsStockKpi: StockStatusEnum | undefined
): any {
  if (sparePartsStockKpi) {
    return {
      "bg-red": sparePartsStockKpi === StockStatusEnum.MISSING_FROM_STOCK,
      "bg-orange": sparePartsStockKpi === StockStatusEnum.STOCK_MINI,
      "bg-green": sparePartsStockKpi === StockStatusEnum.IN_STOCK,
      "bg-darkgrey": sparePartsStockKpi === StockStatusEnum.NOT_STOCKABLE,
    };
  }
}

export function getSparePartsStockKpiChipIcon(
  sparePartsStockKpi: StockStatusEnum | undefined
): SparePartsStockKpiChipIcon {
  if (sparePartsStockKpi) {
    return SparePartsStockKpiChipIcon[sparePartsStockKpi];
  }
  return SparePartsStockKpiChipIcon.not_stockable;
}

export function getSparePartsLabelForChip(
  sparePartsStockKpi: StockStatusEnum | undefined
): string {
  switch (sparePartsStockKpi) {
    case StockStatusEnum.IN_STOCK:
      return "IN STOCK";
    case StockStatusEnum.MISSING_FROM_STOCK:
      return "MISSING";
    case StockStatusEnum.NOT_STOCKABLE:
      return "NOT STOCKABLE";
    case StockStatusEnum.STOCK_MINI:
      return "STOCK MINI";
    default:
      return "";
  }
}
