
import { Options, Vue } from "vue-class-component";

@Options({
  name: "EditProductMenu",
})
export default class EditProductMenu extends Vue {
  onEdit(): void {
    this.$emit("onEdit");
  }
  onDelete(): void {
    this.$emit("onDelete");
  }
}
