
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "../equipment-status/Datepicker.vue";
import { Equipment } from "../../types/equipment";
import { EquipmentStatuses } from "../../types/equipment-status";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import {
  convertEnumStatus,
  allEvents,
  getLastSerialNumber,
} from "../../helpers/equipment-status-helpers";
import { StatusEventEnum } from "../../types/status-events";
import moment from "moment";
import {
  EquipmentComment,
  InformationType,
} from "../../types/equipment-comment";
import { EquipmentCommentFile } from "../../types/equipment-comment-file";
import TradeHierarchy from "./TradeHierarchy.vue";
import EquipmentHierarchy from "./EquipmentHierarchy.vue";
import { ContainerClient } from "@azure/storage-blob";
import { uploadFile } from "../../helpers/file-upload-helper";
import {
  EquipmentDetails,
  EquipmentParamDetails,
  Trade,
} from "@/types/equipment-tree";
import { isNumber } from "@/helpers/equipment-comment-helper";
import _ from "lodash";
import {
  displayAdditionalInformation,
  getDisplayShortfall,
  getEventDisplayEquipementList,
} from "@/helpers/equipment-condition-helper";

@Options({
  name: "AddLastStatusDialog",
  components: {
    Datepicker,
    ConfirmDialog,
    TradeHierarchy,
    EquipmentHierarchy,
  },
  methods: {
    ...mapActions([
      "addEquipmentStatus",
      "updateSelectedDate",
      "displayAddLastStatusDialog",
      "updateSelectedStatus",
      "updateSelectedStatusEvent",
      "addEquipmentComment",
      "claimSasToken",
      "addEquipmentCommentFile",
      "updateSelectedTrade",
      "updateSelectedEquipment",
    ]),
  },
  computed: {
    ...mapGetters([
      "getAddLastStatusDialog",
      "getEquipmentDetails",
      "getStatusToUpdate",
      "getDateToUpdate",
      "getSelectedStatusEvent",
      "getSasToken",
      "getAuthInfoAPI",
      "getEquipmentParam",
      "getSelectedCountryBloc",
      "getSelectedTrade",
      "getSelectedEquipment",
      "getFlaringUnit",
      "getProductionShortfallUnit",
      "getWaterInjectShortfallUnit",
    ]),
  },
  data() {
    return {
      author: "",
      woNumber: "",
      comment: "",
      addToWeeklyReport: false,
      isAlert: false,
      productionShortfall: "",
      waterInjectShortfall: "",
      flaring: "",
      equipmentMajorFailure: false,
      gasOrOilLeak: false,
      fireConfirmed: false,
      showSerialNumber: false,
      serialNumber: null,
      selectedEquipmentName: undefined,
    };
  },
})
export default class AddLastStatusDialog extends Vue {
  files: File[] | null = null;
  comment!: string;
  woNumber = "";
  author!: string;
  addToWeeklyReport = false;
  isAlert = false;
  equipmentStatusesEnum = EquipmentStatuses;

  displayAddLastStatusDialog!: (displayAddLastStatusDialog: boolean) => void;
  getEquipmentDetails!: Equipment;
  confirmPopUpTitle = "Status update";
  datepickerConfirmDialog = false;
  getStatusToUpdate!: EquipmentStatuses;
  getDateToUpdate!: string;
  updateSelectedDate!: (date: string | undefined) => void;
  updateSelectedStatusEvent!: (
    statusEvent: StatusEventEnum | undefined
  ) => void;
  updateSelectedStatus!: (status: EquipmentStatuses | undefined) => void;
  updateSelectedTrade!: (trade: Trade | undefined) => void;
  updateSelectedEquipment!: (equipment: number | undefined) => void;
  getSelectedStatusEvent!: StatusEventEnum;
  claimSasToken!: () => Promise<void>;
  getSasToken!: string;
  containerClient!: ContainerClient | null;
  getAuthInfoAPI!: any;

  getSelectedTrade!: Trade;
  getSelectedEquipment!: number | undefined;
  productionShortfall!: number | undefined;
  waterInjectShortfall!: number | undefined;
  flaring!: number | undefined;
  equipmentMajorFailure = false;
  gasOrOilLeak = false;
  fireConfirmed = false;
  getEquipmentParam!: EquipmentParamDetails;
  getSelectedCountryBloc!: any;
  paths: EquipmentDetails[] = [];
  showSerialNumber!: boolean;
  selectedEquipmentName: string | undefined = undefined;
  serialNumber!: string | null | undefined;
  serialNumberOld!: string | null | undefined;

  addEquipmentComment!: ({
    equipmentComment,
    equipmentId,
  }: {
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentComment>;
  addEquipmentCommentFile!: ({
    equipmentCommentFile,
    equipmentComment,
    equipmentId,
  }: {
    equipmentCommentFile: EquipmentCommentFile;
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentCommentFile>;

  addEquipmentStatus!: ({
    status,
    equipmentId,
    statusDate,
    event,
    commentId,
    isLastStatus,
    equipmentMajorFailure,
    gasOrOilLeak,
    fireConfirmed,
    equipmentDetailsId,
    tradeTypeId,
    productionShortfall,
    waterInjectShortfall,
    flaring,
    equipmentComment,
    serialNumber,
  }: {
    status: EquipmentStatuses | undefined;
    equipmentId: number;
    statusDate: string;
    event: StatusEventEnum | undefined;
    commentId: number | undefined;
    isLastStatus: boolean;
    equipmentMajorFailure: boolean | undefined;
    gasOrOilLeak: boolean | undefined;
    fireConfirmed: boolean | undefined;
    equipmentDetailsId: number | undefined;
    tradeTypeId: number | undefined;
    productionShortfall: number | undefined;
    waterInjectShortfall: number | undefined;
    flaring: number | undefined;
    equipmentComment: EquipmentComment | null;
    serialNumber: string | null | undefined;
  }) => Promise<boolean>;

  mounted(): void {
    this.author = this.getAuthInfoAPI?.account?.name;
  }

  updated(): void {
    this.author = this.getAuthInfoAPI?.account?.name;
  }

  onPreUpdate(): void {
    if (this.validateDateTime()) {
      this.datepickerConfirmDialog = true;
      this.displayAddLastStatusDialog(false);
    }
  }

  isNumberCheck(evt: KeyboardEvent): void {
    isNumber(evt);
  }

  get availableEvents(): any[] {
    if (this.getStatusToUpdate) {
      const events = _.filter(allEvents, (displayedEvent: any) => {
        return displayedEvent.associatedStatus === this.getStatusToUpdate;
      });
      if (this.getStatusToUpdate === EquipmentStatuses.RUN) {
        this.selectedEvent = StatusEventEnum.RUN_RESTARTED;
      } else if (this.getStatusToUpdate === EquipmentStatuses.ABNR) {
        this.selectedEvent = StatusEventEnum.ABNR_STAND_BY;
      } else if (this.getStatusToUpdate === EquipmentStatuses.RUN_LIMITED) {
        this.selectedEvent = StatusEventEnum.RUN_LIMITED;
      }
      return events;
    }
    return [];
  }

  set selectedEvent(event: StatusEventEnum | undefined) {
    this.updateSelectedStatusEvent(event);
    this.updateSelectedTrade(undefined);
    if (this.getStatusToUpdate?.includes("unscheduled")) {
      if (
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_ENV) ||
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_HUMAN)
      ) {
        this.updateSelectedEquipment(undefined);
      }
    }
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.paths = [];
    this.selectedEquipmentName = "";
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
  }

  get selectedEvent(): StatusEventEnum | undefined {
    return this.getSelectedStatusEvent;
  }

  displaySpecificFailure(): boolean {
    return displayAdditionalInformation(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  eventDisplayEquipementList(): boolean {
    return getEventDisplayEquipementList(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  displayShortfall(): boolean {
    return getDisplayShortfall(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  validateDateTime(): boolean {
    const lastStatus = this.getEquipmentDetails?.lastEquipmentStatus?.date;
    if (lastStatus) {
      const momentLastStatusDate = moment(lastStatus);
      const momentDate = moment(this.getDateToUpdate);
      if (
        !(
          momentDate.isAfter(momentLastStatusDate) &&
          momentDate.isBefore(moment())
        )
      ) {
        this.showNotifValidation(
          "Please select a date between the last status date and today (date and time)"
        );
        return false;
      } else if (!momentDate.isBefore(moment())) {
        this.showNotifValidation(
          "Please select a date before the current date and time"
        );
        return false;
      }
    }
    return true;
  }

  showNotifValidation(message: string): void {
    this.$q.notify({
      message,
      type: "negative",
      actions: [{ icon: "close", color: "white" }],
    });
  }

  showSerialNumberSectionFn(): boolean {
    if (
      this.getEquipmentDetails.driver === "Turbine" &&
      (this.getEquipmentDetails.technology === "Aeroderivative" ||
        this.getEquipmentDetails.technology === "Industrial")
    ) {
      if (
        this.selectedEquipmentName === "Turbine" ||
        (this.paths.length > 0 && this.paths[0].equipmentItem === "Turbine")
      ) {
        if (
          this.getStatusToUpdate === EquipmentStatuses.SCH &&
          this.getSelectedStatusEvent === StatusEventEnum.SCH_MAJOR_PM
        ) {
          return true;
        } else if (
          this.getStatusToUpdate === EquipmentStatuses.UNSCH &&
          this.getSelectedStatusEvent === StatusEventEnum.UNSCH_FAILURE &&
          this.equipmentMajorFailure === true
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getEquipmentPath(path: EquipmentDetails[]): void {
    this.paths = path;
  }

  getSelectedEquipmentName(eqName: string): void {
    this.selectedEquipmentName = eqName;
  }

  async onConfirm(): Promise<void> {
    if (this.comment.trim() !== "" && this.author.trim() !== "") {
      const tz = moment.tz.guess();
      const formattedDate = new Date(
        moment.tz(this.getDateToUpdate, tz).toString()
      ).toString();
      const comment = {
        date: formattedDate,
        comment: this.comment,
        woNumber: parseInt(this.woNumber),
        equipmentCommentFiles: [],
        author: this.author,
        informationType: InformationType.STATUS_CHANGE,
        addToWeeklyReport: this.addToWeeklyReport,
        isAlert: this.isAlert,
      } as unknown as EquipmentComment;
      const equipmentComment = await this.addEquipmentComment({
        equipmentComment: comment,
        equipmentId: this.getEquipmentDetails.id,
      });
      if (equipmentComment) {
        await this.addHistoricalStatus(equipmentComment, equipmentComment.id);
        if (this.files) {
          this.files.forEach(async (file: File) => {
            await this.claimSasToken();
            const token = this.getSasToken;
            if (token) {
              const blobName = await uploadFile(
                token,
                file,
                equipmentComment.id,
                tz
              );
              const equipmentCommentFile: EquipmentCommentFile = {
                name: blobName,
              };
              await this.addEquipmentCommentFile({
                equipmentCommentFile,
                equipmentComment,
                equipmentId: this.getEquipmentDetails.id,
              });
            }
          });
        }
        await this.showNotifComment();
      }
    } else {
      await this.addHistoricalStatus(null, undefined);
    }
    this.onClose();
  }

  async addHistoricalStatus(
    equipmentComment: EquipmentComment | null,
    commentId: number | undefined
  ): Promise<void> {
    const successAddEquipmentStatus = await this.addEquipmentStatus({
      status: this.getStatusToUpdate,
      equipmentId: this.getEquipmentDetails.id,
      statusDate: this.getDateToUpdate,
      event: this.getSelectedStatusEvent,
      commentId: commentId,
      isLastStatus: false,
      equipmentMajorFailure: this.equipmentMajorFailure,
      gasOrOilLeak: this.gasOrOilLeak,
      fireConfirmed: this.fireConfirmed,
      equipmentDetailsId: this.getSelectedEquipment,
      tradeTypeId: this.getSelectedTrade?.id,
      productionShortfall: this.productionShortfall
        ? this.productionShortfall
        : undefined,
      waterInjectShortfall: this.waterInjectShortfall
        ? this.waterInjectShortfall
        : undefined,
      flaring: this.flaring ? this.flaring : undefined,
      equipmentComment: equipmentComment,
      serialNumber: this.showSerialNumber
        ? this.serialNumber
        : this.serialNumberOld,
    });
    if (successAddEquipmentStatus) {
      this.showNotif();
    }
  }

  async onClose(): Promise<void> {
    this.updateSelectedDate(undefined);
    this.updateSelectedStatusEvent(undefined);
    this.updateSelectedEquipment(undefined);
    this.comment = "";
    this.woNumber = "";
    this.files = null;
    this.addToWeeklyReport = false;
    this.isAlert = false;
    this.datepickerConfirmDialog = false;
    this.displayAddLastStatusDialog(false);
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
    this.paths = [];
    this.selectedEquipmentName = "";
  }

  async showNotif(): Promise<void> {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } Status has been correctly updated to "${convertEnumStatus(
        this.getStatusToUpdate
      )}" with starting date "${this.getDateToUpdate}"`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  async showNotifComment(): Promise<void> {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `A new comment for ${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } has been added : "${this.comment}"`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  get confirmMessage(): string {
    return (
      "Do you confirm the status change to " +
      convertEnumStatus(this.getStatusToUpdate) +
      " starting at " +
      this.getDateToUpdate +
      " with the comment: " +
      this.comment +
      " ?"
    );
  }

  mandatoryCommentRule(val: string | undefined): boolean | string {
    const notMandatoryCommentStatuses =
      this.getStatusToUpdate === EquipmentStatuses.RUN ||
      this.getStatusToUpdate === EquipmentStatuses.ABNR;
    return !!val || notMandatoryCommentStatuses || "Comment is required";
  }

  get getLastSerialNumberFn(): {
    serialNumber: string | undefined | null;
    installationDate: string | undefined | null;
  } {
    return getLastSerialNumber(this.getEquipmentDetails);
  }
}
