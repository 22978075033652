export enum ImpactType {
  PRODUCTION_LOSS = "production_loss",
  MAINTENANCE_COST = "maintenance_cost",
  GHG_EMISSION = "ghg_emission",
  OTHER = "other",
  NO_IMPACT = "no_impact",
}

export enum EstimatedAvoidedShutdown {
  LESS_THAN_ONE_DAY = "less_than_one_day",
  ONE_DAY_TO_ONE_WEEK = "one_day_to_one_week",
  ONE_WEEK_TO_ONE_MONTH = "one_week_to_one_month",
  MORE_THAN_ONE_MONTH = "more_than_one_month",
}

export type RealAlertValues = {
  id?: number;
  date: string;
  impactType?: ImpactType[];
  actionsComments?: string;
  estimatedAvoidedShutdown?: EstimatedAvoidedShutdown;
  estimatedMap?: number;
  estimatedMai?: number;
  estimatedFlaring?: number;
  estimatedCost?: number;
  estimatedGhg?: number;
};
