import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fae5bece"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-between" }
const _hoisted_2 = { class: "col-10 home" }
const _hoisted_3 = { class: "col-2 product-references" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHomepage = _resolveComponent("SiteHomepage")!
  const _component_ProductReferenceCard = _resolveComponent("ProductReferenceCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SiteHomepage)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ProductReferenceCard)
    ])
  ]))
}