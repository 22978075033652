import { treatErrors } from "@/helpers/http-status-helper";
import { EquipmentAndStatistics } from "@/types/equipment-statistics";
import axios, { AxiosResponse } from "axios";
import moment from "moment-timezone";

export default class StatisticsApi {
  static async getEquipmentList(
    site: string,
    countryBloc: string
  ): Promise<{
    equipmentStatistics: EquipmentAndStatistics | undefined;
    httpStatus: number;
  }> {
    const timezone = moment.tz.guess();
    try {
      const response = await axios.get(
        `/api/v1/equipment-statistics/all/${countryBloc}/${site}`,
        {
          params: { timezone },
        }
      );
      return {
        equipmentStatistics: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return { equipmentStatistics: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async exportReport(
    site: string,
    startDate: Date,
    endDate: Date,
    countryBloc: string
  ): Promise<AxiosResponse<any>> {
    const timezone = moment.tz.guess();
    return axios.get(
      `/api/v1/equipment-statistics/export/${countryBloc}/${site}`,
      {
        params: {
          timezone: timezone,
          site: site,
          fromDate: startDate,
          toDate: endDate,
          countryBloc: countryBloc,
        },
        responseType: "blob",
      }
    );
  }
  static async getEquipmentListKpi(
    startDate: Date,
    endDate: Date,
    site: string,
    countryBloc: string
  ): Promise<{
    equipmentStatistics: EquipmentAndStatistics | undefined;
    httpStatus: number;
  }> {
    try {
      const timezone = moment.tz.guess();
      const response = await axios.get(
        `/api/v1/equipment-statistics/all/${countryBloc}/${site}`,
        {
          params: { timezone: timezone, fromDate: startDate, toDate: endDate },
        }
      );
      return {
        equipmentStatistics: response.data,
        httpStatus: response.status,
      };
    } catch (err: any) {
      return { equipmentStatistics: undefined, httpStatus: treatErrors(err) };
    }
  }
}
