export enum StatusEventEnum {
  UNSCH_ENV = "shutdown_environment_external_process_external_event",
  UNSCH_HUMAN = "package_shutdown_due_to_human_error",
  UNSCH_FAILURE = "unplanned_shutdown_due_to_package_failure_or_issue",
  SCH_UPGRADE = "planned_shutdown_for_control_system_upgrade",
  SCH_MINOR_PM = "planned_shutdown_for_minor_PM_fire_and_gas_PM",
  SCH_MAJOR_PM = "planned_shutdown_for_major_PM_(overhaul_standard_exchange)",
  SCH_NON_ROUTINE = "planned_shutdown_for_major_non_routine",
  SCH_CONDITION_MAINT = "planned_shutdown_for_on_condition_maintenance",
  ABNR_STAND_BY = "package_shutdown_for_stand-by",
  RUN_LIMITED = "package_limited_due_to_other_issues",
  RUN_LIMITED_MECHANICAL = "package_limited_due_to_mechanical_issue",
  RUN_LIMITED_PERF = "package_limited_due_to_performance_issue",
  RUN_LIMITED_INSTRU = "package_limited_due_to_instrumentation_issue",
  RUN_LIMITED_OTHER = "package_limited_due_to_other_issues",
  RUN_RESTARTED = "package_restarted",
}

export function convertStatusEventEnum(status: string | undefined): string {
  switch (status) {
    case "shutdown_environment_external_process_external_event":
      return "Unplanned shutdown due to external process, event";
    case "package_shutdown_due_to_human_error":
      return "Unplanned shutdown due to human error";
    case "unplanned_shutdown_due_to_package_failure_or_issue":
      return "Unplanned shutdown due to package failure / issue";
    case "planned_shutdown_for_control_system_upgrade":
      return "Planned shutdown for Control system upgrade";
    case "planned_shutdown_for_minor_PM_fire_and_gas_PM":
      return "Planned shutdown for Minor PM (up to yearly PM), Fire and Gas PM";
    case "planned_shutdown_for_major_PM_(overhaul_standard_exchange)":
      return "Planned shutdown for Major PM (overhaul / standard exchange)";
    case "planned_shutdown_for_major_non_routine":
      return "Planned shutdown for Major non routine: structure, enclosure";
    case "planned_shutdown_for_on_condition_maintenance":
      return "Planned shutdown for On Condition Maintenance";
    case "package_shutdown_for_stand-by":
      return "Shutdown for stand-by (= Available But Not Running)";
    case "package_limited_due_to_mechanical_issue":
      return "Running in downgraded mode";
    case "package_limited_due_to_performance_issue":
      return "Running in downgraded mode";
    case "package_limited_due_to_instrumentation_issue":
      return "Running in downgraded mode";
    case "package_limited_due_to_other_issues":
      return "Running in downgraded mode";
    case "package_restarted":
      return "Running";
    default:
      return "";
  }
}
