import { ProductReference } from "@/types/product-references";
import axios from "axios";
import { treatErrors } from "@/helpers/http-status-helper";
import moment from "moment-timezone";

export default class ProductApi {
  static async getProductReferenceList(): Promise<{
    productReferences: ProductReference[] | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get(`/api/v1/product-references/all`);
      return { productReferences: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { productReferences: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async addProductReference(
    productReferences: ProductReference
  ): Promise<{
    productReferences: ProductReference | undefined;
    httpStatus: number;
  }> {
    try {
      const date = moment();
      const response = await axios.post(`/api/v1/product-references/create`, {
        linkTitle: productReferences.linkTitle,
        linkUrl: productReferences.linkUrl,
        creationDate: date,
        updatedDate: date,
      });
      return { productReferences: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { productReferences: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async updateProductReference(
    productReferences: ProductReference
  ): Promise<{
    productReferences: ProductReference | undefined;
    httpStatus: number;
  }> {
    try {
      const date = moment();
      const response = await axios.patch(
        `/api/v1/product-references/update/${productReferences.id}`,
        {
          linkTitle: productReferences.linkTitle,
          linkUrl: productReferences.linkUrl,
          updatedDate: date,
        }
      );
      return { productReferences: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { productReferences: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async deleteProductReference(
    productReferences: ProductReference
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/product-references/delete/${productReferences.id}`
      );
      return response.status;
    } catch (error: any) {
      return treatErrors(error);
    }
  }
}
