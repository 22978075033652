
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    percent: {
      type: Number,
      required: false,
    },
  },
  name: "CircularStats",
})
export default class CircularStats extends Vue {
  percent!: number;
  get series(): number[] {
    return [this.percent];
  }

  get color(): string[] {
    if (this.percent <= 70) return ["#F44336"];
    if (this.percent > 70 && this.percent <= 80) return ["#FF9F40"];
    if (this.percent > 80 && this.percent <= 90) return ["#FFE436"];
    else return ["#008000"];
  }

  get chartOptions(): any {
    return {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#ECECEC",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
        },
        colors: this.color,
      },
    };
  }
}
