
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { EquipmentStatuses } from "../../types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { informationTypesTransformers } from "../../helpers/equipment-comment-helper";

@Options({
  components: { EquipmentStatusIcon },
  methods: {
    ...mapActions(["updateFilters"]),
  },
  computed: {
    ...mapGetters([
      "getSelectedStatusFilter",
      "getCriticalCheckboxFilter",
      "getSelectedDateFilter",
      "getSelectedEquipmentFilter",
      "getSelectedEquipmentAlertsFilter",
      "getSelectedSectorFilter",
      "getStatusCheckboxFilter",
    ]),
  },
  data() {
    return {
      informationTypesTransformers,
    };
  },
  name: "EquipmentsWithAlertsFilter",
})
export default class EquipmentsWithAlertsFilter extends Vue {
  informationTypesTransformers!: string[];
  updateFilters!: ({
    selectedStatusFilter,
    criticalCheckboxFilter,
    selectedDateFilter,
    selectedEquipmentFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
    statusCheckboxFilter,
  }: {
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentFilter: string | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;
  getSelectedStatusFilter!: EquipmentStatuses[];
  getCriticalCheckboxFilter!: boolean;
  getSelectedDateFilter!: string;
  getSelectedEquipmentFilter!: string;
  getSelectedEquipmentAlertsFilter!: string[];
  getSelectedSectorFilter!: string[];
  getStatusCheckboxFilter!: boolean;
  allCheckbox = false;
  allSelected = false;

  equipmentWithAlertsOptions = [
    ...informationTypesTransformers,
    { label: "Offshore", value: "offshore" },
    { label: "Status change", value: "status_change" },
  ];

  set selectedEquipmentAlertsFilter(
    selectedEquipmentAlertsFilter: string[] | undefined
  ) {
    this.updateFilters({
      selectedStatusFilter: this.getSelectedStatusFilter,
      criticalCheckboxFilter: this.getCriticalCheckboxFilter,
      selectedDateFilter: this.getSelectedDateFilter,
      selectedEquipmentFilter: this.getSelectedEquipmentFilter,
      selectedEquipmentAlertsFilter,
      selectedSectorFilter: this.getSelectedSectorFilter,
      statusCheckboxFilter: this.getStatusCheckboxFilter,
    });
  }

  get selectedEquipmentAlertsFilter(): string[] | undefined {
    return this.getSelectedEquipmentAlertsFilter;
  }

  checkAll(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.allCheckbox = true;
      this.selectedEquipmentAlertsFilter = this.equipmentWithAlertsOptions
        .filter((v) => v.label !== "All")
        .map((i) => i.value);
    } else {
      this.selectedEquipmentAlertsFilter = [];
      this.allCheckbox = false;
    }
  }

  checkIfAllSelected(): void {
    if (
      this.selectedEquipmentAlertsFilter &&
      this.selectedEquipmentAlertsFilter?.length > 0
    ) {
      this.allCheckbox =
        this.selectedEquipmentAlertsFilter?.length ===
        this.equipmentWithAlertsOptions.length;
    } else {
      this.allCheckbox = false;
    }
  }
}
