
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { Equipment } from "../../types/equipment";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { UserRole } from "../../types/user-info";
import {
  isUserUnknown,
  isUserGuest,
  isUserAdmin,
} from "../../helpers/user-role-helper";
import { SparePart, StockStatusEnum } from "../../types/spare-part";
import {
  getClassForSparePartsStockKpiChip,
  getSparePartsStockKpiChipIcon,
  getSparePartsLabelForChip,
} from "../../helpers/spare-parts-helper";
import AddSparePartDialog from "./AddSparePartDialog.vue";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions(["displayAddSparePartDialog", "deleteSparePart"]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentDetails",
      "getUserRole",
      "getSelectedCountryBloc",
      "getSelectedCountryBlocInShort",
    ]),
  },
  components: {
    ConfirmDialog,
    AddSparePartDialog,
  },
  data() {
    return {
      filter: "",
    };
  },
  name: "SparePartsCard",
})
export default class SparePartsCard extends Vue {
  filter = "";
  getEquipmentDetails!: Equipment;
  getSelectedCountryBloc!: any;
  displayConfirmDeleteSparePartDialog = false;
  getUserRole!: UserRole;
  currentSparePart!: SparePart;
  displayAddSparePartDialog!: (displayAddSparePartDialog: boolean) => void;
  deleteSparePart!: ({
    equipmentId,
    materialNumber,
  }: {
    equipmentId: number;
    materialNumber: number;
  }) => Promise<boolean>;

  columnsSpareParts = [
    {
      name: "materialNumber",
      label: "Material Number",
      align: "left",
      field: "materialNumber",
      sortable: true,
    },
    {
      name: "materialName",
      label: "Material Name",
      align: "left",
      field: "materialName",
    },
    {
      name: "plant",
      align: "left",
      label: "Plant",
      field: "plant",
    },
    {
      name: "status",
      align: "left",
      label: "Stock",
      field: "status",
    },
    {
      name: "linkToSceff",
      label: "",
    },
    {
      name: "delete",
      label: "",
    },
  ];

  async openConfirmDeleteSparePart(sparePart: SparePart): Promise<void> {
    this.currentSparePart = sparePart;
    this.displayConfirmDeleteSparePartDialog = true;
  }

  closeConfirmDeleteSparePart(): void {
    this.displayConfirmDeleteSparePartDialog = false;
  }

  async onConfirmDeleteSparePart(): Promise<void> {
    if (this.currentSparePart) {
      const isDeleted = await this.deleteSparePart({
        equipmentId: this.getEquipmentDetails.id,
        materialNumber: this.currentSparePart.materialNumber,
      });
      this.closeConfirmDeleteSparePart();
      if (isDeleted) this.showNotifSparePartDeleted();
    }
  }

  get confirmDeleteSparePartMessage(): string {
    return "Do you confirm to delete the spare parts ?";
  }

  showNotifSparePartDeleted(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `Spare parts for ${equipment.equipmentModel.name} ${equipment.tag} have been deleted.`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  getClassSparePartIcon(sparePartsStockKpi: StockStatusEnum): string {
    return getClassForSparePartsStockKpiChip(sparePartsStockKpi);
  }

  icon(sparePartsStockKpi: StockStatusEnum): string {
    return getSparePartsStockKpiChipIcon(sparePartsStockKpi);
  }

  getSparePartsLabelForChip(sparePartsStockKpi: StockStatusEnum): string {
    return getSparePartsLabelForChip(sparePartsStockKpi);
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  isUserAdmin(): boolean {
    return isUserAdmin(this.getUserRole);
  }
}
