import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_select, {
      modelValue: _ctx.selectedFeedback,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFeedback) = $event)),
      disable: _ctx.isDisabled,
      "data-test": "selecter-feedback",
      options: _ctx.feedbacks,
      "option-label": "label",
      "option-value": "value",
      standout: "",
      "map-options": "",
      "emit-value": "",
      "bottom-slots": "",
      class: "q-mt-md main-dropdown",
      "bg-color": "white",
      borderless: "",
      "input-style": {
        fontFamily: 'Roboto',
        fontSize: '12px',
        lineHeight: '16px',
      },
      outlined: ""
    }, null, 8 /* PROPS */, ["modelValue", "disable", "options"])
  ]))
}