export enum EquipmentSystem {
  TRANSFORMERS = "Transformers",
}

export type EquipmentModel = {
  name: string;
  description: string;
  system: EquipmentSystem | string;
  category: string;
  manufacturer?: string;
  power?: string;
  dischargePressure?: string;
  nominalFlow?: string;
  suctionPressure: string;
};
