import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import VueApexCharts from "vue3-apexcharts";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const items = { ...localStorage };
const localStorageItemsMap = new Map(Object.entries(items));
let userName;
let localAccountId;
Array.from(localStorageItemsMap.values()).forEach((value) => {
  if (value[0] === "{") {
    const itemJSON = JSON.parse(value);
    if (itemJSON.username) {
      userName = itemJSON.username;
    }
    if (itemJSON.localAccountId) {
      localAccountId = itemJSON.localAccountId;
    }
  }
});
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
    accountId: localAccountId,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.setAuthenticatedUserContext(userName ?? "unknown", localAccountId);

router.beforeEach((route: any, from, next) => {
  const name = `/${route.name}`;
  appInsights.startTrackPage(name);
  next();
});

router.afterEach((route: any) => {
  const name = `/${route.name}`;
  const url = location.protocol + "//" + location.host + route.fullPath;
  appInsights.stopTrackPage(name, url);
  appInsights.flush();
});

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const app = createApp(App).use(Quasar, quasarUserOptions);
app.use(VueApexCharts);
app.config.globalProperties.$msalInstance = {};
app.use(router).use(store).mount("#app");
