
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import Header from "./components/common/Header.vue";
import Footer from "./components/common/Footer.vue";
import UserApi from "./api/user";
import HttpErrorDialog from "./components/common/HttpErrorDialog.vue";
import {
  getMessageByStatusCode,
  isSuccess,
} from "./helpers/http-status-helper";
import { AssignedCountryAndSite } from "./types/user-info";
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';;

@Options({
  name: "LayoutDefault",
  computed: {
    ...mapGetters(["getMsalConfig", "getAuthInfoAPI"]),
  },
  data() {
    return {
      account: null,
      pageLoadingInfo: "Logging in...",
    };
  },
  methods: {
    ...mapActions([
      "setAccessTokenAPI",
      "setAccessTokenMSGraph",
      "loadUserPicture",
      "updateAssignedCountriesAndSitesAndUserRole",
      "displayHttpErrorDialog",
    ]),
  },
  components: {
    Footer,
    Header,
    HttpErrorDialog,
  },
})
export default class App extends Vue {
  $msalInstance!: PublicClientApplication;
  getMsalConfig!: any;
  setAccessTokenAPI!: (accessToken: AuthenticationResult) => void;
  setAccessTokenMSGraph!: (accessToken: AuthenticationResult) => void;
  loadUserPicture!: () => void;
  account!: any;
  getAuthInfoAPI!: any;
  pageLoadingInfo!: string;
  updateAssignedCountriesAndSitesAndUserRole!: (
    assignedCountriesAndSites: AssignedCountryAndSite[]
  ) => void;
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  async created(): Promise<void> {
    Loading.show({
      spinner: QSpinnerGears,
      spinnerSize: 200,
      spinnerColor: "white",
    });
    this.pageLoadingInfo = "Logging in to the oneCBM....";
    const request = {
      scopes: ["User.Read"],
    };
    this.$msalInstance = new PublicClientApplication(this.getMsalConfig);

    const accounts = this.$msalInstance.getAllAccounts();
    const currentAccount = accounts[0];

    await this.$msalInstance.handleRedirectPromise().then(async () => {
      if (accounts.length == 0) {
        await this.$msalInstance.loginRedirect(request).catch((error) => {
          this.pageLoadingInfo = "Error during authentication";
          console.error(`error during authentication: ${error}`);
        });
      }
      this.$msalInstance.setActiveAccount(currentAccount);
      const accessTokenMSGRaph = await this.$msalInstance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: currentAccount,
          forceRefresh: true,
        })
        .catch(async (error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            return this.getToken("User.Read");
          }
        });
      this.pageLoadingInfo = "Almost there..Getting user info and access...";
      if (process.env.VUE_APP_API_SCOPE) {
        const accessTokenAPI = await this.$msalInstance
          .acquireTokenSilent({
            scopes: [process.env.VUE_APP_API_SCOPE],
            account: currentAccount,
            forceRefresh: true,
          })
          .catch(async (error: any) => {
            if (error instanceof InteractionRequiredAuthError) {
              return this.getToken(process.env.VUE_APP_API_SCOPE);
            }
          });
        this.pageLoadingInfo =
          "Checking user access..Please wait few seconds...";
        if (accessTokenAPI === undefined) {
          this.pageLoadingInfo = "Reloading the page..";
          localStorage.clear();
          location.reload();
        } else {
          this.setAccessTokenAPI(accessTokenAPI);
        }
        this.setAccessTokenMSGraph(accessTokenMSGRaph);
      }
      this.loadUserPicture();
      this.account = currentAccount;
      const responseUserInfo = await UserApi.getUserInfo();
      const userInfo = responseUserInfo.user;
      if (userInfo && isSuccess(responseUserInfo.httpStatus)) {
        this.pageLoadingInfo = "Logged in...";
        this.updateAssignedCountriesAndSitesAndUserRole(
          userInfo.assignedCountriesAndSites
        );
      } else {
        this.pageLoadingInfo = "Error during login";
        this.displayHttpErrorDialog({
          displayHttpErrorDialog: true,
          httpErrorMessage:
            "Get User Info : " +
            getMessageByStatusCode(responseUserInfo.httpStatus),
        });
      }
    });
    this.$q.loading.hide();
  }

  async getToken(scope: any): Promise<any> {
    return this.$msalInstance.acquireTokenRedirect({
      scopes: [scope],
    });
  }
}
