import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00dc49fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row kpi"
}
const _hoisted_2 = { class: "row q-ma-md export-dates" }
const _hoisted_3 = { class: "q-mr-sm q-mt-sm" }
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { class: "q-px-sm q-mt-sm" }
const _hoisted_6 = { class: "row items-center justify-end" }
const _hoisted_7 = { class: "apply-btn" }
const _hoisted_8 = {
  key: 1,
  class: "text-h6 text-blue-10",
  style: {"text-align":"center"}
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  class: "q-mb-sm",
  style: {"border":"1px solid gainsboro","border-radius":"6px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_EquipmentModel = _resolveComponent("EquipmentModel")!
  const _component_EquipmentTag = _resolveComponent("EquipmentTag")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_EditEquipmentCommentPopin = _resolveComponent("EditEquipmentCommentPopin")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.getReportingView === _ctx.ReportingViewType.BARCHART)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_input, {
                  modelValue: _ctx.startDate,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startDate) = $event)),
                  outlined: "",
                  label: "Start date (yyyy/mm/dd)",
                  "stack-label": ""
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "event",
                      class: "cursor-pointer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_popup_proxy, {
                          ref: "qDateProxy",
                          cover: "",
                          "transition-show": "scale",
                          "transition-hide": "scale"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_date, {
                              modelValue: _ctx.startDate,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
                              "default-view": "Months",
                              "emit-immediately": true,
                              mask: "YYYY/MM/DD",
                              options: _ctx.startDateOptions
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_4, [
                                  _withDirectives(_createVNode(_component_q_btn, {
                                    label: "Close",
                                    color: "primary",
                                    flat: ""
                                  }, null, 512 /* NEED_PATCH */), [
                                    [_directive_close_popup]
                                  ])
                                ])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }, 512 /* NEED_PATCH */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_input, {
                  modelValue: _ctx.endDate,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endDate) = $event)),
                  outlined: "",
                  label: "End date (yyyy/mm/dd)",
                  "stack-label": ""
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "event",
                      class: "cursor-pointer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_popup_proxy, {
                          ref: "qDateProxy",
                          cover: "",
                          "transition-show": "scale",
                          "transition-hide": "scale"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_date, {
                              modelValue: _ctx.endDate,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endDate) = $event)),
                              "default-view": "Months",
                              "emit-immediately": true,
                              mask: "YYYY/MM/DD",
                              options: _ctx.endDateOptions
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _withDirectives(_createVNode(_component_q_btn, {
                                    label: "Close",
                                    color: "primary",
                                    flat: ""
                                  }, null, 512 /* NEED_PATCH */), [
                                    [_directive_close_popup]
                                  ])
                                ])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }, 512 /* NEED_PATCH */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_q_btn, {
                  outline: "",
                  rounded: "",
                  "no-caps": "",
                  label: "Apply",
                  class: "action-button export-button",
                  disable: !_ctx.startDate || !_ctx.endDate,
                  onClick: _ctx.apply
                }, null, 8 /* PROPS */, ["disable", "onClick"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.showPageLoadingInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.pageLoadingInfo), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipmentsGroupedBySystemAndModelAndDesc, (equipmentsGroupedBySystem, system) => {
      return (_openBlock(), _createElementBlock("div", { key: system }, [
        (_ctx.getReportingView !== _ctx.ReportingViewType.BARCHART)
          ? (_openBlock(), _createBlock(_component_q_expansion_item, {
              key: 0,
              modelValue: _ctx.expansion_items['weekly-report-' + system],
              "onUpdate:modelValue": ($event: any) => ((_ctx.expansion_items['weekly-report-' + system]) = $event),
              class: "q-ma-lg container",
              label: system,
              "expand-icon-class": "expansion-icon",
              "data-test": "equipment-system"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card, { class: "q-mx-lg container" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_separator, { inset: "" }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentsGroupedBySystem, (equipmentGroupedByModel, modelName) => {
                      return (_openBlock(), _createElementBlock("div", { key: modelName }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_EquipmentModel, {
                            "model-name": equipmentGroupedByModel[0].equipmentModel.name,
                            description: equipmentGroupedByModel[0].name_description
                          }, null, 8 /* PROPS */, ["model-name", "description"])
                        ]),
                        _createVNode(_component_q_card_section, { class: "equipment-line" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentGroupedByModel, (equipment) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: equipment.id
                              }, [
                                _createVNode(_component_EquipmentTag, {
                                  equipment: equipment,
                                  "weekly-comments": true
                                }, null, 8 /* PROPS */, ["equipment"])
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "label"]))
          : _createCommentVNode("v-if", true),
        (_ctx.getReportingView === _ctx.ReportingViewType.BARCHART)
          ? (_openBlock(), _createBlock(_component_q_expansion_item, {
              key: 1,
              class: "q-ma-lg container",
              label: system,
              "expand-icon-class": "expansion-icon",
              "data-test": "equipment-system",
              onBeforeShow: _ctx.expand
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card, { class: "q-mx-lg container" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_separator, { inset: "" }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentsGroupedBySystem, (equipmentGroupedByModel, modelName) => {
                      return (_openBlock(), _createElementBlock("div", { key: modelName }, [
                        _createVNode(_component_q_card_section, { class: "equipment-line" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentGroupedByModel, (equipmentDescription, description) => {
                              return (_openBlock(), _createElementBlock("div", { key: description }, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_EquipmentModel, {
                                    "model-name": modelName,
                                    description: description,
                                    availability: equipmentDescription.availability,
                                    reliability: equipmentDescription.reliability,
                                    "eq-description-eqs": equipmentDescription.equipments,
                                    "equipment-statistics": 
                      equipmentDescription.statistics12Months
                    
                                  }, null, 8 /* PROPS */, ["model-name", "description", "availability", "reliability", "eq-description-eqs", "equipment-statistics"])
                                ])
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "onBeforeShow"]))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createVNode(_component_EditEquipmentCommentPopin, {
      onOnClosed: _ctx.onClosedEditEquipmentCommentPopin,
      onUpdatedEquipmentComment: _ctx.updatedEquipmentComment
    }, null, 8 /* PROPS */, ["onOnClosed", "onUpdatedEquipmentComment"])
  ]))
}