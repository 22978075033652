
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { Equipment } from "../../types/equipment";
import { SparePart } from "../../types/spare-part";
import { isNumber } from "@/helpers/equipment-comment-helper";

@Options({
  name: "AddSparePartDialog",
  methods: {
    ...mapActions(["displayAddSparePartDialog", "addSparePart"]),
  },
  computed: {
    ...mapGetters(["getEquipmentDetails", "getDisplayAddSparePartDialog"]),
  },
  data() {
    return {
      materialNumber: "",
    };
  },
})
export default class AddSparePartDialog extends Vue {
  displayAddSparePartDialog!: (displayAddSparePartDialog: boolean) => void;
  getEquipmentDetails!: Equipment;
  confirmPopUpTitle = "Status update";
  datepickerConfirmDialog = false;
  materialNumber!: number | undefined;
  addSparePart!: ({
    equipmentId,
    materialNumber,
  }: {
    equipmentId: number;
    materialNumber: number | undefined;
  }) => Promise<SparePart[] | undefined>;

  isNumberCheck(evt: KeyboardEvent): void {
    isNumber(evt);
  }

  async onConfirm(): Promise<void> {
    const newSpareParts = await this.addSparePart({
      equipmentId: this.getEquipmentDetails.id,
      materialNumber: this.materialNumber,
    });
    if (newSpareParts && newSpareParts.length > 0) {
      this.showNotif();
      this.onClose();
    } else {
      if (newSpareParts) this.showNotifAlreadyExist();
    }
  }

  onClose(): void {
    this.materialNumber = undefined;
    this.displayAddSparePartDialog(false);
  }

  async showNotif(): Promise<void> {
    this.$q.notify({
      message: "New spare part added",
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  async showNotifAlreadyExist(): Promise<void> {
    this.$q.notify({
      message: "This spare part already exists for this equipment",
      color: "negative",
      textColor: "white",
      actions: [{ icon: "close", color: "white" }],
    });
  }
}
