
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapActions } from "vuex";
import { ReportingViewType } from "../../types/reporting-view";
import { UserRole } from "../../types/user-info";
import { isUserUnknown, isUserGuest } from "../../helpers/user-role-helper";
import moment from "moment-timezone";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      "getReportingView",
      "getUserRole",
      "getIsReportingWeeklyReportExpanded",
      "getDateRangeForWeeklyReport",
    ]),
  },
  methods: {
    ...mapActions([
      "updateReportingView",
      "reportsUpdated",
      "updateIsReportingWeeklyReportExpanded",
      "updateDateRangeForWeeklyReport",
    ]),
  },
  data() {
    return {
      selectedWeek: "",
    };
  },

  name: "ReportingFilters",
})
export default class ReportingFilters extends Vue {
  ReportingViewType = ReportingViewType;
  getUserRole!: UserRole;
  getIsReportingWeeklyReportExpanded!: boolean;
  getReportingView!: ReportingViewType;
  getDateRangeForWeeklyReport!: string;
  updateReportingView!: (reportingView: ReportingViewType) => void;
  updateIsReportingWeeklyReportExpanded!: (isExpanded: boolean) => void;
  updateDateRangeForWeeklyReport!: (value: string) => void;
  selectedWeek!: string | null;
  startDate!: string;
  endDate!: string;
  reportsUpdated!: ({ fromDate, toDate }: any) => Promise<void>;
  async mounted(): Promise<void> {
    this.updateDateRangeForWeeklyReport("");
    this.updateReportingView(ReportingViewType.WEEKLY);
  }

  get reportingView(): ReportingViewType {
    return this.getReportingView;
  }

  set reportingView(reportingView: ReportingViewType) {
    this.updateReportingView(reportingView);
  }

  get expandButtonLabel(): string {
    return this.getIsReportingWeeklyReportExpanded
      ? "Collapse all"
      : "Expand all";
  }

  defineAvailableWeeks = (): string[] => {
    const toDate = moment().toDate();
    let availbleWeeks: string[] = [];
    for (let i = 0; i < 52; i++) {
      let value = moment(toDate).subtract(i, "week").toDate();
      let week = moment(value).week();
      let year = moment(value).year();
      availbleWeeks.push(`${week}/${year}`);
    }
    return availbleWeeks;
  };
  availableWeeks: string[] = this.defineAvailableWeeks();
  openOrCloseAllExpansionItems(): void {
    this.updateIsReportingWeeklyReportExpanded(
      !this.getIsReportingWeeklyReportExpanded
    );
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  async weekSelectedHandler(val: any): Promise<void> {
    this.selectedWeek = val;
    const splitted = this.selectedWeek?.split("/");
    if (splitted && splitted?.length != 0) {
      const week = parseInt(splitted[0]);
      const year = parseInt(splitted[1]);
      this.startDate = moment()
        .year(year)
        .week(week)
        .startOf("week")
        .format("DD-MM-YYYY");
      this.endDate = moment()
        .year(year)
        .week(week)
        .endOf("week")
        .format("DD-MM-YYYY");
      let range = `From ${this.startDate} to ${this.endDate}`;
      this.updateDateRangeForWeeklyReport(range);
      if (this.getReportingView === ReportingViewType.WEEKLY) {
        // this.showPageLoadingInfo = true;
        // this.pageLoadingInfo = "Loading weekly report data...";
        this.$q.loading.show();
        const toDate = moment(this.endDate, "DD MM YYYY hh:mm:ss").toDate();
        const fromDate = moment(this.startDate, "DD MM YYYY hh:mm:ss").toDate();
        //this await function results in slowing down the reporting page.
        // this.pageLoadingInfo =
        //   "Loading comments and 12 months avl/rel data for each equipment in weekly report section...";
        await this.reportsUpdated({
          fromDate,
          toDate,
        });
        this.$q.loading.hide();
      }
    }
  }
}
