import {
  EquipmentParamDetails,
  Trade,
  StopCodes,
  StopCodeDetails,
  EquipmentDetails,
} from "@/types/equipment-tree";
import { StatusEventEnum, convertStatusEventEnum } from "@/types/status-events";
import { equipmentStatuses } from "./equipment-status-helpers";

export enum EquipmentCondition {
  AVAILABLE = "available",
  DOWNGRADED_AVAILABLE = "downgraded_available",
  UNAVAILABLE = "unavailable",
}

export function mapEquipmentConditionEnumToString(
  condition: string | undefined
): string {
  if (condition === EquipmentCondition.AVAILABLE) {
    return "Available";
  } else if (condition === EquipmentCondition.UNAVAILABLE) {
    return "Unavailable";
  } else if (condition === EquipmentCondition.DOWNGRADED_AVAILABLE) {
    return "Downgraded Available";
  } else return "";
}

export function mapConditionStringToEnum(
  condition: string | undefined
): string | undefined {
  if (condition === "Available") {
    return EquipmentCondition.AVAILABLE;
  } else if (condition === "Downgraded Available") {
    return EquipmentCondition.DOWNGRADED_AVAILABLE;
  } else if (condition === "Unavailable") {
    return EquipmentCondition.UNAVAILABLE;
  }
}

export function getTrade(
  id: number | undefined,
  getEquipmentParam: EquipmentParamDetails
): Trade | undefined {
  const tradeData = getEquipmentParam?.tradeType;
  let trade: any;
  tradeData.forEach((tradeDataItem: Trade) => {
    if (tradeDataItem.id === id) trade = tradeDataItem;
  });
  return trade;
}

export function getEquipmentStatus(
  getEquipmentParam: EquipmentParamDetails
): any[] {
  const stopCodeData = getEquipmentParam?.stopCodes;
  const status: any[] = [];
  if (stopCodeData) {
    stopCodeData.forEach((item: StopCodes) => {
      item.details.forEach((details: StopCodeDetails) => {
        if (
          status.findIndex((item) => item.label === details.oneCbmName) === -1
        ) {
          const obj = {
            label: details.oneCbmName,
            value: getEquipmentStatusValue(details.oneCbmName),
          };
          status.push(obj);
        }
      });
    });
  }
  return status;
}

function getEquipmentStatusValue(label: string): string {
  let value = "";
  equipmentStatuses.forEach((equipmentStatus) => {
    if (equipmentStatus.label === label) {
      value = equipmentStatus.value;
      return value;
    }
  });
  return value;
}

export function displayAdditionalInformation(
  getSelectedStatusEvent: StatusEventEnum,
  getEquipmentParam: EquipmentParamDetails
): boolean {
  let display = false;
  const event = convertStatusEventEnum(getSelectedStatusEvent);
  if (event) {
    const stopCodedetail = findEventDetails(event, getEquipmentParam);
    if (stopCodedetail?.additionalInformation !== undefined) {
      display = stopCodedetail.additionalInformation;
      if (display === null || display === false) return false;
      return true;
    }
  }
  return display;
}

export function getEventDisplayEquipementList(
  getSelectedStatusEvent: StatusEventEnum,
  getEquipmentParam: EquipmentParamDetails
): boolean {
  let display = false;
  const event = convertStatusEventEnum(getSelectedStatusEvent);
  if (event) {
    const eventDetail = findEventDetails(event, getEquipmentParam);
    if (eventDetail) {
      switch (eventDetail.detailedComponent) {
        case "NO":
        case null:
          break;
        default:
          display = true;
      }
    }
  }
  return display;
}

export function getDisplayShortfall(
  getSelectedStatusEvent: StatusEventEnum,
  getEquipmentParam: EquipmentParamDetails
): boolean {
  let display = false;
  const event = convertStatusEventEnum(getSelectedStatusEvent);
  if (event) {
    const eventDetail = findEventDetails(event, getEquipmentParam);
    if (eventDetail) {
      display = eventDetail.shortfall;
      if (display === null || display === false) return false;
      return true;
    }
  }
  return display;
}

function findEventDetails(
  name: string,
  getEquipmentParam: EquipmentParamDetails
): StopCodeDetails | undefined {
  let eventdetail: any;
  const eventData = getEquipmentParam?.stopCodes;
  if (eventData) {
    eventData.forEach((event: StopCodes) => {
      event.details.forEach((detail: StopCodeDetails) => {
        if (detail.oneCbmType === name) {
          eventdetail = detail;
          return eventdetail;
        }
      });
    });
  }
  return eventdetail;
}

export function getShowEquipmentHierarchy(
  id: number | undefined,
  getEquipmentParam: EquipmentParamDetails
): string {
  let hierarchy = "";
  if (id) {
    let eqId: number | undefined | null = id;
    let eqDetails = findEquipmentDetails(eqId, getEquipmentParam);
    hierarchy += eqDetails?.equipmentItem;
    eqId = eqDetails?.equipmentParentId;
    while (eqDetails !== undefined && eqId !== undefined && eqId !== null) {
      eqDetails = findEquipmentDetails(eqId, getEquipmentParam);
      hierarchy = eqDetails?.equipmentItem + " >> " + hierarchy;
      eqId = eqDetails?.equipmentParentId;
    }
  }
  return hierarchy;
}

export function findEquipmentDetails(
  id: number | undefined,
  getEquipmentParam: EquipmentParamDetails
): EquipmentDetails | undefined {
  let eqdetail: any;
  const eqData = getEquipmentParam?.equipmentDetails;
  if (eqData) {
    eqData.forEach((event: EquipmentDetails) => {
      if (event.id === id) {
        eqdetail = event;
        return eqdetail;
      }
    });
  }
  return eqdetail;
}
