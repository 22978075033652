
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ProductReference } from "../../types/product-references";
import { EquipmentStatistics } from "@/types/equipment-statistics";
import { EquipmentComment } from "@/types/equipment-comment";
import moment from "moment";
import {
  convertEnumInformationType,
  getMonthNumber,
} from "@/helpers/equipment-comment-helper";
import { Equipment } from "@/types/equipment";
import { EquipmentStatus } from "@/types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import _ from "lodash";
import {
  convertEnumStatus,
  formatDate,
  getClassForStatusText,
} from "@/helpers/equipment-status-helpers";
import { convertStatusEventEnum } from "@/types/status-events";

@Options({
  name: "EquipmentCommentsForBarChart",
  props: {
    equipment: {
      type: Object,
      required: false,
    },
    months: {
      type: Array,
      required: false,
    },
    clickedAvlRel: {
      type: Number,
      required: false,
    },
    dataPointIndex: {
      type: Number,
      required: false,
    },
  },
  methods: {
    ...mapActions(["equipmentCommentsForBarChart"]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentCommentsForBarChart",
      "getDisplayEquipmentCommentForBarChartPopin",
    ]),
  },
  components: {
    EquipmentStatusIcon,
  },
  data() {
    return {
      commentsLoadingStatus: "Comments are loading....",
    };
  },
})
export default class EquipmentCommentsForBarChart extends Vue {
  isEditMode!: boolean;
  currentProductReference!: ProductReference;
  equipment!: EquipmentStatistics;
  months!: string[];
  clickedAvlRel!: number;
  dataPointIndex!: number;
  commentsLoadingStatus!: string;
  getEquipmentCommentsForBarChart!: EquipmentComment[];
  equipmentCommentsForBarChart!: (equipmentId: any) => Promise<void>;
  $q: any;

  setup(): void {
    this.$q = useQuasar();
  }

  async mounted(): Promise<void> {
    if (this.equipment) {
      await this.equipmentCommentsForBarChart(this.equipment.id);
    }
    this.equipment.equipmentComments = this.getEquipmentCommentsForBarChart;

    this.equipment.equipmentComments = this.equipment.equipmentComments.filter(
      (comment: EquipmentComment) =>
        comment.author === null || comment.author?.toString() !== "OneCBM"
    );

    if (this.commentsByStatuses().length === 0) {
      this.commentsLoadingStatus = "No comments...";
    }
  }

  formateDate(date: string): string {
    const tz = moment.tz.guess();
    return moment(date).tz(tz).format("YYYY/MM/DD HH:mm");
  }

  getInformationTypeLabel(informationType: string): string {
    return convertEnumInformationType(informationType);
  }

  commentsByStatuses(): any {
    let equipmentStatuses: any;
    if (this.equipment.equipmentStatuses?.length) {
      equipmentStatuses = _.sortBy(this.equipment.equipmentStatuses, "date");
    } else {
      equipmentStatuses = [
        {
          comment: null,
          date: "1700-01-01T08:05:00.000Z",
          equipmentDetailsId: null,
          equipmentMajorFailure: null,
          event: null,
          fireConfirmed: null,
          flaring: null,
          gasOrOilLeak: null,
          id: null,
          productionShortfall: null,
          status: null,
          tradeTypeId: null,
          waterInjectShortfall: null,
        },
      ];
    }
    let commentsByStatuses = [];
    commentsByStatuses = _.map(
      equipmentStatuses,
      (status: EquipmentStatus, index: number) => {
        const nextStatus = equipmentStatuses[index + 1];
        let nextStatusDate = nextStatus ? nextStatus.date : new Date();

        const equipmentComments = this.filterCommentsForStatus(
          this.equipment,
          status,
          nextStatusDate
        );

        return {
          status: status,
          comments: _.orderBy(equipmentComments, "date", "desc"),
          statusEndDate: nextStatusDate,
        };
      }
    );

    commentsByStatuses = _.orderBy(
      commentsByStatuses.filter(
        (commentByStatus) => commentByStatus.comments.length > 0
      ),
      "status.date",
      "desc"
    );
    return commentsByStatuses.filter(
      (commentByStatus) =>
        ("0" + (new Date(commentByStatus.status.date).getMonth() + 1)).slice(
          -2
        ) <= getMonthNumber(this.months[this.dataPointIndex].split(" ")[0]) &&
        ("0" + (new Date(commentByStatus.statusEndDate).getMonth() + 1)).slice(
          -2
        ) >= getMonthNumber(this.months[this.dataPointIndex].split(" ")[0]) &&
        new Date(commentByStatus.status.date).getFullYear() <=
          Number(this.months[this.dataPointIndex].split(" ")[1]) &&
        new Date(commentByStatus.statusEndDate).getFullYear() >=
          Number(this.months[this.dataPointIndex].split(" ")[1])
    );
  }

  generateSeperatorText(
    status: EquipmentStatus,
    statusEndDate: string
  ): string {
    return `${convertEnumStatus(status.status)} - From ${formatDate(
      status.date
    )} to ${formatDate(statusEndDate)} - ${
      convertStatusEventEnum(status.event)
        ? convertStatusEventEnum(status.event)
        : "Unknown Event"
    }`;
  }

  getClass(comment: EquipmentComment): any {
    return getClassForStatusText(comment?.status?.status);
  }

  filterCommentsForStatus(
    equipment: Equipment,
    status: EquipmentStatus,
    nextStatusDate: Date | string
  ): EquipmentComment[] | undefined {
    return equipment.equipmentComments?.filter((comment: any) => {
      return (
        new Date(comment.date) >= new Date(status.date) &&
        new Date(comment.date) < new Date(nextStatusDate)
      );
    });
  }

  onClose(): void {
    this.equipment.equipmentComments = [];
    this.$emit("onClose");
  }
}
