
import { DryGasSealType, EquipmentComment } from "@/types/equipment-comment";
import { EquipmentCommentFile } from "@/types/equipment-comment-file";
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import { Equipment } from "@/types/equipment";
import date from 'quasar/src/utils/date.js';;
import Datepicker from "../equipment-status/Datepicker.vue";
import { ref, unref } from "vue";
import moment from "moment-timezone";
import {
  AnalysisType,
  ElecInsulationAnalysis,
  ElecOilAnalysis,
  InformationType,
  OilType,
  QualityKPI,
} from "../../types/equipment-comment";
import {
  getClassForQualityKpiText,
  getClassForQualityKpiIcon,
  getQualityKpiIcon,
  informationTypes,
  informationTypesTransformers,
  qualityKpis,
  analysisTypes,
  oilTypes,
  dryGasSealTypes,
  dryGasSealTypesForDrivenPUMP,
  informationTypesForDrivenPUMP,
  informationTypesForDrivenCOMP,
  isNumber,
} from "../../helpers/equipment-comment-helper";
import _ from "lodash";
import { uploadFile } from "../../helpers/file-upload-helper";
import { EquipmentSystem } from "../../types/equipment-model";
import ElecAnalysis from "./ElecAnalysis.vue";

@Options({
  name: "AddEquipmentCommentPopin",
  components: {
    Datepicker,
    ElecAnalysis,
  },
  methods: {
    ...mapActions([
      "addEquipmentComment",
      "displayAddCommentPopin",
      "claimSasToken",
      "addEquipmentCommentFile",
    ]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentDetails",
      "getDisplayAddCommentPopin",
      "getSasToken",
      "getAuthInfoAPI",
    ]),
  },
  data() {
    return {
      informationTypes,
      informationTypesForDrivenPUMP,
      informationTypesForDrivenCOMP,
      informationTypesTransformers,
      analysisTypes,
      analysisType: "",
      oilType: "",
      oilTypes,
      dryGasSealType: "",
      dryGasSealTypes,
      dryGasSealTypesForDrivenPUMP,
      qualityKpi: "",
      qualityKpis,
      oilAnalysis: {},
      insulationAnalysis: {},
      addToWeeklyReport: false,
      isAlert: false,
    };
  },
})
export default class AddEquipmentCommentPopin extends Vue {
  equipmentSystemEnum = EquipmentSystem;
  informationTypeEnum = InformationType;
  files: File[] | null = null;
  selectedDate = ref("");
  comment = ref("");
  woNumber = "";
  author = ref("");
  addToWeeklyReport = false;
  isAlert = false;
  selectedInformation = InformationType.OTHER;
  _analysisType?: AnalysisType;
  _qualityKpi?: QualityKPI;
  _oilType?: OilType;
  _dryGasSealType?: DryGasSealType;
  oilAnalysis: ElecOilAnalysis = {
    elecMoistureWater: undefined,
    elecDissolvedGasHydrogen: undefined,
    elecDissolvedGasMethane: undefined,
    elecDissolvedGasAcethylene: undefined,
    elecDissolvedGasEthylene: undefined,
    elecDissolvedGasEthane: undefined,
    elecDissolvedGasMonoxide: undefined,
    elecDissolvedGasDioxide: undefined,
    elecDissolvedGasTdcg: undefined,
  };
  insulationAnalysis: ElecInsulationAnalysis = {
    mean: undefined,
    standardDeviation: undefined,
    ratioMeanStandardDeviation: undefined,
  };

  getEquipmentDetails!: Equipment;
  displayAddCommentPopin!: (displayAddCommentPopin: boolean) => void;
  claimSasToken!: () => Promise<void>;
  getSasToken!: string;
  getAuthInfoAPI!: any;

  isNumberCheck(evt: KeyboardEvent): void {
    isNumber(evt);
  }

  get selectedInformationType(): InformationType {
    return this.selectedInformation;
  }

  set selectedInformationType(type: InformationType) {
    if (
      type === InformationType.OTHER ||
      type === InformationType.SERVICE_BULLETINS
    ) {
      this.analysisType = undefined;
      this.qualityKpi = undefined;
      this.oilType = undefined;
      this.dryGasSealType = undefined;
    } else {
      this.analysisType = AnalysisType.ROUTINE;
      this.qualityKpi = undefined;
      if (type === InformationType.OIL) {
        this.oilType = OilType.HYD_OIL;
      } else {
        this.oilType = undefined;
      }
    }
    if (
      this.getEquipmentDetails?.equipmentModel?.system ===
      EquipmentSystem.TRANSFORMERS
    ) {
      if (type !== InformationType.OIL) {
        this.oilAnalysis = {
          elecMoistureWater: undefined,
          elecDissolvedGasHydrogen: undefined,
          elecDissolvedGasMethane: undefined,
          elecDissolvedGasAcethylene: undefined,
          elecDissolvedGasEthylene: undefined,
          elecDissolvedGasEthane: undefined,
          elecDissolvedGasMonoxide: undefined,
          elecDissolvedGasDioxide: undefined,
          elecDissolvedGasTdcg: undefined,
        };
      }
      if (type !== InformationType.INSULATION) {
        this.insulationAnalysis = {
          mean: undefined,
          standardDeviation: undefined,
          ratioMeanStandardDeviation: undefined,
        };
      }
    }
    this.selectedInformation = type;
  }

  get analysisType(): AnalysisType | undefined {
    return this._analysisType;
  }

  set analysisType(type: AnalysisType | undefined) {
    this._analysisType = type;
  }

  get qualityKpi(): QualityKPI | undefined {
    return this._qualityKpi;
  }

  set qualityKpi(quality: QualityKPI | undefined) {
    this._qualityKpi = quality;
  }

  get oilType(): OilType | undefined {
    return this._oilType;
  }

  set oilType(type: OilType | undefined) {
    this._oilType = type;
  }

  get dryGasSealType(): DryGasSealType | undefined {
    return this._dryGasSealType;
  }

  set dryGasSealType(type: DryGasSealType | undefined) {
    this._dryGasSealType = type;
  }

  addEquipmentCommentFile!: ({
    equipmentCommentFile,
    equipmentComment,
    equipmentId,
  }: {
    equipmentCommentFile: EquipmentCommentFile;
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentCommentFile>;

  updated(): void {
    const timeStamp = Date.now();
    this.selectedDate = ref(date.formatDate(timeStamp, "YYYY-MM-DD HH:mm"));
    this.author = this.getAuthInfoAPI?.account?.name;
  }

  onClose(): void {
    this.selectedDate = ref("");
    this.comment = ref("");
    this.woNumber = "";
    this.files = null;
    this.addToWeeklyReport = false;
    this.isAlert = false;
    this.selectedInformationType = InformationType.OTHER;
    this.analysisType = undefined;
    this.qualityKpi = undefined;
    this.oilType = undefined;
    this.dryGasSealType = undefined;
    this.oilAnalysis = {
      elecMoistureWater: undefined,
      elecDissolvedGasHydrogen: undefined,
      elecDissolvedGasMethane: undefined,
      elecDissolvedGasAcethylene: undefined,
      elecDissolvedGasEthylene: undefined,
      elecDissolvedGasEthane: undefined,
      elecDissolvedGasMonoxide: undefined,
      elecDissolvedGasDioxide: undefined,
      elecDissolvedGasTdcg: undefined,
    };
    this.insulationAnalysis = {
      mean: undefined,
      standardDeviation: undefined,
      ratioMeanStandardDeviation: undefined,
    };
    this.displayAddCommentPopin(false);
  }

  showNotif(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `A new comment for ${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } has been added.`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  get getClassQuality(): any {
    return getClassForQualityKpiText(this.qualityKpi);
  }

  get getClassQualityIcon(): any {
    return getClassForQualityKpiIcon(this.qualityKpi);
  }

  get icon(): string {
    return getQualityKpiIcon(this.qualityKpi);
  }

  async onSubmit(): Promise<void> {
    if (
      this.selectedInformationType !== InformationType.OTHER &&
      this.selectedInformationType !== InformationType.SERVICE_BULLETINS &&
      this.qualityKpi === undefined
    ) {
      this.$q.notify({
        message: `Please select Quality KPI`,
        color: "negative",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
      return;
    }
    this.$q.loading.show();
    const tz = moment.tz.guess();
    const formattedDate = new Date(
      moment.tz(this.selectedDate, tz).toString()
    ).toString();
    const comment = {
      date: formattedDate,
      comment: this.comment,
      woNumber: parseInt(this.woNumber),
      equipmentCommentFiles: [],
      author: this.author,
      informationType: this.selectedInformationType,
      analysisType: this.getAnalysisType(),
      qualityKpi: this.getQualityKpi(),
      oilType: this.getOilType(),
      dryGasSealComponent: this.getDryGasSealType(),
      addToWeeklyReport: this.addToWeeklyReport,
      isAlert: this.isAlert,
    } as EquipmentComment;
    if (
      this.getEquipmentDetails?.equipmentModel?.system ===
      EquipmentSystem.TRANSFORMERS
    ) {
      if (this.selectedInformationType === InformationType.OIL) {
        comment.elecMoistureWater = this.oilAnalysis.elecMoistureWater;
        comment.elecDissolvedGasHydrogen =
          this.oilAnalysis.elecDissolvedGasHydrogen;
        comment.elecDissolvedGasMethane =
          this.oilAnalysis.elecDissolvedGasMethane;
        comment.elecDissolvedGasAcethylene =
          this.oilAnalysis.elecDissolvedGasAcethylene;
        comment.elecDissolvedGasEthylene =
          this.oilAnalysis.elecDissolvedGasEthylene;
        comment.elecDissolvedGasEthane =
          this.oilAnalysis.elecDissolvedGasEthane;
        comment.elecDissolvedGasMonoxide =
          this.oilAnalysis.elecDissolvedGasMonoxide;
        comment.elecDissolvedGasDioxide =
          this.oilAnalysis.elecDissolvedGasDioxide;
        comment.elecDissolvedGasTdcg = this.oilAnalysis.elecDissolvedGasTdcg;
      }
      if (this.selectedInformationType === InformationType.INSULATION) {
        comment.mean = this.insulationAnalysis.mean;
        comment.standardDeviation = this.insulationAnalysis.standardDeviation;
        comment.ratioMeanStandardDeviation =
          this.insulationAnalysis.ratioMeanStandardDeviation;
      }
    }
    const equipmentComment = await this.addEquipmentComment({
      equipmentComment: comment,
      equipmentId: this.getEquipmentDetails.id,
    });
    if (equipmentComment) {
      this.uploadEquipmentCommentFiles(equipmentComment, tz);
      this.showNotif();
    }

    this.$q.loading.hide();
    this.onClose();
  }

  async uploadEquipmentCommentFiles(
    equipmentComment: EquipmentComment,
    tz: string
  ): Promise<void> {
    if (this.files) {
      for (let file of this.files) {
        await this.claimSasToken();
        const token = this.getSasToken;
        if (token) {
          const blobName = await uploadFile(
            token,
            file,
            equipmentComment.id,
            tz
          );
          const equipmentCommentFile: EquipmentCommentFile = {
            name: blobName,
          };
          this.addEquipmentCommentFile({
            equipmentCommentFile,
            equipmentComment,
            equipmentId: this.getEquipmentDetails.id,
          });
        }
      }
    }
  }

  getAnalysisType(): AnalysisType | undefined {
    return this.selectedInformationType !== InformationType.OTHER &&
      this.selectedInformationType !== InformationType.SERVICE_BULLETINS
      ? this.analysisType
      : undefined;
  }

  getQualityKpi(): QualityKPI | undefined {
    return this.selectedInformationType !== InformationType.OTHER &&
      this.selectedInformationType !== InformationType.SERVICE_BULLETINS
      ? this.qualityKpi
      : undefined;
  }

  getOilType(): OilType | undefined {
    return this.selectedInformationType === InformationType.OIL
      ? this.oilType
      : undefined;
  }

  getDryGasSealType(): DryGasSealType | undefined {
    return this.selectedInformationType === InformationType.DRY_GAS_SEAL
      ? this.dryGasSealType
      : undefined;
  }

  addEquipmentComment!: ({
    equipmentComment,
    equipmentId,
  }: {
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentComment>;

  isValidDate(dateToVerify: string): boolean {
    if (_.isEmpty(dateToVerify)) return false;
    const momentDate = moment(dateToVerify);
    return momentDate.isValid();
  }

  minDate(date: string): boolean {
    const momentDate = moment(date, "YYYY-MM-DD");
    return momentDate.isBefore(moment());
  }

  minTime(hour: number, minute: number): boolean {
    const momentSelectedDate = moment(unref(this.selectedDate), "YYYY/MM/DD");
    momentSelectedDate.set({ hour, minute });
    return momentSelectedDate.isBefore(moment());
  }

  getDryGasSealLabel(): string {
    if (this.getEquipmentDetails.driven === "Compressor")
      return "Dry gas seal type";
    else if (this.getEquipmentDetails.driven === "Pump")
      return "Mechanical seal type";
    else return "";
  }
}
