import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!

  return (_openBlock(), _createBlock(_component_q_avatar, {
    size: "50px",
    class: "q-mt-sm",
    color: "primary",
    "text-color": "white"
  }, {
    default: _withCtx(() => [
      (_ctx.getUserPicture)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.getUserPicture,
            alt: "user-avatar"
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      (!_ctx.getUserPicture)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getAuthInfoMSGraph.account.name
          .match(/\b(\w)/g)
          .join("")
          .toUpperCase()), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}