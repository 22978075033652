
import { Options, Vue } from "vue-class-component";
import type { Equipment } from "../../types/equipment";
import { mapActions, mapGetters } from "vuex";
import EquipmentModel from "../equipment/EquipmentModel.vue";
import EquipmentTag from "../equipment/EquipmentTag.vue";
import EquipmentSelectorFilter from "./EquipmentSelectorFilter.vue";
import StatusSelectorFilter from "./StatusSelectorFilter.vue";
import SectorSelectorFilter from "./SectorSelectorFilter.vue";
import CriticalFilter from "./CriticalFilter.vue";
import StatusBoxFilter from "./StatusOnlyFilter.vue";
import StatusChangeFromFilter from "./StatusChangeFromFilter.vue";
import _ from "lodash";
import fp from "lodash/fp";
import PageTitle, { PageEnum } from "../common/PageTitle.vue";
import EquipmentsWithAlertsFilter from "./EquipmentsWithAlertsFilter.vue";
import { EquipmentStatuses } from "@/types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
@Options({
  components: {
    EquipmentModel,
    EquipmentTag,
    PageTitle,
    EquipmentSelectorFilter,
    StatusSelectorFilter,
    CriticalFilter,
    StatusChangeFromFilter,
    EquipmentsWithAlertsFilter,
    SectorSelectorFilter,
    EquipmentStatusIcon,
    StatusBoxFilter,
  },
  methods: {
    ...mapActions([
      "resetFilters",
      "equipmentListLoaded",
      "updateSelectedSiteAndUserRole",
      "updateExpandedItems",
      "updateSelectedCountryBloc",
      "updateFilters",
    ]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentListFiltered",
      "getExpandedItems",
      "getAreAllExpanded",
      "getSelectedCountryBloc",
      "getSelectedSite",
      "getStatusCheckboxFilter",
    ]),
  },
  name: "EquipmentList",
  data() {
    return {
      expansion_items: {},
    };
  },
})
export default class EquipmentList extends Vue {
  pageEnum = PageEnum;
  expansion_items!: { [key: string]: boolean };
  areAllOpened = true;

  getEquipmentListFiltered!: () => Equipment[];
  equipmentListLoaded!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  getAreAllExpanded!: boolean;
  getExpandedItems!: { [key: string]: boolean };
  updateExpandedItems!: ({
    expandedItems,
    areAllExpanded,
  }: {
    expandedItems: { [key: string]: boolean };
    areAllExpanded: boolean;
  }) => void;

  updateFilters!: ({
    selectedStatusFilter,
    criticalCheckboxFilter,
    selectedDateFilter,
    selectedEquipmentFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
    statusCheckboxFilter,
  }: {
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentFilter: string | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;

  get equipmentsGroupedBySystemAndModelAndDesc(): any {
    const equipmentsGroupedBySystem = _.groupBy(
      this.getEquipmentListFiltered,
      "system"
    );

    const manualSortList = [
      "Critical and safety systems",
      "Critical systems",
      "Safety systems",
      "Electric energy",
      "Produced gas processing",
      "Produced liquid processing",
      "Produced water system",
      "Water processing",
      "Subsea processing",
      "Chemical injection",
      "Drain system",
      "Other utilities",
      "Electrical equipment",
      "HVAC",
      "Lifting equipment",
      "Marine",
      "Buoy",
      "Drilling equipment",
      "Dan Bravo",
      "Dagmar",
      "Kraka",
      "Rolf",
      "Roar",
      "Svend",
      "Tyra South East A",
      "Tyra South East B",
      "Valdemar",
    ];

    const equipmentsGroupedBySystemManualOrdered = Object.keys(
      equipmentsGroupedBySystem
    )
      .sort((a, b) => manualSortList.indexOf(a) - manualSortList.indexOf(b))
      .reduce((obj: any, key) => {
        obj[key] = equipmentsGroupedBySystem[key];
        return obj;
      }, {});

    let equipmentsGroupedBySystemOrderedTagId: any = {};
    Object.keys(equipmentsGroupedBySystemManualOrdered).forEach((key) => {
      equipmentsGroupedBySystemOrderedTagId[key] = _.sortBy(
        equipmentsGroupedBySystemManualOrdered[key],
        "tagId"
      );
    });
    const equipmentsGroupedBySystemandModelandDesc = _.mapValues(
      equipmentsGroupedBySystemOrderedTagId,
      fp.groupBy(function (obj: Equipment) {
        return [obj.equipmentModel.name + "(" + obj.name_description + ")"];
      })
    );
    return equipmentsGroupedBySystemandModelandDesc;
  }

  checkIfAllExpansionItemsAreOpened(): void {
    const expansionItemsKeys = Object.keys(this.expansion_items);
    if (expansionItemsKeys.length > 0) {
      const unexpandedItem = expansionItemsKeys.find(
        (k) =>
          this.expansion_items[k] &&
          Object.keys(this.equipmentsGroupedBySystemAndModelAndDesc).includes(
            k.substring(17, k.length)
          )
      );
      this.areAllOpened = unexpandedItem ? true : false;
    } else {
      this.areAllOpened = true;
    }
    this.updateExpandedItems({
      expandedItems: this.getExpandedItems,
      areAllExpanded: this.areAllOpened,
    });
  }
  beforeCreate(): void {
    this.$q.loading.show();
  }
  async mounted(): Promise<void> {
    this.$q.loading.show();
    await this.updateSelectedCountryBloc(
      this.$route.params.countryBloc.toString()
    );
    await this.updateSelectedSiteAndUserRole(
      this.$route.params.site.toString()
    );
    await this.equipmentListLoaded(this.$route.params.site.toString());
    this.expansion_items = this.getExpandedItems;
    this.areAllOpened = this.getAreAllExpanded;
    await this.updateFilters({
      selectedStatusFilter: [],
      criticalCheckboxFilter: false,
      selectedDateFilter: undefined,
      selectedEquipmentFilter: undefined,
      selectedEquipmentAlertsFilter: [],
      selectedSectorFilter: [],
      statusCheckboxFilter: true,
    });
  }

  updated(): void {
    this.$q.loading.hide();
    this.checkIfAllExpansionItemsAreOpened();
  }

  unmounted(): void {
    for (let key in this.getExpandedItems) {
      delete this.getExpandedItems[key];
    }
    this.updateFilters({
      selectedStatusFilter: [],
      criticalCheckboxFilter: false,
      selectedDateFilter: undefined,
      selectedEquipmentFilter: undefined,
      selectedEquipmentAlertsFilter: [],
      selectedSectorFilter: [],
      statusCheckboxFilter: false,
    });
  }

  openOrCloseAllExpansionItems(): void {
    this.areAllOpened = !this.areAllOpened;
    Object.keys(this.equipmentsGroupedBySystemAndModelAndDesc).forEach(
      (system: string) => {
        this.expansion_items["collapsible-item-" + system] = this.areAllOpened;
      }
    );
    this.updateExpandedItems({
      expandedItems: this.expansion_items,
      areAllExpanded: this.areAllOpened,
    });
  }

  get expandButtonLabel(): string {
    return this.areAllOpened ? "Collapse all" : "Expand all";
  }
}
