
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import StatusTimelineChart from "./StatusTimelineChart.vue";
import HistoricalStatusDialog from "./HistoricalStatusDialog.vue";
import { EquipmentStatus } from "../../types/equipment-status";
import {
  formatDate,
  convertEnumStatus,
} from "../../helpers/equipment-status-helpers";
import { Equipment } from "../../types/equipment";
import * as _ from "lodash";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { UserRole } from "../../types/user-info";
import { isUserUnknown, isUserGuest } from "../../helpers/user-role-helper";
import moment from "moment";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  components: {
    StatusTimelineChart,
    HistoricalStatusDialog,
    ConfirmDialog,
  },
  methods: {
    ...mapActions(["displayHistoricalStatusDialog", "deleteEquipmentStatus"]),
  },
  computed: {
    ...mapGetters([
      "getDisplayStatusTimeline",
      "getEquipmentDetails",
      "getUserRole",
    ]),
  },
  watch: {
    equipment: function (val, oldVal) {
      if (oldVal?.id !== val?.id) {
        this.selectedStatus = undefined;
      }
    },
  },
  name: "StatusHistoryCard",
  data() {
    return {
      selectedStatus: undefined,
    };
  },
})
export default class StatusCard extends Vue {
  getDisplayStatusTimeline!: boolean;
  displayHistoricalStatusDialog!: ({
    displayHistoricalStatusDialog,
    isEditMode,
  }: {
    displayHistoricalStatusDialog: boolean;
    isEditMode: boolean;
  }) => void;
  selectedStatus: EquipmentStatus | undefined = undefined;
  selectedStatusEndDate = "";
  getEquipmentDetails!: Equipment;
  isEditMode = false;
  confirmPopUpTitle = "Status update";
  displayConfirmDialog = false;
  deleteEquipmentStatus!: (statusId: number) => void;
  getUserRole!: UserRole;
  equipmentRunningHours!: number;
  equipmentRunningMinutes!: number;
  onClickTimelineStatus(statusInfo: { id: number; endDate: string }): void {
    this.selectedStatusEndDate = statusInfo.endDate;
    this.selectedStatus = _.find(
      this.getEquipmentDetails?.equipmentStatuses,
      (equipmentStatus: EquipmentStatus) => {
        return equipmentStatus.id === statusInfo.id;
      }
    );
    this.$emit("onClickTimelineStatus", statusInfo.id);
  }

  get statusInfo(): string {
    const startDate = moment(this.selectedStatus?.date);
    const endDate = moment(this.selectedStatusEndDate);
    const durationDifference = moment.duration(endDate.diff(startDate));
    this.equipmentRunningHours = Math.floor(durationDifference.asHours());
    this.equipmentRunningMinutes = durationDifference.minutes();
    return (
      "Selected status: " +
      convertEnumStatus(this.selectedStatus?.status) +
      " from " +
      formatDate(this.selectedStatus?.date) +
      " to " +
      formatDate(this.selectedStatusEndDate)
    );
  }

  get currentSelectedStatus(): EquipmentStatus | undefined {
    return this.selectedStatus;
  }

  onUpdateStatusClick(): void {
    this.displayHistoricalStatusDialog({
      displayHistoricalStatusDialog: true,
      isEditMode: true,
    });
  }

  get confirmDeleteMessage(): string {
    return (
      "Do you confirm the deletion of status " +
      convertEnumStatus(this.selectedStatus?.status) +
      " starting at " +
      formatDate(this.selectedStatus?.date) +
      "?"
    );
  }
  onDeleteStatusClick(): void {
    this.displayConfirmDialog = true;
  }

  onClose(): void {
    this.displayConfirmDialog = false;
  }

  onDelete(): void {
    if (this.selectedStatus) {
      this.deleteEquipmentStatus(this.selectedStatus.id);
      this.onClose();
    }
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }
}
