
import { AssignedCountryAndSite } from "@/types/user-info";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import router from "../../router";

@Options({
  name: "SiteHomepage",
  props: {
    account: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getAuthInfoAPI", "getAssignedCountriesAndSites"]),
  },
  methods: {
    ...mapActions([
      "updateSelectedSiteAndUserRole",
      "updateSelectedCountryBloc",
    ]),
  },
  data() {
    return {
      sites: [],
    };
  },
})
export default class SiteHomepage extends Vue {
  getAuthInfoAPI!: any;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;
  getAssignedCountriesAndSites!: () => AssignedCountryAndSite[];

  async mounted(): Promise<void> {
    this.$q.loading.show();
    this.updateSelectedSiteAndUserRole("");
    this.$q.loading.hide();
  }

  getCountryAndSite(countryBloc: string): string {
    if (countryBloc === "Denmark-NS") {
      return "Denmark-DUC";
    }
    return countryBloc;
  }

  async setSelectedSite(countryBloc: string, site: string): Promise<void> {
    this.updateSelectedCountryBloc(countryBloc);
    router.push({
      name: "EquipmentList",
      params: { countryBloc, site },
    });
  }
}
