import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "q-pl-md column" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_EquipmentStatusIcon = _resolveComponent("EquipmentStatusIcon")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.getDisplayEquipmentCommentForBarChartPopin,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getDisplayEquipmentCommentForBarChartPopin) = $event)),
      position: "standard",
      "transition-show": "rotate",
      "transition-hide": "rotate"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { class: "popin" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_bar, { class: "row q-pb-none" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, " Status and Comments for " + _toDisplayString(_ctx.months[_ctx.dataPointIndex]) + ": ", 1 /* TEXT */),
                _createVNode(_component_q_space),
                _createVNode(_component_q_btn, {
                  icon: "close",
                  flat: "",
                  round: "",
                  dense: "",
                  onClick: _ctx.onClose
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_q_card_section, { class: "comment-box" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("dl", null, [
                    (_ctx.commentsByStatuses().length === 0)
                      ? (_openBlock(), _createElementBlock("dt", _hoisted_3, _toDisplayString(_ctx.commentsLoadingStatus), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commentsByStatuses(), (object) => {
                      return (_openBlock(), _createElementBlock("dt", {
                        key: object,
                        class: "product-reference q-mb-lg"
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_EquipmentStatusIcon, {
                            status: object.status.status
                          }, null, 8 /* PROPS */, ["status"]),
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.getClass(object.status))
                          }, _toDisplayString(_ctx.generateSeperatorText(object.status, object.statusEndDate)), 3 /* TEXT, CLASS */)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(object.comments, (comment) => {
                          return (_openBlock(), _createElementBlock("div", { key: comment }, [
                            _createElementVNode("b", null, "[" + _toDisplayString(_ctx.formateDate(comment.date)) + "] - " + _toDisplayString(comment.comment), 1 /* TEXT */)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */)),
                        _createVNode(_component_q_separator, { inset: "" })
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}