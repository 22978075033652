
import { Options, Vue } from "vue-class-component";
import {
  convertStatusEventEnum,
  StatusEventEnum,
} from "../../types/status-events";
import { mapActions, mapGetters } from "vuex";
import {
  EquipmentDetails,
  EquipmentParamDetails,
  StopCodeDetails,
  StopCodes,
} from "../../types/equipment-tree";
import { Equipment } from "@/types/equipment";
import { findEquipmentDetails } from "@/helpers/equipment-condition-helper";

@Options({
  name: "EquipmentHierarchy",
  methods: {
    ...mapActions(["updateSelectedEquipment"]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentDetails",
      "getEquipmentParam",
      "getSelectedStatusEvent",
      "getSelectedEquipment",
    ]),
  },
  data() {
    return {
      selectedEquipment: undefined,
      paths: [],
      currentOptions: [],
      initialOptions: [],
      visible: false,
    };
  },
})
export default class EquipmentHierarchy extends Vue {
  equipmentData!: EquipmentDetails[];
  selectedEquipment!: string | undefined;
  paths!: EquipmentDetails[];
  currentOptions!: EquipmentDetails[];
  initialOptions!: EquipmentDetails[];
  visible!: boolean;
  getEquipmentDetails!: Equipment;
  getEquipmentParam!: EquipmentParamDetails;
  getSelectedStatusEvent!: StatusEventEnum;
  updateSelectedEquipment!: (equipment: number | undefined) => void;
  getSelectedEquipment!: number | undefined;

  mounted(): void {
    if (
      this.getEquipmentDetails !== null &&
      this.getEquipmentDetails !== undefined
    ) {
      this.initialOptions = this.getEquipmentParam?.equipmentDetails;
      this.currentOptions = this.getRootNode();
    }
    this.selectedEquipment = this.getSelectedEquipment
      ? this.getEquipmentNameFromId(this.getSelectedEquipment)
      : undefined;
    this.paths = this.getSelectedEquipment
      ? this.makePathArray(this.getSelectedEquipment)
      : [];
    if (this.paths.length === 1) {
      this.$emit("equipment-name", this.paths[0].equipmentItem);
    } else if (this.paths.length > 1) {
      this.$emit("equipment-path", this.paths);
    }
    this.paths = [];
  }

  getEquipmentNameFromId(id: number): string {
    let equipment: any;
    if (id) {
      if (this.getEquipmentParam) {
        this.getEquipmentParam?.equipmentDetails.forEach(
          (eD: EquipmentDetails) => {
            if (eD.id === id) {
              equipment = eD.equipmentItem;
            }
          }
        );
      }
    }
    return equipment;
  }

  makePathArray(id: number): EquipmentDetails[] {
    let hierarchy: EquipmentDetails[] = [];
    if (id) {
      let eqId: number | undefined | null = id;
      let eqDetails = findEquipmentDetails(eqId, this.getEquipmentParam);
      if (eqDetails != undefined) {
        hierarchy.unshift(eqDetails);
      }
      eqId = eqDetails?.equipmentParentId;
      while (eqDetails !== undefined && eqId !== undefined && eqId !== null) {
        eqDetails = findEquipmentDetails(eqId, this.getEquipmentParam);
        if (eqDetails != undefined) {
          hierarchy.unshift(eqDetails);
        }
        eqId = eqDetails?.equipmentParentId;
      }
    }
    return hierarchy;
  }

  eventDisplayEquipementFullList(): boolean {
    let display = false;
    let event = convertStatusEventEnum(this.getSelectedStatusEvent);
    if (event) {
      const eventDetail = this.findEventDetails(event);
      if (eventDetail)
        display = eventDetail.detailedComponent === "YES_ITEM" ? false : true;
    }
    return display;
  }
  findEventDetails(name: string): StopCodeDetails | undefined {
    let eventdetail: any;
    let eventData = this.getEquipmentParam?.stopCodes;
    if (eventData) {
      eventData.forEach((event: StopCodes) => {
        event.details.forEach((detail: StopCodeDetails) => {
          if (detail.oneCbmType === name) {
            eventdetail = detail;
            return eventdetail;
          }
        });
      });
    }
    return eventdetail;
  }

  haveChildren(path: EquipmentDetails): boolean {
    return (
      this.initialOptions.find(
        (t: EquipmentDetails) => t.equipmentParentId === path.id
      ) !== undefined
    );
  }

  toggle(): void {
    this.visible = !this.visible;
  }
  getRootNode(): EquipmentDetails[] {
    if (this.initialOptions) {
      return this.initialOptions.filter(
        (t: EquipmentDetails) => t.equipmentParentId === null
      );
    }
    return [];
  }
  editNode(node: EquipmentDetails, level: number) {
    let siblings = this.getRootNode();
    if (level > 0) {
      const parent = this.paths[level - 1];
      siblings = this.getChildren(parent);
    }
    this.paths = this.paths.splice(0, level);
    this.currentOptions = siblings;
    this.toggle();
    this.selectedEquipment = undefined;
    this.updateSelectedEquipment(undefined);
    this.$emit("equipment-path", []);
    this.$emit("equipment-name", undefined);
  }

  getChildren(path: EquipmentDetails): EquipmentDetails[] {
    if (path && path.id && this.initialOptions) {
      return this.initialOptions.filter(
        (t: EquipmentDetails) => t.equipmentParentId === path.id
      );
    }
    return [];
  }

  selectNode(selectedNode: EquipmentDetails): void {
    if (selectedNode) {
      if (this.eventDisplayEquipementFullList() === true) {
        const haveChildren = this.haveChildren(selectedNode);
        if (this.islastPathNodeALeaf()) {
          this.paths[this.paths.length - 1] = selectedNode;
        } else {
          this.paths.push(selectedNode);
        }

        if (haveChildren) {
          this.currentOptions = this.getChildren(selectedNode);
          this.toggle();
          this.selectedEquipment = undefined;
          this.updateSelectedEquipment(undefined);
          this.$emit("equipment-path", []);
          this.$emit("equipment-name", undefined);
        } else {
          this.selectedEquipment = selectedNode.equipmentItem;
          this.updateSelectedEquipment(selectedNode.id);
          this.$emit("equipment-path", this.paths);
          this.$emit("equipment-name", selectedNode.equipmentItem);
        }
      } else {
        this.selectedEquipment = selectedNode.equipmentItem;
        this.updateSelectedEquipment(selectedNode.id);
        this.$emit("equipment-path", this.paths);
        this.$emit("equipment-name", selectedNode.equipmentItem);
      }
    }
  }

  islastPathNodeALeaf(): boolean {
    let islastPathNodeALeaf = false;
    if (this.paths && this.paths.length > 0) {
      const lastPathNode = this.paths.slice(-1)[0];
      islastPathNodeALeaf = !this.haveChildren(lastPathNode);
    }
    return islastPathNodeALeaf;
  }
}
