
import AlertsCard from "../components/equipment-alerting/AlertsCard.vue";
import { mapActions } from "vuex";
import { Options, Vue } from "vue-class-component";
import PageTitle, { PageEnum } from "../components/common/PageTitle.vue";

@Options({
  components: {
    AlertsCard,
    PageTitle,
  },
  methods: {
    ...mapActions([
      "updateSelectedSiteAndUserRole",
      "updateSelectedCountryBloc",
    ]),
  },
  name: "EquipmentAlerting",
})
export default class EquipmentAlerting extends Vue {
  pageEnum = PageEnum;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;

  async created(): Promise<void> {
    this.updateSelectedCountryBloc(this.$route.params.countryBloc.toString());
    this.updateSelectedSiteAndUserRole(this.$route.params.site.toString());
  }
}
