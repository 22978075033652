
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
import {
  EquipmentMlStatus,
  FeedbackEnum,
} from "../../types/equipment-ml-status";
import { feedbacks } from "../../helpers/equipment-alerting-helper";

@Options({
  name: "AlertFeedbackSelector",
  props: {
    equipmentMlStatus: {
      type: Object,
      required: true,
    },
    isDisabled: Boolean,
  },
  methods: {
    ...mapActions([
      "updateEquipmentMlStatus",
      "displayRealAlertPopin",
      "updateSelectedEquipmentMlStatus",
    ]),
  },
  data() {
    return {
      feedbacks,
    };
  },
})
export default class AlertFeedbackSelector extends Vue {
  equipmentMlStatus!: EquipmentMlStatus;
  updateEquipmentMlStatus!: ({
    equipmentId,
    equipmentMlStatusId,
    unread,
    feedback,
    startDate,
    endDate,
    realAlertValues,
  }: any) => Promise<boolean>;
  isDisabled!: boolean;
  displayRealAlertPopin!: (displayRealAlertPopin: boolean) => void;
  updateSelectedEquipmentMlStatus!: (
    equipmentMlStatus: EquipmentMlStatus
  ) => void;

  set selectedFeedback(feedback: FeedbackEnum | undefined) {
    if (feedback === FeedbackEnum.REAL) {
      this.updateSelectedEquipmentMlStatus(this.equipmentMlStatus);
      this.$emit("onRealAlert");
    }
    if (feedback === FeedbackEnum.NORMAL_BEHAVIOR) {
      this.updateSelectedEquipmentMlStatus(this.equipmentMlStatus);
      this.$emit("onNormalBehavior");
    } else {
      this.updateEquipmentMlStatus({
        equipmentId: this.equipmentMlStatus.equipment.id,
        equipmentMlStatusId: this.equipmentMlStatus.id,
        feedback: feedback,
        startDate: null,
        endDate: null,
        unread: false,
      });
    }
  }

  get selectedFeedback(): FeedbackEnum | undefined {
    return this.equipmentMlStatus.feedback;
  }
}
