
import { Options, Vue } from "vue-class-component";
import ReportingFilters from "../components/equipment-reporting/ReportingFilters.vue";
import ReportingCard from "../components/equipment-reporting/ReportingCard.vue";
import ReportExport from "../components/equipment-reporting/ReportExport.vue";
import { ReportingViewType } from "../types/reporting-view";
import { mapActions, mapGetters } from "vuex";
import PageTitle, { PageEnum } from "../components/common/PageTitle.vue";

@Options({
  components: {
    ReportingCard,
    ReportingFilters,
    ReportExport,
    PageTitle,
  },
  computed: {
    ...mapGetters(["getReportingView"]),
  },
  methods: {
    ...mapActions([
      "updateSelectedSiteAndUserRole",
      "updateSelectedCountryBloc",
    ]),
  },
  name: "EquipmentReporting",
})
export default class EquipmentReporting extends Vue {
  ReportingViewType = ReportingViewType;
  getReportingView!: ReportingViewType;
  pageEnum = PageEnum;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;

  async created(): Promise<void> {
    this.updateSelectedCountryBloc(this.$route.params.countryBloc.toString());
    this.updateSelectedSiteAndUserRole(this.$route.params.site.toString());
  }
}
