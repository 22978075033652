import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createBlock(_component_q_icon, {
    class: _normalizeClass(_ctx.getClass()),
    name: _ctx.icon,
    style: {"font-size":"14px"}
  }, null, 8 /* PROPS */, ["class", "name"]))
}