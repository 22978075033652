import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "alerting-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_AlertsCard = _resolveComponent("AlertsCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PageTitle, {
      "data-test": "alerting-title",
      title: "Alert",
      page: _ctx.pageEnum.Alert
    }, null, 8 /* PROPS */, ["page"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AlertsCard)
      ])
    ])
  ]))
}