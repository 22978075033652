
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import Datepicker from "../equipment-status/Datepicker.vue";
import { Equipment } from "../../types/equipment";
import * as _ from "lodash";
import {
  EquipmentStatus,
  EquipmentStatuses,
} from "../../types/equipment-status";
import moment from "moment";
import {
  equipmentStatuses,
  convertEnumStatus,
  formatDate,
  allEvents,
  getLastSerialNumber,
} from "../../helpers/equipment-status-helpers";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { StatusEventEnum } from "../../types/status-events";
import EquipmentHierarchy from "./EquipmentHierarchy.vue";
import date from 'quasar/src/utils/date.js';;
import {
  EquipmentComment,
  InformationType,
} from "../../types/equipment-comment";
import { EquipmentCommentFile } from "../../types/equipment-comment-file";
import { uploadFile } from "../../helpers/file-upload-helper";
import TradeHierarchy from "./TradeHierarchy.vue";
import {
  EquipmentDetails,
  EquipmentParamDetails,
  Trade,
} from "@/types/equipment-tree";
import { isNumber } from "@/helpers/equipment-comment-helper";
import {
  getTrade,
  getEquipmentStatus,
  displayAdditionalInformation,
  getEventDisplayEquipementList,
  getDisplayShortfall,
} from "@/helpers/equipment-condition-helper";

@Options({
  name: "HistoricalStatusDialog",
  props: {
    status: {
      type: Object,
      required: false,
    },
  },
  components: {
    Datepicker,
    ConfirmDialog,
    EquipmentHierarchy,
    TradeHierarchy,
  },
  methods: {
    ...mapActions([
      "displayHistoricalStatusDialog",
      "addEquipmentStatus",
      "updateSelectedStatusEvent",
      "updateSelectedDate",
      "modifyEquipmentStatus",
      "addEquipmentComment",
      "addEquipmentCommentFile",
      "claimSasToken",
      "updateSelectedTrade",
      "updateSelectedEquipment",
      "updateEquipmentComment",
    ]),
  },
  computed: {
    ...mapGetters([
      "getDisplayHistoricalStatusDialog",
      "getEquipmentDetails",
      "getDateToUpdate",
      "getSelectedStatusEvent",
      "getAuthInfoAPI",
      "getIsEditModeHistoricalStatusDialog",
      "getSasToken",
      "getEquipmentParam",
      "getSelectedCountryBloc",
      "getSelectedTrade",
      "getSelectedEquipment",
      "getFlaringUnit",
      "getProductionShortfallUnit",
      "getWaterInjectShortfallUnit",
    ]),
  },
  data() {
    return {
      equipmentStatuses,
      _selectedStatus: undefined,
      author: "",
      woNumber: "",
      comment: "",
      addToWeeklyReport: false,
      isAlert: false,
      productionShortfall: "",
      waterInjectShortfall: "",
      flaring: "",
      equipmentMajorFailure: false,
      gasOrOilLeak: false,
      fireConfirmed: false,
      showSerialNumber: false,
      serialNumber: null,
      selectedEquipmentName: undefined,
    };
  },
})
export default class HistoricalStatusDialog extends Vue {
  status!: EquipmentStatus;
  author!: string;
  woNumber!: string;
  comment!: string;
  addToWeeklyReport = false;
  isAlert = false;
  files: File[] | null = null;
  _selectedStatus!: EquipmentStatuses | undefined;
  equipmentStatusesEnum = EquipmentStatuses;
  productionShortfall!: number | undefined;
  waterInjectShortfall!: number | undefined;
  flaring!: number | undefined;
  equipmentMajorFailure = false;
  gasOrOilLeak = false;
  fireConfirmed = false;
  getEquipmentParam!: EquipmentParamDetails;
  getAuthInfoAPI!: any;
  claimSasToken!: () => Promise<void>;
  getSasToken!: string;
  getSelectedCountryBloc!: any;
  displayConfirmDialog = false;
  getDisplayHistoricalStatusDialog!: boolean;
  getEquipmentDetails!: Equipment;
  getDateToUpdate!: string;
  getSelectedStatusEvent!: StatusEventEnum;
  getIsEditModeHistoricalStatusDialog!: boolean;
  getSelectedTrade!: Trade;
  getSelectedEquipment!: number | undefined;
  showSerialNumber!: boolean;
  paths: EquipmentDetails[] = [];
  selectedEquipmentName: string | undefined = undefined;
  serialNumber!: string | null | undefined;
  serialNumberOld!: string | null | undefined;

  displayHistoricalStatusDialog!: ({
    displayHistoricalStatusDialog,
    isEditMode,
  }: {
    displayHistoricalStatusDialog: boolean;
    isEditMode: boolean;
  }) => void;
  updateSelectedStatusEvent!: (
    statusEvent: StatusEventEnum | undefined
  ) => void;
  updateSelectedTrade!: (trade: Trade | undefined) => void;
  updateSelectedEquipment!: (equipment: number | undefined) => void;
  updateSelectedDate!: (date: string | undefined) => void;
  updateEquipmentComment!: ({
    equipmentId,
    equipmentCommentId,
    equipmentComment,
  }: {
    equipmentId: number;
    equipmentCommentId: number | undefined;
    equipmentComment: EquipmentComment | undefined;
  }) => Promise<EquipmentComment>;
  addEquipmentStatus!: ({
    status,
    equipmentId,
    statusDate,
    event,
    commentId,
    isLastStatus,
    equipmentMajorFailure,
    gasOrOilLeak,
    fireConfirmed,
    equipmentDetailsId,
    tradeTypeId,
    productionShortfall,
    waterInjectShortfall,
    flaring,
    equipmentComment,
    serialNumber,
  }: {
    status: EquipmentStatuses | undefined;
    equipmentId: number;
    statusDate: string;
    event: StatusEventEnum | undefined;
    commentId: number | undefined;
    isLastStatus: boolean;
    equipmentMajorFailure: boolean | undefined;
    gasOrOilLeak: boolean | undefined;
    fireConfirmed: boolean | undefined;
    equipmentDetailsId: number | undefined;
    tradeTypeId: number | undefined;
    productionShortfall: number | undefined;
    waterInjectShortfall: number | undefined;
    flaring: number | undefined;
    equipmentComment: EquipmentComment | null;
    serialNumber: string | null | undefined;
  }) => Promise<boolean>;
  modifyEquipmentStatus!: ({
    statusId,
    status,
    statusDate,
    event,
    commentId,
    equipmentMajorFailure,
    gasOrOilLeak,
    fireConfirmed,
    equipmentDetailsId,
    tradeTypeId,
    productionShortfall,
    waterInjectShortfall,
    flaring,
    equipmentComment,
    serialNumber,
    automaticStatus,
  }: {
    statusId: number;
    status: EquipmentStatuses | undefined;
    statusDate: string;
    event: StatusEventEnum | undefined;
    commentId: number | undefined;
    equipmentMajorFailure: boolean | undefined;
    gasOrOilLeak: boolean | undefined;
    fireConfirmed: boolean | undefined;
    equipmentDetailsId: number | undefined;
    tradeTypeId: number | undefined;
    productionShortfall: number | undefined;
    waterInjectShortfall: number | undefined;
    flaring: number | undefined;
    equipmentComment: EquipmentComment | null;
    serialNumber: string | null | undefined;
    automaticStatus: boolean | undefined;
  }) => Promise<boolean>;
  addEquipmentComment!: ({
    equipmentComment,
    equipmentId,
  }: {
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentComment>;
  addEquipmentCommentFile!: ({
    equipmentCommentFile,
    equipmentComment,
    equipmentId,
  }: {
    equipmentCommentFile: EquipmentCommentFile;
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<EquipmentCommentFile>;

  isNumberCheck(evt: KeyboardEvent): void {
    isNumber(evt);
  }

  beforeShow(): void {
    this.author = this.getAuthInfoAPI?.account?.name;
    this.serialNumberOld = this.getLastSerialNumberFn.serialNumber;
    if (this.getIsEditModeHistoricalStatusDialog) {
      this.comment = this.status.comment?.comment;
      this.woNumber = this.status.comment?.woNumber;
      this.files = null;
      this.addToWeeklyReport = this.status.comment
        ? this.status.comment.addToWeeklyReport
        : false;
      this.isAlert = this.status.comment ? this.status.comment.isAlert : false;
      this.selectedStatus = this.status.status;
      this.updateSelectedStatusEvent(this.status.event);
      this.updateSelectedEquipment(this.status.equipmentDetailsId);
      this.updateSelectedTrade(this.tradeValue(this.status.tradeTypeId));
      this.productionShortfall = this.status.productionShortfall
        ? this.status.productionShortfall
        : undefined;
      this.waterInjectShortfall = this.status.waterInjectShortfall
        ? this.status.waterInjectShortfall
        : undefined;
      this.flaring = this.status.flaring ? this.status.flaring : undefined;
      this.equipmentMajorFailure = this.status.equipmentMajorFailure
        ? this.status.equipmentMajorFailure
        : false;
      this.fireConfirmed = this.status.fireConfirmed
        ? this.status.fireConfirmed
        : false;
      this.gasOrOilLeak = this.status.gasOrOilLeak
        ? this.status.gasOrOilLeak
        : false;
      this.updateSelectedDate(
        date.formatDate(this.status.date, "YYYY-MM-DD HH:mm")
      );
    }
  }

  tradeValue(id: number | undefined): Trade | undefined {
    return getTrade(id, this.getEquipmentParam);
  }

  get equipmentStatus(): any[] {
    return getEquipmentStatus(this.getEquipmentParam);
  }

  displaySpecificFailure(): boolean {
    return displayAdditionalInformation(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  eventDisplayEquipementList(): boolean {
    return getEventDisplayEquipementList(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  showSerialNumberSectionFn(): boolean {
    if (
      this.getEquipmentDetails.driver === "Turbine" &&
      (this.getEquipmentDetails.technology === "Aeroderivative" ||
        this.getEquipmentDetails.technology === "Industrial")
    ) {
      if (
        this.selectedEquipmentName === "Turbine" ||
        (this.paths.length > 0 && this.paths[0].equipmentItem === "Turbine")
      ) {
        if (
          this.selectedStatus === EquipmentStatuses.SCH &&
          this.getSelectedStatusEvent === StatusEventEnum.SCH_MAJOR_PM
        ) {
          return true;
        } else if (
          this.selectedStatus === EquipmentStatuses.UNSCH &&
          this.getSelectedStatusEvent === StatusEventEnum.UNSCH_FAILURE &&
          this.equipmentMajorFailure === true
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getEquipmentPath(path: EquipmentDetails[]): void {
    this.paths = path;
  }

  getSelectedEquipmentName(eqName: string): void {
    this.selectedEquipmentName = eqName;
  }

  displayShortfall(): boolean {
    return getDisplayShortfall(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  onPreUpdate(): void {
    if (this.validateDateTime()) {
      this.displayConfirmDialog = true;
      this.displayHistoricalStatusDialog({
        displayHistoricalStatusDialog: false,
        isEditMode: this.getIsEditModeHistoricalStatusDialog,
      });
    }
  }

  async onConfirm(): Promise<void> {
    const tz = moment.tz.guess();
    const formattedDate = new Date(
      moment.tz(this.getDateToUpdate, tz).toString()
    ).toString();
    const comment = {
      date: formattedDate,
      comment: this.comment,
      woNumber: parseInt(this.woNumber),
      equipmentCommentFiles: [],
      author: this.author,
      informationType: InformationType.STATUS_CHANGE,
      addToWeeklyReport: this.addToWeeklyReport,
      isAlert: this.isAlert,
    } as unknown as EquipmentComment;
    if (this.comment.trim() !== "" && this.author.trim() !== "") {
      if (this.getIsEditModeHistoricalStatusDialog) {
        if (this.status.comment) {
          if (
            this.status.comment.comment !== this.comment ||
            (this.status.comment.equipmentCommentFiles !== this.files) === null
              ? []
              : this.files ||
                this.status.comment.woNumber !== this.woNumber ||
                this.status.comment.addToWeeklyReport !==
                  this.addToWeeklyReport ||
                this.status.comment.isAlert !== this.isAlert
          ) {
            const commentLoaded = {
              date: formattedDate,
              comment: this.comment,
              woStatus: null,
              woNumber: parseInt(this.woNumber),
              author: this.author,
              informationType: InformationType.STATUS_CHANGE,
              analysisType: null,
              qualityKpi: null,
              oilType: null,
              elecMoistureWater: null,
              elecDissolvedGasHydrogen: null,
              elecDissolvedGasMethane: null,
              elecDissolvedGasAcethylene: null,
              elecDissolvedGasEthylene: null,
              elecDissolvedGasEthane: null,
              elecDissolvedGasMonoxide: null,
              elecDissolvedGasDioxide: null,
              elecDissolvedGasTdcg: null,
              mean: null,
              standardDeviation: null,
              ratioMeanStandardDeviation: null,
              addToWeeklyReport: this.addToWeeklyReport,
              isAlert: this.isAlert,
            } as unknown as EquipmentComment;
            const equipmentComment = await this.updateEquipmentComment({
              equipmentId: this.getEquipmentDetails.id,
              equipmentCommentId: this.status.comment.id,
              equipmentComment: commentLoaded,
            });
            if (equipmentComment) {
              await this.addHistoricalStatus(
                equipmentComment,
                equipmentComment.id
              );
              if (this.files) {
                await this.addAndUploadFiles(this.files, equipmentComment);
              }
              await this.showNotifComment();
            }
          } else {
            const commentInEditMode = {
              date: this.status.comment.date,
              comment: this.comment,
              woNumber: parseInt(this.woNumber),
              equipmentCommentFiles:
                (this.status.comment.equipmentCommentFiles !== this.files) ===
                null
                  ? []
                  : this.files,
              author: this.author,
              informationType: InformationType.STATUS_CHANGE,
              addToWeeklyReport: this.addToWeeklyReport,
              isAlert: this.isAlert,
            } as unknown as EquipmentComment;
            await this.addHistoricalStatus(
              commentInEditMode,
              this.status.comment.id
            );
          }
        } else {
          const equipmentComment = await this.addEquipmentComment({
            equipmentComment: comment,
            equipmentId: this.getEquipmentDetails.id,
          });
          if (equipmentComment) {
            await this.addHistoricalStatus(
              equipmentComment,
              equipmentComment.id
            );
            if (this.files) {
              await this.addAndUploadFiles(this.files, equipmentComment);
            }
            await this.showNotifComment();
          }
        }
      } else {
        const equipmentComment = await this.addEquipmentComment({
          equipmentComment: comment,
          equipmentId: this.getEquipmentDetails.id,
        });
        if (equipmentComment) {
          await this.addHistoricalStatus(equipmentComment, equipmentComment.id);
          if (this.files) {
            await this.addAndUploadFiles(this.files, equipmentComment);
          }
          await this.showNotifComment();
        }
      }
    } else {
      await this.addHistoricalStatus(null, undefined);
    }
    this.onClose();
  }

  async addHistoricalStatus(
    equipmentComment: EquipmentComment | null,
    commentId: number | undefined
  ): Promise<void> {
    if (this.getIsEditModeHistoricalStatusDialog) {
      const successUpdateEquipmentStatus = await this.modifyEquipmentStatus({
        statusId: this.status.id,
        status: this.selectedStatus,
        statusDate: this.getDateToUpdate,
        event: this.getSelectedStatusEvent,
        commentId: commentId,
        equipmentMajorFailure: this.equipmentMajorFailure,
        gasOrOilLeak: this.gasOrOilLeak,
        fireConfirmed: this.fireConfirmed,
        equipmentDetailsId: this.getSelectedEquipment,
        tradeTypeId: this.getSelectedTrade?.id,
        productionShortfall: this.productionShortfall
          ? this.productionShortfall
          : undefined,
        waterInjectShortfall: this.waterInjectShortfall
          ? this.waterInjectShortfall
          : undefined,
        flaring: this.flaring ? this.flaring : undefined,
        equipmentComment: equipmentComment,
        serialNumber: this.showSerialNumber
          ? this.serialNumber
          : this.serialNumberOld,
        automaticStatus: true,
      });
      if (successUpdateEquipmentStatus) {
        this.showNotif();
        this.$emit("update_success_emmit");
      }
    } else {
      const successAddEquipmentStatus = await this.addEquipmentStatus({
        status: this.selectedStatus,
        equipmentId: this.getEquipmentDetails.id,
        statusDate: this.getDateToUpdate,
        event: this.getSelectedStatusEvent,
        commentId: commentId,
        isLastStatus: false,
        equipmentMajorFailure: this.equipmentMajorFailure,
        gasOrOilLeak: this.gasOrOilLeak,
        fireConfirmed: this.fireConfirmed,
        equipmentDetailsId: this.getSelectedEquipment,
        tradeTypeId: this.getSelectedTrade?.id,
        productionShortfall: this.productionShortfall
          ? this.productionShortfall
          : undefined,
        waterInjectShortfall: this.waterInjectShortfall
          ? this.waterInjectShortfall
          : undefined,
        flaring: this.flaring ? this.flaring : undefined,
        equipmentComment: equipmentComment,
        serialNumber: this.showSerialNumber
          ? this.serialNumber
          : this.serialNumberOld,
      });
      if (successAddEquipmentStatus) {
        this.showNotif();
      }
    }
  }

  async addAndUploadFiles(
    file: File[] | null,
    equipmentComment: EquipmentComment
  ): Promise<void> {
    file?.forEach(async (file: File) => {
      await this.claimSasToken();
      const token = this.getSasToken;
      if (token) {
        const blobName = await uploadFile(
          token,
          file,
          equipmentComment.id,
          moment.tz.guess()
        );
        const equipmentCommentFile: EquipmentCommentFile = {
          name: blobName,
        };
        await this.addEquipmentCommentFile({
          equipmentCommentFile,
          equipmentComment,
          equipmentId: this.getEquipmentDetails.id,
        });
      }
    });
  }

  async showNotifComment(): Promise<void> {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `A new comment for ${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } has been added : "${this.comment}"`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  validateDateTime(): boolean {
    if (!this.selectedStatus) {
      this.showNotifValidation("Please select a status");
      return false;
    }
    const lastStatus = this.getEquipmentDetails?.lastEquipmentStatus?.date;
    if (lastStatus) {
      const momentLastStatusDate = moment(lastStatus);
      if (
        !this.getIsEditModeHistoricalStatusDialog &&
        moment(this.getDateToUpdate).isSameOrAfter(momentLastStatusDate)
      ) {
        this.showNotifValidation(
          "Please select a date and time before the last status date"
        );
        return false;
      }
      if (
        this.getIsEditModeHistoricalStatusDialog &&
        moment(this.getDateToUpdate).isAfter(moment())
      ) {
        this.showNotifValidation(
          "Please select a date and time before today (date and time)"
        );
        return false;
      }
    }
    return true;
  }

  showNotifValidation(message: string): void {
    this.$q.notify({
      message,
      type: "negative",
      actions: [{ icon: "close", color: "white" }],
    });
  }

  onClose(): void {
    this.updateSelectedDate(undefined);
    this.updateSelectedStatusEvent(undefined);
    this.updateSelectedTrade(undefined);
    this.updateSelectedEquipment(undefined);
    this.selectedStatus = undefined;
    this.comment = "";
    this.author = "";
    this.woNumber = "";
    this.files = null;
    this.addToWeeklyReport = false;
    this.isAlert = false;
    this.displayConfirmDialog = false;
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
    this.paths = [];
    this.selectedEquipmentName = "";
  }

  showNotif(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } Status has been correctly updated to "${convertEnumStatus(
        this.selectedStatus ?? ""
      )}" with starting date "${this.getDateToUpdate}"`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  get statusEndDateTime(): string | undefined {
    const sortedStatuses = _.sortBy(
      this.getEquipmentDetails?.equipmentStatuses,
      "date"
    );
    const nextStatus = _.find(sortedStatuses, (status: EquipmentStatus) => {
      return moment(status.date).isAfter(moment(this.getDateToUpdate));
    });
    if (nextStatus) {
      return formatDate(nextStatus.date);
    }
    return undefined;
  }

  get confirmMessage(): string {
    if (this.getIsEditModeHistoricalStatusDialog) {
      return (
        "Do you confirm the status modification from " +
        convertEnumStatus(this.status?.status) +
        " to " +
        convertEnumStatus(this.selectedStatus ?? "") +
        " from " +
        formatDate(this.getDateToUpdate) +
        " to " +
        formatDate(this.statusEndDateTime) +
        "?"
      );
    } else {
      return (
        "Do you confirm the status addition " +
        convertEnumStatus(this.selectedStatus ?? "") +
        " from " +
        formatDate(this.getDateToUpdate) +
        " to " +
        formatDate(this.statusEndDateTime) +
        "?"
      );
    }
  }

  set selectedStatus(status: EquipmentStatuses | undefined) {
    this._selectedStatus = status;
    this.updateSelectedStatusEvent(undefined);
    this.updateSelectedTrade(undefined);
    this.updateSelectedEquipment(undefined);
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
  }

  get selectedStatus(): EquipmentStatuses | undefined {
    return this._selectedStatus;
  }

  set selectedEvent(event: StatusEventEnum | undefined) {
    this.updateSelectedStatusEvent(event);
    this.updateSelectedTrade(undefined);
    if (this.selectedStatus?.includes("unscheduled")) {
      if (
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_ENV) ||
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_HUMAN)
      ) {
        this.updateSelectedEquipment(undefined);
      }
    }
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.paths = [];
    this.selectedEquipmentName = "";
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
  }

  get selectedEvent(): StatusEventEnum | undefined {
    return this.getSelectedStatusEvent;
  }

  get availableEvents(): any[] {
    if (this.selectedStatus) {
      const events = _.filter(allEvents, (displayedEvent: any) => {
        return displayedEvent.associatedStatus === this.selectedStatus;
      });
      if (this.selectedStatus === EquipmentStatuses.RUN) {
        this.selectedEvent = StatusEventEnum.RUN_RESTARTED;
      } else if (this.selectedStatus === EquipmentStatuses.ABNR) {
        this.selectedEvent = StatusEventEnum.ABNR_STAND_BY;
      } else if (this.selectedStatus === EquipmentStatuses.RUN_LIMITED) {
        this.selectedEvent = StatusEventEnum.RUN_LIMITED;
      }
      return events;
    }
    return [];
  }

  get defaultYearMonth(): string | undefined {
    const lastDate = this.getEquipmentDetails?.lastEquipmentStatus?.date;
    if (lastDate) {
      const currentTimeZone = moment.tz.guess();
      return moment(lastDate).tz(currentTimeZone).format("YYYY/MM");
    }
    return undefined;
  }

  get getLastSerialNumberFn(): {
    serialNumber: string | undefined | null;
    installationDate: string | undefined | null;
  } {
    return getLastSerialNumber(this.getEquipmentDetails);
  }
}
