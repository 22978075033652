import { Equipment } from "./equipment";
import { EquipmentLink } from "./equipment-link";
import { SensorStatus } from "./sensor-status";

export enum EquipmentMLStatusTypeEnum {
  NOT_RUNNING = "not_running",
  NORMAL = "normal",
  ALERT = "alert",
}

export enum FeedbackEnum {
  NORMAL_BEHAVIOR = "normal_behavior",
  SPURIOUS = "spurious",
  REAL = "real",
  Empty_Unknown = "Empty/Unkown",
}

export type EquipmentMlStatus = {
  id: number;
  type: EquipmentMLStatusTypeEnum;
  firstDriftDate: Date;
  predictionStartDate: Date;
  predictionEndDate: Date;
  unread: boolean;
  feedback: FeedbackEnum;
  equipment: Equipment;
  sensorStatuses: SensorStatus[];
  modelVersion: string;
  equipmentName: string;
  equipmentLinks: EquipmentLink[];
  plotPath: string;
  startDate?: string;
  endDate?: string;
};
