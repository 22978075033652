
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { EquipmentStatuses } from "../../types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { Equipment } from "../../types/equipment";
import _ from "lodash";

@Options({
  methods: {
    ...mapActions(["updateFilters"]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentList",
      "getSelectedEquipmentFilter",
      "getCriticalCheckboxFilter",
      "getSelectedStatusFilter",
      "getSelectedDateFilter",
      "getSelectedEquipmentAlertsFilter",
      "getSelectedSectorFilter",
      "getStatusCheckboxFilter",
    ]),
  },
  data() {
    return {
      equipmentSearch: [],
    };
  },
  components: { EquipmentStatusIcon },
  name: "EquipmentSelector",
})
export default class EquipmentSelector extends Vue {
  status!: EquipmentStatuses;
  getEquipmentList!: () => Equipment[];
  equipmentSearch!: string[];

  updateFilters!: ({
    selectedEquipmentFilter,
    criticalCheckboxFilter,
    selectedStatusFilter,
    selectedDateFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
    statusCheckboxFilter,
  }: {
    selectedEquipmentFilter: string[] | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;
  getSelectedEquipmentFilter!: string[];
  getCriticalCheckboxFilter!: boolean;
  getSelectedStatusFilter!: EquipmentStatuses[];
  getSelectedDateFilter!: string;
  getSelectedEquipmentAlertsFilter!: string[];
  getSelectedSectorFilter!: string[];
  getStatusCheckboxFilter!: boolean;

  get availableEquipments(): string[] {
    return _.map(this.getEquipmentList, (equipment: Equipment) => {
      return (
        equipment.equipmentModel.name +
        (equipment.tag ? "-" + equipment.tag : "")
      );
    });
  }

  set selectedEquipmentFilter(selectedEquipmentFilter: string[] | undefined) {
    this.updateFilters({
      selectedEquipmentFilter,
      criticalCheckboxFilter: this.getCriticalCheckboxFilter,
      selectedStatusFilter: this.getSelectedStatusFilter,
      selectedDateFilter: this.getSelectedDateFilter,
      selectedEquipmentAlertsFilter: this.getSelectedEquipmentAlertsFilter,
      selectedSectorFilter: this.getSelectedSectorFilter,
      statusCheckboxFilter: this.getStatusCheckboxFilter,
    });
  }

  get selectedEquipmentFilter(): string[] | undefined {
    return this.getSelectedEquipmentFilter;
  }

  filterFn(val: any, update: any, abort: any): any {
    update(() => {
      const needle = val.toLowerCase();
      this.equipmentSearch = this.availableEquipments.filter(
        (v) => v.toLowerCase().indexOf(needle) > -1
      );
    });
  }
}
