
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { Equipment } from "../../types/equipment";
import { EquipmentStatuses } from "../../types/equipment-status";
import AddLastStatusDialog from "../equipment-details/AddLastStatusDialog.vue";
import date from 'quasar/src/utils/date.js';;
import moment from "moment-timezone";
import {
  equipmentStatuses,
  getClassForStatusText,
  getLastSerialNumber,
  getLegend,
} from "../../helpers/equipment-status-helpers";

import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { UserRole } from "../../types/user-info";
import { isUserUnknown, isUserGuest } from "../../helpers/user-role-helper";
import { EquipmentParamDetails } from "@/types/equipment-tree";
import { getEquipmentStatus } from "@/helpers/equipment-condition-helper";
import _ from "lodash";

@Options({
  components: { AddLastStatusDialog, EquipmentStatusIcon },
  methods: {
    ...mapActions([
      "displayAddLastStatusDialog",
      "updateSelectedStatus",
      "updateSelectedDate",
    ]),
  },
  computed: {
    ...mapGetters(["getEquipmentDetails", "getUserRole", "getEquipmentParam"]),
  },
  data() {
    return {
      equipmentStatuses,
    };
  },
  name: "EquipmentStatusSelector",
})
export default class EquipmentStatusSelector extends Vue {
  status!: EquipmentStatuses;
  getEquipmentDetails!: Equipment;
  displayAddLastStatusDialog!: (displayAddLastStatusDialog: boolean) => void;
  updateSelectedStatus!: (status: EquipmentStatuses | undefined) => void;
  updateSelectedDate!: (date: string | undefined) => void;
  getUserRole!: UserRole;
  getEquipmentParam!: EquipmentParamDetails;

  get equipmentStatus(): any[] {
    return getEquipmentStatus(this.getEquipmentParam);
  }

  set selectedStatus(status: EquipmentStatuses | undefined) {
    this.updateSelectedStatus(status);
    const currentStatusDate = date.formatDate(new Date(), "YYYY-MM-DD HH:mm");
    this.updateSelectedDate(currentStatusDate);
    this.displayAddLastStatusDialog(true);
  }

  get selectedStatus(): EquipmentStatuses | undefined {
    return this.getEquipmentDetails.lastEquipmentStatus?.status;
  }

  getLegendforStatus(): string {
    return getLegend(this.selectedStatus);
  }

  getClassForLegend(): any {
    return {
      ...getClassForStatusText(
        this.getEquipmentDetails?.lastEquipmentStatus?.status
      ),
      "q-mt-lg": true,
      "q-ml-lg": true,
    };
  }

  getClass(): any {
    return {
      ...getClassForStatusText(
        this.getEquipmentDetails?.lastEquipmentStatus?.status
      ),
      "main-dropdown": true,
    };
  }

  get startDate(): string | undefined {
    if (this.getEquipmentDetails.lastEquipmentStatus) {
      const currentTimeZone = moment.tz.guess();
      return moment(this.getEquipmentDetails.lastEquipmentStatus.date)
        .tz(currentTimeZone)
        .format("YYYY/MM/DD HH:mm");
    } else return undefined;
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  showSerialNumber(): boolean {
    return (
      this.getEquipmentDetails.driver === "Turbine" &&
      (this.getEquipmentDetails.technology === "Aeroderivative" ||
        this.getEquipmentDetails.technology === "Industrial")
    );
  }

  get getLastSerialNumberFn(): {
    serialNumber: string | undefined | null;
    installationDate: string | undefined | null;
  } {
    return getLastSerialNumber(this.getEquipmentDetails);
  }
}
