
import { Options, Vue } from "vue-class-component";
import EquipmentTitle from "../equipment-details/Title.vue";

export enum PageEnum {
  EquipmentList,
  EquipmentDetail,
  Reporting,
  Alert,
  AutomaticStatus,
}

@Options({
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    EquipmentTitle,
  },
})
export default class PageTitle extends Vue {
  pageEnum = PageEnum;
}
