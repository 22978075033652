
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { EquipmentLink } from "../../types/equipment-link";
import { Equipment } from "../../types/equipment";

@Options({
  name: "AddEquipmentLink",
  props: {
    isEditMode: {
      type: Boolean,
      required: false,
    },
    currentLink: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions([
      "addEquipmentLink",
      "displayAddLinkPopin",
      "updateEquipmentLink",
    ]),
  },
  computed: {
    ...mapGetters(["getEquipmentDetails", "getDisplayAddLinkPopin"]),
  },
})
export default class AddEquipmentLinkPopin extends Vue {
  isEditMode!: boolean;
  currentLink!: EquipmentLink;
  url!: string;
  title!: string;
  addLinkDisplay = false;
  getEquipmentDetails!: Equipment;
  displayAddLinkPopin!: (displayAddLinkPopin: boolean) => void;
  $q: any;
  addEquipmentLink!: ({
    equipmentLink,
    equipmentId,
  }: {
    equipmentLink: EquipmentLink | undefined;
    equipmentId: number;
  }) => Promise<EquipmentLink>;
  updateEquipmentLink!: (
    equipmentLink: EquipmentLink
  ) => Promise<EquipmentLink>;

  setup(): void {
    this.$q = useQuasar();
  }

  beforeShow(): void {
    this.url = this.isEditMode ? this.currentLink.link : "";
    this.title = this.isEditMode ? this.currentLink.title : "";
  }

  async onSubmit(): Promise<void> {
    if (this.title?.trim() !== "" && this.url?.trim() !== "") {
      let link = {
        title: this.title,
        link: this.url,
      } as EquipmentLink;
      let equipmentLink = undefined;
      if (this.isEditMode) {
        link.id = this.currentLink.id;
        equipmentLink = await this.updateEquipmentLink(link);
      } else {
        equipmentLink = await this.addEquipmentLink({
          equipmentLink: link,
          equipmentId: this.getEquipmentDetails.id,
        });
      }
      this.onClose();
      if (equipmentLink) {
        this.showNotif();
      }
    }
  }

  onClose(): void {
    this.title = "";
    this.url = "";
    this.displayAddLinkPopin(false);
    this.$emit("onClose");
  }

  showNotif(): void {
    if (this.isEditMode) {
      this.$q.notify({
        message: "The link has been updated",
        color: "positive",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
    } else {
      const equipment = this.getEquipmentDetails;
      this.$q.notify({
        message: `A new link for ${equipment.equipmentModel.name} ${equipment.tag} has been added.`,
        color: "positive",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
    }
  }
}
