import { EquipmentSystem } from "@/types/equipment-model";
import {
  AnalysisType,
  DryGasSealType,
  InformationType,
  OilType,
  QualityKPI,
} from "../types/equipment-comment";

export const informationTypes = [
  {
    label: "Other",
    value: InformationType.OTHER,
  },
  {
    label: "Oil analysis",
    value: InformationType.OIL,
  },
  {
    label: "Vibration analysis",
    value: InformationType.VIBRATION,
  },
  {
    label: "Performance",
    value: InformationType.PERFORMANCE,
  },
  {
    label: "Report",
    value: InformationType.REPORT,
  },
  {
    label: "Downgraded situation",
    value: InformationType.DOWNGRADED_SITUATION,
  },
  {
    label: "RAID",
    value: InformationType.RAID,
  },
  {
    label: "Service Bulletins",
    value: InformationType.SERVICE_BULLETINS,
  },
];

export const informationTypesForDrivenCOMP = [
  ...informationTypes,
  {
    label: "Dry gas seal",
    value: InformationType.DRY_GAS_SEAL,
  },
];

export const informationTypesForDrivenPUMP = [
  ...informationTypes,
  {
    label: "Mechanical seal",
    value: InformationType.DRY_GAS_SEAL,
  },
];

export const informationTypesTransformers = [
  ...informationTypes,
  {
    label: "Breakdown voltage analysis",
    value: InformationType.INSULATION,
  },
];

export const analysisTypes = [
  {
    label: "Routine",
    value: AnalysisType.ROUTINE,
  },
  {
    label: "Issue",
    value: AnalysisType.ISSUE,
  },
];

export const oilTypes = [
  {
    label: "Hydraulic Oil",
    value: OilType.HYD_OIL,
  },
  {
    label: "Lube Oil",
    value: OilType.LUBE_OIL,
  },
  {
    label: "Mineral Lube Oil",
    value: OilType.MLO,
  },
  {
    label: "Synthetic Lube Oil",
    value: OilType.SLO,
  },
  {
    label: "Grease",
    value: OilType.GREASE,
  },
  {
    label: "Seal Oil",
    value: OilType.SEAL_OIL,
  },
];

export const dryGasSealTypesForDrivenPUMP = [
  {
    label: "Stage 1 DE",
    value: DryGasSealType.S1DE,
  },
  {
    label: "Stage 1 NDE",
    value: DryGasSealType.S1NDE,
  },
];

export const dryGasSealTypes = [
  ...dryGasSealTypesForDrivenPUMP,
  {
    label: "Stage 2 DE",
    value: DryGasSealType.S2DE,
  },
  {
    label: "Stage 2 NDE",
    value: DryGasSealType.S2NDE,
  },
  {
    label: "Stage 3 DE",
    value: DryGasSealType.S3DE,
  },
  {
    label: "Stage 3 NDE",
    value: DryGasSealType.S3NDE,
  },
];

export const qualityKpis = [
  {
    label: "CRITICAL",
    value: QualityKPI.CRITICAL,
  },
  {
    label: "GOOD",
    value: QualityKPI.GOOD,
  },
  {
    label: "FAIR",
    value: QualityKPI.FAIR,
  },
];

enum QualityKpiIcon {
  critical = "clear",
  fair = "priority_high",
  good = "done",
  missing = "",
}

enum QualityKpiChipIcon {
  critical = "cancel",
  fair = "error",
  good = "check_circle",
  missing = "",
}

export function getInformationTypesForFilter(equipmentSystem: string): any[] {
  let informationTypeFilters = informationTypes;
  if (equipmentSystem === EquipmentSystem.TRANSFORMERS) {
    informationTypeFilters = informationTypesTransformers;
  }
  return [
    ...informationTypeFilters,
    {
      label: "Status change",
      value: InformationType.STATUS_CHANGE,
    },
    {
      label: "S/N",
      value: InformationType.SERIAL_NUMBER,
    },
    {
      label: "All",
      value: undefined,
    },
  ];
}

export function getClassForQualityKpiText(
  qualityKpi: QualityKPI | undefined
): any {
  if (qualityKpi) {
    return {
      red: qualityKpi === QualityKPI.CRITICAL,
      orange: qualityKpi === QualityKPI.FAIR,
      green: qualityKpi === QualityKPI.GOOD,
      "q-mt-md": true,
      "text-weight-bold": true,
      "main-dropdown-100": true,
    };
  } else {
    return {
      "q-mt-md": true,
      "text-weight-bold": true,
      "main-dropdown-100": true,
    };
  }
}

export function getClassForQualityKpiIcon(
  qualityKpi: QualityKPI | undefined
): any {
  if (qualityKpi) {
    return {
      "bg-red": qualityKpi === QualityKPI.CRITICAL,
      "bg-orange": qualityKpi === QualityKPI.FAIR,
      "bg-green": qualityKpi === QualityKPI.GOOD,
      "status-badge": true,
    };
  }
}

export function getClassForQualityKpiChip(
  qualityKpi: QualityKPI | undefined
): any {
  if (qualityKpi) {
    return {
      "bg-red": qualityKpi === QualityKPI.CRITICAL,
      "bg-orange": qualityKpi === QualityKPI.FAIR,
      "bg-green": qualityKpi === QualityKPI.GOOD,
    };
  }
}

export function getQualityKpiIcon(
  qualityKpi: QualityKPI | undefined
): QualityKpiIcon {
  if (qualityKpi) {
    return QualityKpiIcon[qualityKpi];
  }
  return QualityKpiIcon.missing;
}

export function getQualityKpiChipIcon(
  qualityKpi: QualityKPI | undefined
): QualityKpiChipIcon {
  if (qualityKpi) {
    return QualityKpiChipIcon[qualityKpi];
  }
  return QualityKpiChipIcon.missing;
}

export function convertEnumInformationType(
  informationType: string | undefined
): string {
  switch (informationType) {
    case "oil":
      return "Oil analysis";
    case "vibration":
      return "Vibration analysis";
    case "performance":
      return "Performance";
    case "other":
      return "Other";
    case "status_change":
      return "Status change";
    case "offshore":
      return "Offshore";
    case "insulation":
      return "Breakdown voltage analysis";
    case "report":
      return "Report";
    case "downgraded_situation":
      return "Downgraded situation";
    case "raid":
      return "RAID";
    case "dry_gas_seal":
      return "Dry gas seal";
    case "stage_1_DE":
      return "DE";
    case "stage_1_NDE":
      return "NDE";
    case "stage_2_DE":
      return "DE";
    case "stage_2_NDE":
      return "NDE";
    case "stage_3_DE":
      return "DE";
    case "stage_3_NDE":
      return "NDE";
    case "service_bulletins":
      return "Service Bulletins";
    default:
      return "";
  }
}

export function convertEnumAnalysis(analysisType: string | undefined): string {
  switch (analysisType) {
    case "routine":
      return "Routine";
    case "issue":
      return "Issue";
    default:
      return "";
  }
}

export function convertEnumFailureType(
  failureType: string | undefined
): string {
  switch (failureType) {
    case "arcing":
      return "Arcing";
    case "corona":
      return "Corona";
    case "thermal_decomposition":
      return "Thermal Decomposition";
    case "low_temperature_overheating":
      return "Low temperature overheating";
    case "overheating_less_than_700":
      return "Overheating < 700°C";
    case "thermal_fault_over_than_700":
      return "Thermal fault > 700°C";
    default:
      return "";
  }
}

export function convertEnumOilType(oilType: string | undefined): string {
  switch (oilType) {
    case OilType.MLO:
      return "Mineral Lube Oil";
    case OilType.SLO:
      return "Synthetic Lube Oil";
    case OilType.HYD_OIL:
      return "Hydraulic Oil";
    case OilType.LUBE_OIL:
      return "Lube Oil";
    default:
      return "Oil analysis";
  }
}

export function convertEnumDryGasSealType(
  dryGasSealType: string | undefined
): string {
  switch (dryGasSealType) {
    case DryGasSealType.S1DE:
      return "Stage 1 DE";
    case DryGasSealType.S1NDE:
      return "Stage 1 NDE";
    case DryGasSealType.S2DE:
      return "Stage 2 DE";
    case DryGasSealType.S2NDE:
      return "Stage 2 NDE";
    case DryGasSealType.S3DE:
      return "Stage 3 DE";
    case DryGasSealType.S3NDE:
      return "Stage 3 NDE";
    default:
      return "";
  }
}

export function isNumber(evt: KeyboardEvent): void {
  const keysAllowed: string[] = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  const keyPressed: string = evt.key;
  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
}

export function getMonthNumber(month: string): string {
  if (month === "Jan") return "01";
  if (month === "Feb") return "02";
  if (month === "Mar") return "03";
  if (month === "Apr") return "04";
  if (month === "May") return "05";
  if (month === "Jun") return "06";
  if (month === "Jul") return "07";
  if (month === "Aug") return "08";
  if (month === "Sep") return "09";
  if (month === "Oct") return "10";
  if (month === "Nov") return "11";
  if (month === "Dec") return "12";
  return "-1";
}
