import { treatErrors } from "@/helpers/http-status-helper";
import { SparePart } from "@/types/spare-part";
import axios, { AxiosResponse } from "axios";

export default class SparePartsApi {
  static async getSparePartsForEquipment(
    equipmentId: number,
    countryBloc: string,
    site: string
  ): Promise<{ spareParts: SparePart[] | undefined; httpStatus: number }> {
    try {
      const response = await axios.get(
        `/api/v1/spare-part/${countryBloc}/${site}/equipment/${equipmentId}`
      );
      return { spareParts: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { spareParts: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async deleteSparePart(
    equipmentId: number,
    materialNumber: number,
    countryBloc: string,
    site: string
  ): Promise<number> {
    try {
      const response = await axios.delete(
        `/api/v1/spare-part/delete/${countryBloc}/${site}/equipment/${equipmentId}/material-number/${materialNumber}`
      );
      return response.status;
    } catch (err: any) {
      return treatErrors(err);
    }
  }

  static async addSparePart(
    equipmentId: number,
    materialNumber: number,
    countryBloc: string,
    site: string
  ): Promise<{ spareParts: SparePart[] | undefined; httpStatus: number }> {
    try {
      const response = await axios.post(
        `/api/v1/spare-part/create/${countryBloc}/${site}/equipment/${equipmentId}`,
        {
          materialNumber,
        }
      );
      return { spareParts: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { spareParts: undefined, httpStatus: treatErrors(err) };
    }
  }

  static async exportMissingSpareParts(
    countryBloc: string,
    site: string
  ): Promise<AxiosResponse<any>> {
    return axios.get(`/api/v1/spare-part/export/${countryBloc}/${site}`, {
      responseType: "blob",
    });
  }
}
