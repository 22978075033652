
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import {
  isUserUnknown,
  isUserGuest,
  isUserAdmin,
} from "../../helpers/user-role-helper";
import { UserRole } from "../../types/user-info";

@Options({
  name: "EditMenu",
  props: {
    author: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getAuthInfoAPI", "getUserRole"]),
  },
})
export default class EditMenu extends Vue {
  getUserRole!: UserRole;
  getAuthInfoAPI!: any;
  author!: string;

  onEdit(): void {
    this.$emit("onEdit");
  }
  onDelete(): void {
    this.$emit("onDelete");
  }
  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  isUserAdmin(): boolean {
    return isUserAdmin(this.getUserRole);
  }

  canDelete(): boolean {
    return (
      (this.isUserAdmin() ||
        this.getAuthInfoAPI?.account.name.trim().toLowerCase() ===
          this.author?.trim().toLowerCase() ||
        this.author?.trim().toLowerCase() === "onecbm") &&
      !this.isUserUnknownOrGuest()
    );
  }
}
