
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
import { EquipmentStatistics } from "../../types/equipment-statistics";
import * as _ from "lodash";
import fp from "lodash/fp";
import EquipmentCommentsForBarChart from "../equipment-reporting/EquipmentCommentsForBarChart.vue";
import { EquipmentComment } from "@/types/equipment-comment";

@Options({
  name: "EquipmentBarchart",
  props: {
    equipment: Object,
  },
  methods: {
    ...mapActions(["displayEquipmentCommentForBarChartPopin"]),
  },
  data() {
    return {
      openEquipmentCommentForBarChartPopin: false,
    };
  },
  components: {
    EquipmentCommentsForBarChart,
  },
})
export default class EquipmentBarchart extends Vue {
  equipment!: EquipmentStatistics;
  equipmentComment!: EquipmentComment[] | undefined;
  clickedAvlOrRel!: number;
  dataPointIndex!: number;
  openEquipmentCommentForBarChartPopin!: boolean;
  displayEquipmentCommentForBarChartPopin!: (
    displayEquipmentCommentForBarChartPopin: boolean
  ) => void;

  onBarGraphClick(event: any, chartContext: any, config: any): void {
    this.clickedAvlOrRel = config.seriesIndex;
    this.dataPointIndex = config.dataPointIndex;
    if (this.clickedAvlOrRel !== -1 && this.dataPointIndex !== -1) {
      this.openEquipmentCommentForBarChartPopin = true;
      this.displayEquipmentCommentForBarChartPopin(true);
    }
  }

  onClose(): void {
    this.openEquipmentCommentForBarChartPopin = false;
    this.displayEquipmentCommentForBarChartPopin(false);
  }

  get equipmentCommentsMonthWise(): EquipmentComment[] | undefined {
    return this.equipmentComment;
  }

  get currentStats(): any {
    return this.equipment;
  }

  get months(): string[] {
    return Object.keys(this.currentStats.statistics12Months);
  }

  get series(): any[] {
    return [
      {
        name: "Availability",
        data: _.map(
          this.currentStats.statistics12Months,
          fp.get("availability")
        ),
      },
      {
        name: "Reliability",
        data: _.map(
          this.currentStats.statistics12Months,
          fp.get("reliability")
        ),
      },
    ];
  }

  get chartOptions(): any {
    return {
      chart: {
        type: "bar",
        height: 300,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#2F7BB4", "#E00090"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val: any) {
          return val.toString().match(/^-?\d+(?:\.\d?)?/)[0];
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: -20,
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: this.months,
      },
      yaxis: {
        title: {
          text: "percent",
        },
        max: 110,
        decimalsInFloat: 0,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        x: {
          formatter: (value: any) => {
            return "Click on graph to get comments for " + value;
          },
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
    };
  }
}
