
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { EquipmentStatuses } from "../../types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";

@Options({
  components: { EquipmentStatusIcon },
  methods: {
    ...mapActions(["updateFilters"]),
  },
  computed: {
    ...mapGetters([
      "getCriticalCheckboxFilter",
      "getSelectedStatusFilter",
      "getSelectedDateFilter",
      "getSelectedEquipmentFilter",
      "getSelectedEquipmentAlertsFilter",
      "getSelectedSectorFilter",
      "getStatusCheckboxFilter",
    ]),
  },
  name: "StatusBoxFilter",
})
export default class StatusCheckbox extends Vue {
  updateFilters!: ({
    statusCheckboxFilter,
    criticalCheckboxFilter,
    selectedStatusFilter,
    selectedDateFilter,
    selectedEquipmentFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
  }: {
    statusCheckboxFilter: boolean | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentFilter: string[] | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
  }) => void;
  getCriticalCheckboxFilter!: boolean;
  getSelectedStatusFilter!: EquipmentStatuses[];
  getSelectedDateFilter!: string;
  getSelectedEquipmentFilter!: string[];
  getSelectedEquipmentAlertsFilter!: string[];
  getSelectedSectorFilter!: string[];
  getStatusCheckboxFilter!: boolean;

  set statusCheckboxFilter(statusCheckboxFilter: boolean | undefined) {
    this.updateFilters({
      statusCheckboxFilter,
      criticalCheckboxFilter: this.getCriticalCheckboxFilter,
      selectedStatusFilter: this.getSelectedStatusFilter,
      selectedDateFilter: this.getSelectedDateFilter,
      selectedEquipmentFilter: this.getSelectedEquipmentFilter,
      selectedEquipmentAlertsFilter: this.getSelectedEquipmentAlertsFilter,
      selectedSectorFilter: this.getSelectedSectorFilter,
    });
  }

  get statusCheckboxFilter(): boolean | undefined {
    return this.getStatusCheckboxFilter;
  }
}
