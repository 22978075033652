import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.open,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
      persistent: "",
      "transition-show": "scale",
      "transition-hide": "scale"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { class: "q-pa-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "row items-center" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */),
                _createVNode(_component_q_space)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_q_card_section, { class: "row justify-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.message), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_q_btn, {
                  outline: "",
                  rounded: "",
                  "no-caps": "",
                  type: "button",
                  label: "Cancel",
                  class: "main-button",
                  onClick: _ctx.emitClose
                }, null, 8 /* PROPS */, ["onClick"]), [
                  [_directive_close_popup]
                ]),
                _createVNode(_component_q_btn, {
                  outline: "",
                  rounded: "",
                  "no-caps": "",
                  label: "Confirm",
                  type: "button",
                  class: "action-button",
                  onClick: _ctx.emitConfirm
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}