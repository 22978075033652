
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import moment from "moment-timezone";
import date from 'quasar/src/utils/date.js';;

import {
  EquipmentMlStatus,
  FeedbackEnum,
} from "../../types/equipment-ml-status";

@Options({
  name: "NormalBehaviorPopin",
  methods: {
    ...mapActions(["displayNormalBehaviorPopin", "updateEquipmentMlStatus"]),
  },
  computed: {
    ...mapGetters([
      "getDisplayNormalBehaviorPopin",
      "getSelectedEquipmentMlStatus",
    ]),
  },
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
})
export default class NormalBehaviorPopin extends Vue {
  equipmentMlStatus!: EquipmentMlStatus;
  displayNormalBehaviorPopin!: (displayNormalBehaviorPopin: boolean) => void;
  updateEquipmentMlStatus!: ({
    equipmentId,
    equipmentMlStatusId,
    unread,
    feedback,
    startDate,
    endDate,
    realAlertValues,
  }: any) => Promise<boolean>;
  getSelectedEquipmentMlStatus!: EquipmentMlStatus;
  startDate!: string | null;
  endDate!: string | null;
  utcOffsetStartDate!: string;
  utcOffsetEndDate!: string;

  beforeShow(): void {
    this.utcOffsetStartDate = moment(
      this.getSelectedEquipmentMlStatus.predictionStartDate
    ).format("ZZ");
    this.utcOffsetEndDate = moment(
      this.getSelectedEquipmentMlStatus.predictionEndDate
    ).format("ZZ");
    this.startDate = date.formatDate(
      this.getSelectedEquipmentMlStatus.predictionStartDate,
      "YYYY-MM-DD HH:mm"
    );
    this.endDate = date.formatDate(
      this.getSelectedEquipmentMlStatus.predictionEndDate,
      "YYYY-MM-DD HH:mm"
    );
  }

  onClose(): void {
    this.startDate = null;
    this.endDate = null;
    this.displayNormalBehaviorPopin(false);
  }

  startDateOptions(date: Date): boolean {
    const momentDate = moment(date, "YYYY-MM-DD");
    return (
      momentDate.isSameOrAfter(
        moment(this.getSelectedEquipmentMlStatus.predictionStartDate).subtract(
          1,
          "days"
        )
      ) && momentDate.isSameOrBefore(moment(this.endDate))
    );
  }

  endDateOptions(date: Date): boolean {
    const momentDate = moment(date, "YYYY-MM-DD");
    return (
      momentDate.isSameOrBefore(
        moment(this.getSelectedEquipmentMlStatus.predictionEndDate)
      ) && momentDate.isSameOrAfter(moment(this.startDate).subtract(1, "days"))
    );
  }

  startTimeOptions(hr: number, mints: number | null): boolean {
    const formatedStartDate = moment(this.startDate).format("YYYY-MM-DD");
    if (mints !== null) {
      const momentTime = moment(
        formatedStartDate + " " + hr + ":" + mints,
        "YYYY-MM-DD HH:mm"
      );
      return (
        momentTime.isSameOrAfter(
          moment(this.getSelectedEquipmentMlStatus.predictionStartDate).format(
            "YYYY-MM-DD HH:mm"
          )
        ) &&
        momentTime.isSameOrBefore(
          moment(this.endDate).format("YYYY-MM-DD HH:mm")
        )
      );
    } else {
      const momentTime = moment(formatedStartDate + " " + hr, "YYYY-MM-DD HH");
      return (
        momentTime.isSameOrAfter(
          moment(this.getSelectedEquipmentMlStatus.predictionStartDate).format(
            "YYYY-MM-DD HH"
          )
        ) &&
        momentTime.isSameOrBefore(moment(this.endDate).format("YYYY-MM-DD HH"))
      );
    }
  }

  endTimeOptions(hr: number, mints: number | null): boolean {
    const formatedEndDate = moment(this.endDate).format("YYYY-MM-DD");
    if (mints !== null) {
      const momentTime = moment(
        formatedEndDate + " " + hr + ":" + mints,
        "YYYY-MM-DD HH:mm"
      );
      return (
        momentTime.isSameOrBefore(
          moment(this.getSelectedEquipmentMlStatus.predictionEndDate).format(
            "YYYY-MM-DD HH:mm"
          )
        ) &&
        momentTime.isSameOrAfter(
          moment(this.startDate).add(1, "minutes").format("YYYY-MM-DD HH:mm")
        )
      );
    } else {
      const momentTime = moment(formatedEndDate + " " + hr, "YYYY-MM-DD HH");
      return (
        momentTime.isSameOrBefore(
          moment(this.getSelectedEquipmentMlStatus.predictionEndDate).format(
            "YYYY-MM-DD HH"
          )
        ) &&
        momentTime.isSameOrAfter(moment(this.startDate).format("YYYY-MM-DD HH"))
      );
    }
  }
  async onSubmit(): Promise<void> {
    const tz = moment.tz.guess();
    const formattedStartDate = new Date(
      moment.tz(this.startDate, tz).toString()
    ).toString();
    const formattedEndDate = new Date(
      moment.tz(this.endDate, tz).toString()
    ).toString();
    const isUpdated = await this.updateEquipmentMlStatus({
      equipmentId: this.getSelectedEquipmentMlStatus.equipment.id,
      equipmentMlStatusId: this.getSelectedEquipmentMlStatus.id,
      unread: false,
      feedback: FeedbackEnum.NORMAL_BEHAVIOR,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    if (isUpdated) {
      this.$q.notify({
        message:
          "Start and end dates for normal behavior have been updated in OneCBM",
        type: "positive",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
    }
    this.$q.loading.hide();
    this.onClose();
  }
}
