import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-md status-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_select, {
      modelValue: _ctx.selectedEquipmentFilter,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEquipmentFilter) = $event)),
      outlined: "",
      rounded: "",
      "use-input": "",
      clearable: "",
      "input-debounce": "0",
      label: "Select equipment",
      multiple: "",
      "stack-label": "",
      options: _ctx.equipmentSearch,
      style: {"width":"200px"},
      behavior: "menu",
      "data-test": "equipment-selector",
      onFilter: _ctx.filterFn
    }, {
      option: _withCtx(({ itemProps, opt, selected, toggleOption }) => [
        _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(itemProps)), {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { innerHTML: opt }, null, 8 /* PROPS */, ["innerHTML"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */),
            _createVNode(_component_q_item_section, { side: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  "model-value": selected,
                  "onUpdate:modelValue": ($event: any) => (toggleOption(opt))
                }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options", "onFilter"])
  ]))
}