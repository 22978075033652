export type UserInfo = {
  name: string;
  assignedCountriesAndSites: AssignedCountryAndSite[];
};

export type AssignedCountryAndSite = {
  countryAndBloc: string;
  sitesAndRoles: SiteAndRole[];
};

export type SiteAndRole = {
  site: string;
  role: string;
};

export enum UserRole {
  ADMIN = "admin",
  ONSHORE = "onshore",
  OFFSHORE = "offshore",
  GUEST = "guest",
  UNKNOWN = "unknown",
}
