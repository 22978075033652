
import { Options, Vue } from "vue-class-component";

@Options({
  name: "QuickDashboardConditions",
  params: {
    params: {
      type: Array,
      required: true,
    },
  },
})
export default class QuickDashboardConditions extends Vue {
  params!: any;
  dangerRed = "#D10000";
  orange = "#EF6F00";
  greenSuccess = "#40A900";
  computeColorForCondition(condition: string): string | undefined {
    if (condition === "Available") {
      return this.greenSuccess;
    } else if (condition === "Downgraded Available") {
      return this.orange;
    } else if (condition === "Unavailable") {
      return this.dangerRed;
    }
  }
}
