
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

@Options({
  props: {
    numberOfUnreadAlerts: {
      type: Number,
      required: false,
    },
    displayTotal: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getUnreadAlertsTotal"]),
  },
  name: "AlertsIcon",
})
export default class AlertsIcon extends Vue {
  displayTotal!: boolean;
  getUnreadAlertsTotal!: number;
  numberOfUnreadAlerts!: number;

  get numberAlerts(): number {
    return this.displayTotal
      ? this.getUnreadAlertsTotal
      : this.numberOfUnreadAlerts;
  }

  get icon(): string {
    return this.numberAlerts > 0
      ? "o_notification_important"
      : "o_notifications";
  }
}
