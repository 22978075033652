
import { Equipment } from "@/types/equipment";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
import fp from "lodash/fp";
import {
  EquipmentComment,
  InformationType,
} from "../../types/equipment-comment";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
  },
  name: "BarchartKPIsCard",
})
export default class BarchartKPIsCard extends Vue {
  getEquipmentDetails!: Equipment;
  informationTypeEnum = InformationType;

  get oilComments(): EquipmentComment[] {
    return _.takeRight(
      _.sortBy(
        _.filter(
          this.getEquipmentDetails.equipmentComments,
          (comment: EquipmentComment) => {
            return comment.informationType == this.informationTypeEnum.OIL;
          }
        ),
        "date"
      ),
      5
    );
  }

  get series(): any[] {
    return [
      {
        name: "Moisture water",
        data: _.map(this.oilComments, fp.get("elecMoistureWater")),
        color: "#DF02A1",
      },
      {
        name: "Dissolved gas hydrogen",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasHydrogen")),
        color: "#4632FF",
      },
      {
        name: "Dissolved gas methane",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasMethane")),
        color: "#00884A",
      },
      {
        name: "Dissolved gas acethylene",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasAcethylene")),
        color: "#8722C6",
      },
      {
        name: "Dissolved gas ethylene",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasEthylene")),
        color: "#EF6F00",
      },
      {
        name: "Dissolved gas ethane",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasEthane")),
        color: "#084567",
      },
      {
        name: "Dissolved gas carbon monoxide",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasMonoxide")),
        color: "#009CEA",
      },
      {
        name: "Dissolved gas carbon dioxide",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasDioxide")),
        color: "#B19018",
      },
      {
        name: "Dissolved gas TDCG*",
        data: _.map(this.oilComments, fp.get("elecDissolvedGasTdcg")),
        color: "#EE0000",
      },
    ];
  }

  get chartOptions(): any {
    return {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: _.map(this.oilComments, (comment: any) => {
          const tz = moment.tz.guess();
          return moment.tz(comment.date, tz).format("YYYY/MM/DD");
        }),
      },
      yaxis: [
        {
          seriesName: "Moisture water",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#B7CBD3",
          },
          labels: {
            style: {
              colors: "#374649",
            },
          },
          title: {
            text: "TDCG * ppm",
            style: {
              color: "#374649",
            },
          },
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
        {
          seriesName: "Dissolved gas carbon dioxide",
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#EE0000",
          },
          labels: {
            style: {
              colors: "#EE0000",
            },
          },
          title: {
            text: "Carbon dioxide (ppm)",
            style: {
              color: "#EE0000",
            },
          },
        },
        {
          seriesName: "Moisture water",
          show: false,
        },
      ],
      fill: {
        opacity: 1,
      },
    };
  }
}
