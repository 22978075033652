
import { Equipment } from "@/types/equipment";
import { EquipmentStatus, EquipmentStatuses } from "@/types/equipment-status";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";

enum StatusColorHexa {
  unscheduled = "#D12210",
  scheduled = "#F39C12",
  run_limited = "#2ECC71",
  run = "#1E9D54",
  abnr = "#666666",
  missing = "",
}

@Options({
  name: "StatusTimelineChart",
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
  },
})
export default class StatusTimelineChart extends Vue {
  getEquipmentDetails!: Equipment;
  private fromDate = new Date();
  private toDate = new Date();

  mounted(): void {
    this.fromDate = new Date(this.getInitialDateRange().min);
    this.toDate = new Date(this.getInitialDateRange().max);
  }

  get dateRange(): string {
    return `Status history from ${this.formatDate(
      this.fromDate
    )} to ${this.formatDate(this.toDate)}.`;
  }

  get serie(): any[] {
    return this.getStatuses();
  }

  get chartOptions(): any {
    const colors = _.map(this.getStatuses(), (status: any) => {
      return StatusColorHexa[status.name as EquipmentStatuses];
    });
    return {
      chart: {
        id: "status-chart",
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          rangeBarGroupRows: true,
        },
      },
      fill: {
        type: "solid",
      },
      colors: colors,
      xaxis: {
        type: "datetime",
        min: this.getInitialDateRange().min,
        max: this.getInitialDateRange().max,
        labels: {
          datetimeUTC: false,
        },
      },
      legend: {
        position: "right",
      },
      noData: {
        text: "No statuses for this equipment.",
        align: "center",
        verticalAlign: "middle",
      },
      tooltip: {
        custom: ((opts: any): string => {
          const fromDate = new Date(opts.y1);
          const toDate = opts.y2
            ? new Date(opts.y2)
            : new Date(moment().subtract(1, "days").endOf("day").toString());
          return `From ${this.formatDate(fromDate)} to ${this.formatDate(
            toDate
          )}`;
        }).bind(this),
      },
    };
  }

  getStatuses(): any {
    const sortedStatuses: EquipmentStatus[] = _.sortBy(
      this.getEquipmentDetails?.equipmentStatuses,
      "date"
    );
    const distinctStatusByDate: EquipmentStatus[] = [];
    for (const stat of sortedStatuses) {
      if (
        !sortedStatuses.find((st) => st.id > stat.id && st.date === stat.date)
      ) {
        distinctStatusByDate.push(stat);
      }
    }
    const statusesWithEndDates: any[] =
      this.setStatusesEndDates(distinctStatusByDate);
    const statusesByName = _.groupBy(statusesWithEndDates, "status");
    return _.map(statusesByName, (elements: any, key: string) => {
      return {
        name: key,
        data: elements,
      };
    });
  }

  setStatusesEndDates(statuses: EquipmentStatus[]): any[] {
    return _.map(statuses, (status: EquipmentStatus, index: number) => {
      const fromDate = new Date(status.date).getTime();
      const toDate =
        statuses?.length > index + 1
          ? new Date(statuses[index + 1].date).getTime()
          : new Date(moment().subtract(1, "days").endOf("day").toString());
      return {
        status: status.status,
        id: status.id,
        x: "Status",
        y: [fromDate, toDate],
      };
    });
  }

  getInitialDateRange(): any {
    const toDate = moment.now();
    const fromDate = moment().startOf("year").toDate().getTime();
    return {
      min: fromDate,
      max: toDate,
    };
  }

  formatDate(date: Date): string {
    const tz = moment.tz.guess();
    return moment(date).tz(tz).format("YYYY/MM/DD HH:mm:ss");
  }

  beforeResetZoom(chartContext: any, opts: any): void {
    opts.config.xaxis.min = this.getInitialDateRange().min;
    opts.config.xaxis.max = this.getInitialDateRange().max;
    this.fromDate = new Date(opts.config.xaxis.min);
    this.toDate = new Date(opts.config.xaxis.max);
  }

  zoomed(chartContext: any, { xaxis, yaxis }: any) {
    this.fromDate = new Date(xaxis.min);
    this.toDate = new Date(xaxis.max);
  }

  onClickChart(event: any, chartContext: any, config: any): void {
    const data = this.serie[config.seriesIndex].data[config.dataPointIndex];
    const statusInfo = {
      id: data.id,
      endDate: data.y[1],
    };
    this.$emit("onClickTimelineStatus", statusInfo);
  }
}
