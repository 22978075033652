
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

@Options({
  name: "Footer",
  props: {
    account: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getSelectedCountryBloc", "getSelectedSite"]),
  },
})
export default class Footer extends Vue {
  getSelectedSite!: string;
}
