
import { Options, Vue } from "vue-class-component";
import {
  convertStatusEventEnum,
  StatusEventEnum,
} from "../../types/status-events";
import { mapActions, mapGetters } from "vuex";
import {
  StopCodes,
  Trade,
  EquipmentParamDetails,
  StopCodeDetails,
} from "../../types/equipment-tree";

@Options({
  name: "TradeHierarchy",
  methods: {
    ...mapActions(["updateSelectedTrade"]),
  },
  computed: {
    ...mapGetters([
      "getSelectedTrade",
      "getEquipmentParam",
      "getSelectedStatusEvent",
    ]),
  },
  data() {
    return {
      _selectedTrade: undefined,
    };
  },
})
export default class TradeHierarchy extends Vue {
  equipmentData!: StopCodes[];
  updateSelectedTrade!: (trade: Trade | undefined) => void;
  getSelectedStatusEvent!: StatusEventEnum;
  getSelectedTrade!: Trade;
  eventData!: StopCodes[];
  _selectedTrade?: Trade;
  getEquipmentParam!: EquipmentParamDetails;

  get tradeList(): any[] {
    let list: Trade[] = [];
    let tradeData = this.getEquipmentParam?.tradeType;
    if (this.getSelectedStatusEvent && tradeData) {
      let event = convertStatusEventEnum(this.getSelectedStatusEvent);
      let eventDetail: StopCodeDetails = this.findEventDetails(event);
      if (eventDetail.tradeAll === true) {
        let getTrade: Trade | undefined = this.getTrade("All");
        if (getTrade) list.push(getTrade);
      }
      if (eventDetail.tradeElec === true) {
        let getTrade: Trade | undefined = this.getTrade("Elec");
        if (getTrade) list.push(getTrade);
      }
      if (eventDetail.tradeInstrum === true) {
        let getTrade: Trade | undefined = this.getTrade("Instrum");
        if (getTrade) list.push(getTrade);
      }
      if (eventDetail.tradeMeca === true) {
        let getTrade: Trade | undefined = this.getTrade("Meca");
        if (getTrade) list.push(getTrade);
      }
      if (eventDetail.tradeHydraulic === true) {
        let getTrade: Trade | undefined = this.getTrade("Hydraulic");
        if (getTrade) list.push(getTrade);
      }
      if (eventDetail.tradeOther === true) {
        let getTrade: Trade | undefined = this.getTrade("Other");
        if (getTrade) list.push(getTrade);
      }
    } else {
      list = [];
    }
    return list;
  }

  getTrade(name: string): Trade | undefined {
    let tradeData = this.getEquipmentParam?.tradeType;
    let trade: any;
    tradeData.forEach((tradeDataItem: Trade) => {
      if (tradeDataItem.name === name) trade = tradeDataItem;
    });
    return trade;
  }

  findEventDetails(name: string): StopCodeDetails {
    let eventDetail: any;
    let eventData = this.getEquipmentParam?.stopCodes;
    if (eventData) {
      eventData.forEach((stopCode: StopCodes) => {
        stopCode.details.forEach((detail: StopCodeDetails) => {
          if (detail.oneCbmType === name) {
            eventDetail = detail;
            return eventDetail;
          }
        });
      });
    }
    return eventDetail;
  }

  get selectedTrade(): Trade | undefined {
    return this.getSelectedTrade;
  }

  set selectedTrade(trade: Trade | undefined) {
    this._selectedTrade = trade;
    this.updateSelectedTrade(this._selectedTrade);
  }

  displayTradeList(): boolean {
    return this.tradeList.length > 0;
  }
}
