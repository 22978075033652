import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import EquipmentList from "../components/equipment-list/EquipmentList.vue";
import EquipmentDetails from "../views/EquipmentDetails.vue";
import EquipmentReporting from "../views/EquipmentReporting.vue";
import EquipmentAlerting from "../views/EquipmentAlerting.vue";
import QuickDashboard from "../views/QuickDashboard.vue";
import AutomaticStatus from "../components/automatic-status/AutomaticStatus.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:countryBloc?/:site?/equipment-list",
    name: "EquipmentList",
    component: EquipmentList,
    props: true,
  },
  {
    path: "/:countryBloc?/:site?/equipment-details/:equipmentModelName?/:equipmentTag?",
    name: "EquipmentDetails",
    component: EquipmentDetails,
    props: true,
  },
  {
    path: "/:countryBloc?/:site?/equipment-reporting",
    name: "EquipmentReporting",
    component: EquipmentReporting,
    props: true,
  },
  {
    path: "/:countryBloc?/:site?/equipment-alerting",
    name: "EquipmentAlerting",
    component: EquipmentAlerting,
    props: true,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:countryBloc?/:site?/quick-dashboard",
    name: "QuickDashboard",
    component: QuickDashboard,
    props: true,
  },
  {
    path: "/:countryBloc?/:site?/automatic-status-update",
    name: "AutomaticStatus",
    component: AutomaticStatus,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
