
import { DryGasSealType, EquipmentComment } from "@/types/equipment-comment";
import { EquipmentCommentFile } from "@/types/equipment-comment-file";
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import { Equipment } from "@/types/equipment";
import Datepicker from "../equipment-status/Datepicker.vue";
import { ref, unref } from "vue";
import moment from "moment-timezone";
import _ from "lodash";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import {
  AnalysisType,
  ElecInsulationAnalysis,
  ElecOilAnalysis,
  InformationType,
  OilType,
  QualityKPI,
} from "../../types/equipment-comment";
import {
  getClassForQualityKpiText,
  getClassForQualityKpiIcon,
  getQualityKpiIcon,
  informationTypes,
  informationTypesTransformers,
  qualityKpis,
  analysisTypes,
  oilTypes,
  dryGasSealTypes,
  dryGasSealTypesForDrivenPUMP,
  isNumber,
} from "../../helpers/equipment-comment-helper";
import {
  deleteFiles,
  downloadFile,
  uploadFile,
} from "../../helpers/file-upload-helper";
import { EquipmentSystem } from "../../types/equipment-model";
import ElecAnalysis from "./ElecAnalysis.vue";
import {
  isUserUnknown,
  isUserGuest,
  isUserAdmin,
} from "../../helpers/user-role-helper";
import { UserRole } from "../../types/user-info";
import {
  EquipmentDetails,
  EquipmentParamDetails,
  Trade,
} from "@/types/equipment-tree";
import { StatusEventEnum } from "@/types/status-events";
import {
  convertEnumStatus,
  allEvents,
  getLastSerialNumber,
} from "@/helpers/equipment-status-helpers";
import { EquipmentStatus, EquipmentStatuses } from "@/types/equipment-status";
import EquipmentHierarchy from "./EquipmentHierarchy.vue";
import TradeHierarchy from "./TradeHierarchy.vue";
import date from 'quasar/src/utils/date.js';;
import {
  displayAdditionalInformation,
  getDisplayShortfall,
  getEquipmentStatus,
  getEventDisplayEquipementList,
  getTrade,
} from "@/helpers/equipment-condition-helper";

const informationTypesExtended = [
  ...informationTypes,
  {
    label: "Dry Gas Seal",
    value: InformationType.DRY_GAS_SEAL,
  },
  {
    label: "Status Change",
    value: InformationType.STATUS_CHANGE,
  },
  {
    label: "Offshore",
    value: InformationType.OFFSHORE,
  },
];

const informationTypesExtendedTransformers = [
  ...informationTypesTransformers,
  {
    label: "Dry Gas Seal",
    value: InformationType.DRY_GAS_SEAL,
  },
  {
    label: "Status Change",
    value: InformationType.STATUS_CHANGE,
  },
  {
    label: "Offshore",
    value: InformationType.OFFSHORE,
  },
];

@Options({
  name: "EditEquipmentCommentPopin",
  components: {
    Datepicker,
    ConfirmDialog,
    ElecAnalysis,
    EquipmentHierarchy,
    TradeHierarchy,
  },
  methods: {
    ...mapActions([
      "updateEquipmentComment",
      "displayEditCommentPopin",
      "claimSasToken",
      "addEquipmentCommentFile",
      "equipmentCommentDetailsLoaded",
      "deleteEquipmentCommentFile",
      "updateSelectedTrade",
      "updateSelectedEquipment",
      "updateSelectedStatusEvent",
      "modifyEquipmentStatus",
      "updateSelectedDate",
    ]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentDetails",
      "getDisplayEditCommentPopin",
      "getSasToken",
      "getEquipmentCommentDetails",
      "getIsCommentPopinReadOnly",
      "getAuthInfoAPI",
      "getUserRole",
      "getEquipmentParam",
      "getSelectedCountryBloc",
      "getSelectedTrade",
      "getSelectedEquipment",
      "getSelectedStatusEvent",
      "getDateToUpdate",
      "getFlaringUnit",
      "getProductionShortfallUnit",
      "getWaterInjectShortfallUnit",
    ]),
  },
  data() {
    return {
      currentFile: "",
      informationTypesExtended,
      informationTypesExtendedTransformers,
      analysisTypes,
      analysisType: "",
      oilType: "",
      oilTypes,
      dryGasSealType: "",
      dryGasSealTypes,
      dryGasSealTypesForDrivenPUMP,
      qualityKpi: "",
      qualityKpis,
      oilAnalysis: {},
      insulationAnalysis: {},
      commentLoaded: {},
      _selectedStatus: "",
      author: "",
      woNumber: "",
      comment: "",
      addToWeeklyReport: false,
      isAlert: false,
      productionShortfall: "",
      waterInjectShortfall: "",
      flaring: "",
      equipmentMajorFailure: false,
      gasOrOilLeak: false,
      fireConfirmed: false,
      showSerialNumber: false,
      serialNumber: null,
      selectedEquipmentName: undefined,
    };
  },
})
export default class EditEquipmentCommentPopin extends Vue {
  equipmentSystemEnum = EquipmentSystem;
  informationTypeEnum = InformationType;
  files: File[] | null = null;
  selectedDate = ref("");
  commentLoaded!: EquipmentComment;
  getEquipmentDetails!: Equipment;
  oilAnalysis!: ElecOilAnalysis;
  insulationAnalysis!: ElecInsulationAnalysis;
  getAuthInfoAPI!: any;
  getUserRole!: UserRole;
  hideCloseButton = false;

  status!: EquipmentStatus;
  author!: string;
  woNumber!: string;
  comment!: string;
  addToWeeklyReport = false;
  isAlert = false;
  _selectedStatus!: EquipmentStatuses | undefined;
  productionShortfall!: number | undefined;
  waterInjectShortfall!: number | undefined;
  flaring!: number | undefined;
  equipmentMajorFailure = false;
  gasOrOilLeak = false;
  fireConfirmed = false;
  getEquipmentParam!: EquipmentParamDetails;
  getSelectedTrade!: Trade;
  getSelectedEquipment!: number | undefined;
  getSelectedCountryBloc!: any;
  getSelectedStatusEvent!: StatusEventEnum;
  getDateToUpdate!: string;
  showSerialNumber!: boolean;
  paths: EquipmentDetails[] = [];
  selectedEquipmentName: string | undefined = undefined;
  serialNumber!: string | null | undefined;
  serialNumberOld!: string | null | undefined;

  displayEditCommentPopin!: ({
    displayEditCommentPopin,
    isEditPopinReadOnly,
  }: any) => void;
  getIsCommentPopinReadOnly!: boolean;
  claimSasToken!: () => Promise<void>;
  getSasToken!: string;
  getEquipmentCommentDetails!: EquipmentComment;
  currentFile!: undefined | File | EquipmentCommentFile;
  displayConfirmDeleteDialog = false;
  selectedInformation = InformationType.OTHER;
  _analysisType?: AnalysisType;
  _qualityKpi?: QualityKPI;
  _oilType?: OilType;
  _dryGasSealType?: DryGasSealType;
  updateEquipmentComment!: ({
    equipmentId,
    equipmentCommentId,
    equipmentComment,
  }: {
    equipmentId: number;
    equipmentCommentId: number | undefined;
    equipmentComment: EquipmentComment | undefined;
  }) => Promise<EquipmentComment>;

  updateSelectedStatusEvent!: (
    statusEvent: StatusEventEnum | undefined
  ) => void;
  updateSelectedTrade!: (trade: Trade | undefined) => void;
  updateSelectedEquipment!: (equipment: number | undefined) => void;
  updateSelectedDate!: (date: string | undefined) => void;
  modifyEquipmentStatus!: ({
    statusId,
    status,
    statusDate,
    event,
    commentId,
    equipmentMajorFailure,
    gasOrOilLeak,
    fireConfirmed,
    equipmentDetailsId,
    tradeTypeId,
    productionShortfall,
    waterInjectShortfall,
    flaring,
    equipmentComment,
    serialNumber,
    equipmentDetails,
  }: {
    statusId: number | undefined;
    status: EquipmentStatuses | undefined;
    statusDate: string;
    event: StatusEventEnum | undefined;
    commentId: number | undefined;
    equipmentMajorFailure: boolean | undefined;
    gasOrOilLeak: boolean | undefined;
    fireConfirmed: boolean | undefined;
    equipmentDetailsId: number | undefined;
    tradeTypeId: number | undefined;
    productionShortfall: number | undefined;
    waterInjectShortfall: number | undefined;
    flaring: number | undefined;
    equipmentComment: EquipmentComment | null;
    serialNumber: string | null | undefined;
    equipmentDetails: Equipment;
  }) => Promise<boolean>;

  addEquipmentCommentFile!: ({
    equipmentCommentFile,
    equipmentComment,
    equipmentId,
  }: {
    equipmentCommentFile: EquipmentCommentFile;
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => EquipmentCommentFile;

  deleteEquipmentCommentFile!: ({
    equipmentCommentFile,
    equipmentComment,
    equipmentId,
  }: {
    equipmentCommentFile: EquipmentCommentFile;
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<boolean>;

  isNumberCheck(evt: KeyboardEvent): void {
    isNumber(evt);
  }

  showSerialNumberSectionFn(): boolean {
    if (
      this.getEquipmentDetails.driver === "Turbine" &&
      (this.getEquipmentDetails.technology === "Aeroderivative" ||
        this.getEquipmentDetails.technology === "Industrial")
    ) {
      if (
        this.selectedEquipmentName === "Turbine" ||
        (this.paths.length > 0 && this.paths[0].equipmentItem === "Turbine")
      ) {
        if (
          this.selectedStatus === EquipmentStatuses.SCH &&
          this.getSelectedStatusEvent === StatusEventEnum.SCH_MAJOR_PM
        ) {
          return true;
        } else if (
          this.selectedStatus === EquipmentStatuses.UNSCH &&
          this.getSelectedStatusEvent === StatusEventEnum.UNSCH_FAILURE &&
          this.equipmentMajorFailure === true
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getEquipmentPath(path: EquipmentDetails[]): void {
    this.paths = path;
  }

  getSelectedEquipmentName(eqName: string): void {
    this.selectedEquipmentName = eqName;
  }

  updated(): void {
    this.commentLoaded = _.clone(this.getEquipmentCommentDetails);
    this.selectedStatus = this.commentLoaded.status?.status;
    this.updateSelectedStatusEvent(this.commentLoaded.status?.event);
    this.updateSelectedEquipment(this.commentLoaded.status?.equipmentDetailsId);
    this.updateSelectedTrade(
      this.tradeValue(this.commentLoaded.status?.tradeTypeId)
    );
    this.productionShortfall = this.commentLoaded.status?.productionShortfall
      ? this.commentLoaded.status?.productionShortfall
      : undefined;
    this.waterInjectShortfall = this.commentLoaded.status?.waterInjectShortfall
      ? this.commentLoaded.status?.waterInjectShortfall
      : undefined;
    this.flaring = this.commentLoaded.status?.flaring
      ? this.commentLoaded.status?.flaring
      : undefined;
    this.equipmentMajorFailure = this.commentLoaded.status
      ?.equipmentMajorFailure
      ? this.commentLoaded.status?.equipmentMajorFailure
      : false;
    this.fireConfirmed = this.commentLoaded.status?.fireConfirmed
      ? this.commentLoaded.status?.fireConfirmed
      : false;
    this.gasOrOilLeak = this.commentLoaded.status?.gasOrOilLeak
      ? this.commentLoaded.status?.gasOrOilLeak
      : false;
    this.selectedInformationType = this.commentLoaded.informationType;
    this.analysisType = this.commentLoaded.analysisType;
    this.qualityKpi = this.commentLoaded.qualityKpi;
    if (this.qualityKpi == null) this.qualityKpi = undefined;
    this.oilType = this.commentLoaded.oilType;
    this.dryGasSealType = this.commentLoaded.dryGasSealComponent;
    this.oilAnalysis = {
      elecMoistureWater: this.commentLoaded.elecMoistureWater,
      elecDissolvedGasHydrogen: this.commentLoaded.elecDissolvedGasHydrogen,
      elecDissolvedGasMethane: this.commentLoaded.elecDissolvedGasMethane,
      elecDissolvedGasAcethylene: this.commentLoaded.elecDissolvedGasAcethylene,
      elecDissolvedGasEthylene: this.commentLoaded.elecDissolvedGasEthylene,
      elecDissolvedGasEthane: this.commentLoaded.elecDissolvedGasEthane,
      elecDissolvedGasMonoxide: this.commentLoaded.elecDissolvedGasMonoxide,
      elecDissolvedGasDioxide: this.commentLoaded.elecDissolvedGasDioxide,
      elecDissolvedGasTdcg: this.commentLoaded.elecDissolvedGasTdcg,
    };
    this.insulationAnalysis = {
      mean: this.commentLoaded.mean,
      standardDeviation: this.commentLoaded.standardDeviation,
      ratioMeanStandardDeviation: this.commentLoaded.ratioMeanStandardDeviation,
    };
    this.hideCloseButton = false;
    const tz = moment.tz.guess();
    this.updateSelectedDate(
      date.formatDate(this.commentLoaded.date, "YYYY-MM-DD HH:mm")
    );
    this.selectedDate = ref(
      moment(this.commentLoaded.date).tz(tz).format("YYYY-MM-DD HH:mm")
    );
    this.serialNumberOld = this.getLastSerialNumberFn.serialNumber;
  }

  tradeValue(id: number | undefined): Trade | undefined {
    return getTrade(id, this.getEquipmentParam);
  }

  get equipmentStatus(): any[] {
    return getEquipmentStatus(this.getEquipmentParam);
  }

  displaySpecificFailure(): boolean {
    return displayAdditionalInformation(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  eventDisplayEquipementList(): boolean {
    return getEventDisplayEquipementList(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  displayShortfall(): boolean {
    return getDisplayShortfall(
      this.getSelectedStatusEvent,
      this.getEquipmentParam
    );
  }

  set selectedStatus(status: EquipmentStatuses | undefined) {
    this._selectedStatus = status;
    this.updateSelectedStatusEvent(undefined);
    this.updateSelectedTrade(undefined);
    this.updateSelectedEquipment(undefined);
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
  }

  get selectedStatus(): EquipmentStatuses | undefined {
    return this._selectedStatus;
  }

  set selectedEvent(event: StatusEventEnum | undefined) {
    this.updateSelectedStatusEvent(event);
    this.updateSelectedTrade(undefined);
    if (this.selectedStatus?.includes("unscheduled")) {
      if (
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_ENV) ||
        this.getSelectedStatusEvent.includes(StatusEventEnum.UNSCH_HUMAN)
      ) {
        this.updateSelectedEquipment(undefined);
      }
    }
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.paths = [];
    this.selectedEquipmentName = "";
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
  }

  get selectedEvent(): StatusEventEnum | undefined {
    return this.getSelectedStatusEvent;
  }

  get availableEvents(): any[] {
    if (this.selectedStatus) {
      const events = _.filter(allEvents, (displayedEvent: any) => {
        return displayedEvent.associatedStatus === this.selectedStatus;
      });
      if (this.selectedStatus === EquipmentStatuses.RUN) {
        this.selectedEvent = StatusEventEnum.RUN_RESTARTED;
      } else if (this.selectedStatus === EquipmentStatuses.ABNR) {
        this.selectedEvent = StatusEventEnum.ABNR_STAND_BY;
      } else if (this.selectedStatus === EquipmentStatuses.RUN_LIMITED) {
        this.selectedEvent = StatusEventEnum.RUN_LIMITED;
      }
      return events;
    }
    return [];
  }

  get selectedInformationType(): InformationType {
    return this.selectedInformation;
  }

  set selectedInformationType(type: InformationType) {
    if (
      type === InformationType.OTHER ||
      type === InformationType.SERVICE_BULLETINS ||
      type === InformationType.STATUS_CHANGE ||
      type === InformationType.OFFSHORE
    ) {
      this.analysisType = undefined;
      this.qualityKpi = undefined;
      this.dryGasSealType = undefined;
    } else {
      this.analysisType = AnalysisType.ROUTINE;
      this.qualityKpi = undefined;
    }
    if (type !== InformationType.OIL) {
      this.oilType = undefined;
    } else {
      this.oilType = OilType.HYD_OIL;
    }
    if (
      this.getEquipmentDetails?.equipmentModel?.system ===
      EquipmentSystem.TRANSFORMERS
    ) {
      if (type !== InformationType.OIL) {
        this.oilAnalysis = {
          elecMoistureWater: undefined,
          elecDissolvedGasHydrogen: undefined,
          elecDissolvedGasMethane: undefined,
          elecDissolvedGasAcethylene: undefined,
          elecDissolvedGasEthylene: undefined,
          elecDissolvedGasEthane: undefined,
          elecDissolvedGasMonoxide: undefined,
          elecDissolvedGasDioxide: undefined,
          elecDissolvedGasTdcg: undefined,
        };
      }
      if (type !== InformationType.INSULATION) {
        this.insulationAnalysis = {
          mean: undefined,
          standardDeviation: undefined,
          ratioMeanStandardDeviation: undefined,
        };
      }
    }
    this.selectedInformation = type;
  }

  get analysisType(): AnalysisType | undefined {
    return this._analysisType;
  }

  set analysisType(type: AnalysisType | undefined) {
    this._analysisType = type;
  }

  get oilType(): OilType | undefined {
    return this._oilType;
  }

  set oilType(type: OilType | undefined) {
    this._oilType = type;
  }

  get qualityKpi(): QualityKPI | undefined {
    return this._qualityKpi;
  }

  set qualityKpi(quality: QualityKPI | undefined) {
    this._qualityKpi = quality;
  }

  get dryGasSealType(): DryGasSealType | undefined {
    return this._dryGasSealType;
  }

  set dryGasSealType(dryGasSeal: DryGasSealType | undefined) {
    this._dryGasSealType = dryGasSeal;
  }

  get getClassQuality(): any {
    return getClassForQualityKpiText(this.qualityKpi);
  }

  get getClassQualityIcon(): any {
    return getClassForQualityKpiIcon(this.qualityKpi);
  }

  get icon(): string {
    return getQualityKpiIcon(this.qualityKpi);
  }

  get isReadOnly(): boolean {
    return this.getIsCommentPopinReadOnly;
  }

  onClose(): void {
    this.updateSelectedStatusEvent(undefined);
    this.updateSelectedTrade(undefined);
    this.updateSelectedEquipment(undefined);
    this.updateSelectedDate(undefined);
    this.selectedStatus = undefined;
    this.comment = "";
    this.author = "";
    this.woNumber = "";
    this.files = null;
    this.addToWeeklyReport = false;
    this.isAlert = false;
    this.productionShortfall = undefined;
    this.waterInjectShortfall = undefined;
    this.flaring = undefined;
    this.equipmentMajorFailure = false;
    this.gasOrOilLeak = false;
    this.fireConfirmed = false;
    this.displayEditCommentPopin({
      displayEditCommentPopin: false,
      isEditPopinReadOnly: false,
    });
    this.serialNumber = null;
    this.serialNumberOld = null;
    this.showSerialNumber = false;
    this.paths = [];
    this.selectedEquipmentName = "";
    this.$emit("onClosed");
  }

  showNotifCommentUpdated(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `Comment for ${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } has been updated.`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  showNotifFileDeleted(file: string): void {
    this.$q.notify({
      message: `File "${file}" has been deleted.`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  async onSubmit(): Promise<void> {
    if (
      this.selectedInformationType !== InformationType.OTHER &&
      this.selectedInformationType !== InformationType.SERVICE_BULLETINS &&
      this.selectedInformationType !== InformationType.STATUS_CHANGE &&
      this.selectedInformationType !== InformationType.OFFSHORE &&
      this.qualityKpi === undefined
    ) {
      this.$q.notify({
        message: `Please select Quality KPI`,
        color: "negative",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
      return;
    }
    this.$q.loading.show();
    const tz = moment.tz.guess();
    const formattedDate = new Date(
      moment.tz(this.selectedDate, tz).toString()
    ).toString();
    const commentLoaded = {
      date: formattedDate,
      comment: this.commentLoaded.comment,
      woStatus: this.commentLoaded.woStatus,
      woNumber: this.commentLoaded.woNumber,
      status: this.commentLoaded.status,
      author: this.getAuthInfoAPI?.account?.name,
      informationType: this.selectedInformationType,
      analysisType: this.analysisType,
      qualityKpi: this.qualityKpi,
      oilType: this.oilType,
      dryGasSealComponent: this.dryGasSealType,
      elecMoistureWater: this.oilAnalysis.elecMoistureWater,
      elecDissolvedGasHydrogen: this.oilAnalysis.elecDissolvedGasHydrogen,
      elecDissolvedGasMethane: this.oilAnalysis.elecDissolvedGasMethane,
      elecDissolvedGasAcethylene: this.oilAnalysis.elecDissolvedGasAcethylene,
      elecDissolvedGasEthylene: this.oilAnalysis.elecDissolvedGasEthylene,
      elecDissolvedGasEthane: this.oilAnalysis.elecDissolvedGasEthane,
      elecDissolvedGasMonoxide: this.oilAnalysis.elecDissolvedGasMonoxide,
      elecDissolvedGasDioxide: this.oilAnalysis.elecDissolvedGasDioxide,
      elecDissolvedGasTdcg: this.oilAnalysis.elecDissolvedGasTdcg,
      mean: this.insulationAnalysis.mean,
      standardDeviation: this.insulationAnalysis.standardDeviation,
      ratioMeanStandardDeviation:
        this.insulationAnalysis.ratioMeanStandardDeviation,
      addToWeeklyReport: this.commentLoaded.addToWeeklyReport,
      isAlert: this.commentLoaded.isAlert,
    } as EquipmentComment;
    const equipmentComment = await this.updateEquipmentComment({
      equipmentId: this.getEquipmentDetails.id,
      equipmentCommentId: this.commentLoaded.id,
      equipmentComment: commentLoaded,
    });
    if (equipmentComment) {
      if (this.selectedInformationType === InformationType.STATUS_CHANGE) {
        await this.addHistoricalStatus(equipmentComment, equipmentComment.id);
      }

      if (this.files) {
        for (let file of this.files) {
          await this.claimSasToken();
          const token = this.getSasToken;
          if (token) {
            const blobName = await uploadFile(
              token,
              file,
              equipmentComment.id,
              tz
            );
            const equipmentCommentFile: EquipmentCommentFile = {
              name: blobName,
            };
            this.addEquipmentCommentFile({
              equipmentCommentFile,
              equipmentComment,
              equipmentId: this.getEquipmentDetails.id,
            });
          }
        }
      }
      this.showNotifCommentUpdated();
      this.$emit("updatedEquipmentComment", {
        equipmentId: this.getEquipmentDetails.id,
        equipmentComment,
      });
    }
    this.onClose();
    this.$q.loading.hide();
  }

  async addHistoricalStatus(
    equipmentComment: EquipmentComment | null,
    commentId: number | undefined
  ): Promise<void> {
    const successUpdateEquipmentStatus = await this.modifyEquipmentStatus({
      statusId: equipmentComment?.status?.id,
      status: this.selectedStatus,
      statusDate: this.getDateToUpdate,
      event: this.getSelectedStatusEvent,
      commentId: commentId,
      equipmentMajorFailure: this.equipmentMajorFailure,
      gasOrOilLeak: this.gasOrOilLeak,
      fireConfirmed: this.fireConfirmed,
      equipmentDetailsId: this.getSelectedEquipment,
      tradeTypeId: this.getSelectedTrade?.id,
      productionShortfall: this.productionShortfall
        ? this.productionShortfall
        : undefined,
      waterInjectShortfall: this.waterInjectShortfall
        ? this.waterInjectShortfall
        : undefined,
      flaring: this.flaring ? this.flaring : undefined,
      equipmentComment: equipmentComment,
      serialNumber: this.showSerialNumber
        ? this.serialNumber
        : this.serialNumberOld,
      equipmentDetails: this.getEquipmentDetails,
    });
    if (successUpdateEquipmentStatus) {
      this.showNotif();
    }
  }

  showNotif(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } Status has been correctly updated to "${convertEnumStatus(
        this.selectedStatus ?? ""
      )}" with starting date "${this.getDateToUpdate}"`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  async downloadFile(filename: string): Promise<void> {
    await this.claimSasToken();
    const token = this.getSasToken;
    if (token) {
      await downloadFile(token, "file-upload", filename);
    }
  }

  openConfirmDelete(file: File | EquipmentCommentFile): void {
    this.currentFile = file;
    this.displayConfirmDeleteDialog = true;
  }

  closeConfirmDelete(): void {
    this.displayConfirmDeleteDialog = false;
  }

  async onConfirmDelete(): Promise<void> {
    this.$q.loading.show();
    const file = this.currentFile;
    if (file) {
      await this.claimSasToken();
      const token = this.getSasToken;
      if (token) {
        await deleteFiles(token, [file.name]);
        const isDeleted = await this.deleteEquipmentCommentFile({
          equipmentCommentFile: file,
          equipmentComment: this.getEquipmentCommentDetails,
          equipmentId: this.getEquipmentDetails.id,
        });
        if (isDeleted) {
          _.remove(this.getEquipmentCommentDetails.equipmentCommentFiles, {
            name: file.name,
          });
          this.showNotifFileDeleted(file.name);
        }
      }
      this.closeConfirmDelete();
      this.hideCloseButton = true;
    }
    this.$q.loading.hide();
  }

  get confirmDeleteFileMessage(): string {
    return "Do you confirm to delete the file " + this.currentFile?.name + " ?";
  }

  isValidDate(date: string): boolean {
    if (_.isEmpty(date)) return false;
    const momentDate = moment(date);
    return momentDate.isValid();
  }

  minDate(date: string): boolean {
    const momentDate = moment(date, "YYYY-MM-DD");
    return momentDate.isBefore(moment());
  }

  minTime(hour: number, minute: number): boolean {
    const momentSelectedDate = moment(unref(this.selectedDate), "YYYY/MM/DD");
    momentSelectedDate.set({ hour, minute });
    return momentSelectedDate.isBefore(moment());
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  isUserAdmin(): boolean {
    return isUserAdmin(this.getUserRole);
  }

  isDateEditable(): boolean {
    return (
      (this.isUserAdmin() ||
        this.getAuthInfoAPI?.account.name.trim().toLowerCase() ===
          this.commentLoaded.author?.toString().trim().toLowerCase() ||
        this.commentLoaded.author?.toString().trim().toLowerCase() ===
          "onecbm") &&
      !this.isUserUnknownOrGuest()
    );
  }

  getDryGasSealLabel(): string {
    if (this.getEquipmentDetails.driven === "Compressor")
      return "Dry gas seal type";
    else if (this.getEquipmentDetails.driven === "Pump")
      return "Mechanical seal type";
    else return "";
  }

  get getLastSerialNumberFn(): {
    serialNumber: string | undefined | null;
    installationDate: string | undefined | null;
  } {
    return getLastSerialNumber(this.getEquipmentDetails);
  }
}
