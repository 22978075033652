
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "../equipment-status/Datepicker.vue";

@Options({
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Datepicker,
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters(["getDateToUpdate"]),
  },
})
export default class ConfirmDialog extends Vue {
  open!: boolean;
  getDateToUpdate!: string;

  emitConfirm(): void {
    this.$emit("onConfirm");
  }

  emitClose(): void {
    this.$emit("onClose");
  }
}
