import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_EquipmentCommentsForBarChart = _resolveComponent("EquipmentCommentsForBarChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_apexchart, {
          height: "300",
          "data-test": "bar-chart",
          type: "bar",
          options: _ctx.chartOptions,
          series: _ctx.series,
          onClick: _ctx.onBarGraphClick
        }, null, 8 /* PROPS */, ["options", "series", "onClick"])
      ])
    ]),
    (_ctx.openEquipmentCommentForBarChartPopin)
      ? (_openBlock(), _createBlock(_component_EquipmentCommentsForBarChart, {
          key: 0,
          equipment: _ctx.equipment,
          months: _ctx.months,
          "clicked-avl-rel": _ctx.clickedAvlOrRel,
          "data-point-index": _ctx.dataPointIndex,
          onOnClose: _ctx.onClose
        }, null, 8 /* PROPS */, ["equipment", "months", "clicked-avl-rel", "data-point-index", "onOnClose"]))
      : _createCommentVNode("v-if", true)
  ]))
}