
import { Options, Vue } from "vue-class-component";
import * as _ from "lodash";
import fp from "lodash/fp";

@Options({
  name: "AggregateGraph",
  props: {
    equipmentStatistics: Object,
  },
})
export default class AggregateGraph extends Vue {
  equipmentStatistics!: any;

  get months(): string[] {
    return Object.keys(this.equipmentStatistics || {});
  }

  get series(): any[] {
    return [
      {
        name: "Availability",
        data: _.map(this.equipmentStatistics, fp.get("availability")),
      },
      {
        name: "Reliability",
        data: _.map(this.equipmentStatistics, fp.get("reliability")),
      },
    ];
  }

  get chartOptions(): any {
    return {
      chart: {
        type: "bar",
        height: 300,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#2F7BB4", "#E00090"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val: any) {
          return val.toString().match(/^-?\d+(?:\.\d?)?/)[0];
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: -20,
        style: {
          colors: ["#333"],
          fontSize: "11px",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        title: {
          text: "Total Availability & Reliability",
        },
        categories: this.months,
      },
      yaxis: {
        title: {
          text: "percent",
        },
        max: 110,
        decimalsInFloat: 0,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val: any) {
            return val;
          },
        },
      },
    };
  }
}
