
import {
  getClassForQualityKpiChip,
  getQualityKpiChipIcon,
} from "@/helpers/equipment-comment-helper";
import { QualityKPI } from "@/types/equipment-comment";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import { ReportingViewType } from "../../types/reporting-view";
import EquipmentTag from "../equipment/EquipmentTag.vue";
import { EquipmentStatistics } from "@/types/equipment-statistics";
import AggregateGraph from "../equipment-reporting/AggregateGraph.vue";

@Options({
  name: "EquipmentModel",
  components: {
    EquipmentTag,
    AggregateGraph,
  },
  computed: {
    ...mapGetters(["getReportingView"]),
  },
  props: {
    modelName: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    availability: {
      type: Number,
      required: false,
    },
    reliability: {
      type: Number,
      required: false,
    },
    eqDescriptionEqs: {
      type: Array,
      required: false,
    },
    equipmentStatistics: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      iconType: "expand_less",
      operatingPhilosophy: "",
    };
  },
})
export default class EquipmentModel extends Vue {
  ReportingViewType = ReportingViewType;
  equipmentStatistics!: EquipmentStatistics;
  getReportingView!: string;
  iconType!: string;
  eqDescriptionEqs!: any;
  operatingPhilosophy!: string;
  availability!: number;
  reliability!: number;
  mounted(): void {
    if (this.getReportingView === ReportingViewType.BARCHART) {
      if (this.eqDescriptionEqs) {
        this.operatingPhilosophy = this.eqDescriptionEqs[0].operatingPhilosophy;
      }
    }
  }
  emitExpensionButtonModel(): void {
    this.iconType =
      this.iconType === "expand_less" ? "expand_more" : "expand_less";
  }

  formatter(val: any): string {
    if (val === -1) return "-";
    else if (val) return val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    else return "-";
  }

  get getClassQualityIconForGraph(): any {
    if (this.availability > 95) {
      return getClassForQualityKpiChip(QualityKPI.GOOD);
    }
    if (this.availability >= 90 && this.availability <= 95) {
      return getClassForQualityKpiChip(QualityKPI.FAIR);
    }
    return getClassForQualityKpiChip(QualityKPI.CRITICAL);
  }

  get icon(): string {
    if (this.availability > 95) {
      return getQualityKpiChipIcon(QualityKPI.GOOD);
    }
    if (this.availability >= 90 && this.availability <= 95) {
      return getQualityKpiChipIcon(QualityKPI.FAIR);
    }
    return getQualityKpiChipIcon(QualityKPI.CRITICAL);
  }
}
