
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapActions } from "vuex";
import { Equipment } from "../../types/equipment";
import router from "../../router";

@Options({
  computed: {
    ...mapGetters([
      "getEquipmentList",
      "getSelectedCountryBloc",
      "getSelectedSite",
      "getCurrentSelectedEquipment",
    ]),
  },
  methods: {
    ...mapActions([
      "equipmentSelectorMounted",
      "currentSelectedEquipmentReset",
      "updateCurrentSelectedEquipment",
    ]),
  },
  name: "EquipmentSelector",
  data() {
    return {
      equipmentSearch: [],
    };
  },
})
export default class EquipmentList extends Vue {
  equipmentSelectorMounted!: () => void;
  getSelectedCountryBloc!: any;
  getSelectedSite!: any;
  equipmentSearch!: Equipment[];
  getEquipmentList!: Equipment[];
  currentSelectedEquipmentReset!: () => void;
  getCurrentSelectedEquipment!: Equipment;
  updateCurrentSelectedEquipment!: (equipment: Equipment) => void;

  mounted(): void {
    this.$q.loading.show();
    if (this.getSelectedSite) {
      this.equipmentSelectorMounted();
    }
    this.$q.loading.hide();
  }

  get equipmentDetails(): Equipment | null {
    return this.getCurrentSelectedEquipment;
  }

  set equipmentDetails(equipment: Equipment | null) {
    if (equipment) {
      router.push({
        name: this.$route.name as any,
        params: {
          id: equipment.id,
          equipmentModelName: equipment.equipmentModel.name,
          equipmentTag: equipment.tag,
          countryBloc: this.getSelectedCountryBloc,
          site: this.getSelectedSite,
        },
      });
      this.updateCurrentSelectedEquipment(equipment);
    } else {
      this.currentSelectedEquipmentReset();
    }
  }

  filterEquipments(val: any, update: any, abort: any): any {
    update(() => {
      const needle = val?.toLowerCase();
      this.equipmentSearch = this.getEquipmentList.filter(
        (equipment: Equipment) =>
          (
            equipment.equipmentModel.name?.toLowerCase() +
            "-" +
            equipment.tag?.toLowerCase()
          ).indexOf(needle) > -1
      );
    });
  }
}
