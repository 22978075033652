import { AuthenticationResult } from "@azure/msal-browser";
import axios from "axios";

export default class MsGraphApi {
  static getUserPicture(authInfoMSGraph: AuthenticationResult | null): any {
    const endpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
    return axios
      .get(endpoint, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${authInfoMSGraph?.accessToken}`,
        },
      })
      .then((response) => response.data);
  }
}
