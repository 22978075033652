
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import Datepicker from "../equipment-status/Datepicker.vue";

@Options({
  name: "HttpErrorDialog",
  components: {
    Datepicker,
  },
  methods: {
    ...mapActions(["displayHttpErrorDialog"]),
  },
  computed: {
    ...mapGetters(["getDisplayHttpErrorDialog", "getHttpErrorMessage"]),
  },
})
export default class HttpErrorDialog extends Vue {
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;

  onClose(): void {
    this.displayHttpErrorDialog({
      displayHttpErrorDialog: false,
      httpErrorMessage: "",
    });
  }
}
