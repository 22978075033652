import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "subheader" }
const _hoisted_2 = { id: "title" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EquipmentTitle = _resolveComponent("EquipmentTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      (_ctx.page === _ctx.pageEnum.EquipmentDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_EquipmentTitle, { equipment: _ctx.getEquipmentDetails }, null, 8 /* PROPS */, ["equipment"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}