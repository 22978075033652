
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { EquipmentStatuses } from "../../types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { Equipment } from "../../types/equipment";
import _ from "lodash";

@Options({
  methods: {
    ...mapActions(["updateFilters"]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentList",
      "getSelectedEquipmentFilter",
      "getCriticalCheckboxFilter",
      "getSelectedStatusFilter",
      "getSelectedDateFilter",
      "getSelectedEquipmentAlertsFilter",
      "getSelectedSectorFilter",
      "getStatusCheckboxFilter",
    ]),
  },
  data() {
    return {
      sectorSearch: [],
    };
  },
  components: { EquipmentStatusIcon },
  name: "SectorSelectorFilter",
})
export default class SectorSelectorFilter extends Vue {
  getEquipmentList!: () => Equipment[];
  sectorSearch!: string[];

  updateFilters!: ({
    selectedSectorFilter,
    selectedEquipmentFilter,
    criticalCheckboxFilter,
    selectedStatusFilter,
    selectedDateFilter,
    selectedEquipmentAlertsFilter,
    statusCheckboxFilter,
  }: {
    selectedSectorFilter: string[] | undefined;
    selectedEquipmentFilter: string[] | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;
  getSelectedEquipmentFilter!: string[];
  getCriticalCheckboxFilter!: boolean;
  getSelectedStatusFilter!: EquipmentStatuses[];
  getSelectedDateFilter!: string;
  getSelectedEquipmentAlertsFilter!: string[];
  getSelectedSectorFilter!: string[];
  getStatusCheckboxFilter!: boolean;
  allCheckbox = false;
  allSelected = false;

  get availableEquipments(): string[] {
    const sectorFilteredEquipments = _.map(
      this.getEquipmentList,
      (equipment: Equipment) => {
        return equipment.sector;
      }
    );
    return [...new Set(sectorFilteredEquipments)];
  }

  set selectedSectorFilter(selectedSectorFilter: string[] | undefined) {
    this.updateFilters({
      selectedSectorFilter,
      selectedEquipmentFilter: this.getSelectedEquipmentFilter,
      criticalCheckboxFilter: this.getCriticalCheckboxFilter,
      selectedStatusFilter: this.getSelectedStatusFilter,
      selectedDateFilter: this.getSelectedDateFilter,
      selectedEquipmentAlertsFilter: this.getSelectedEquipmentAlertsFilter,
      statusCheckboxFilter: this.getStatusCheckboxFilter,
    });
  }

  get selectedSectorFilter(): string[] | undefined {
    return this.getSelectedSectorFilter;
  }

  filterFn(val: any, update: any, abort: any): any {
    update(() => {
      const needle = val.toLowerCase();
      this.sectorSearch = this.availableEquipments.filter(
        (v) => v.toLowerCase().indexOf(needle) > -1
      );
    });
  }
}
