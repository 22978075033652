import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-md status-selector" }
const _hoisted_2 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_input, {
      modelValue: _ctx.selectedDateFilter,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDateFilter) = $event)),
      rounded: "",
      outlined: "",
      clearable: "",
      "data-test": "status-change-selector",
      lined: "",
      label: "Status change from",
      "stack-label": ""
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "event",
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              ref: "qDateProxy",
              "transition-show": "scale",
              "transition-hide": "scale"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_date, {
                  modelValue: _ctx.selectedDateFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateFilter) = $event)),
                  mask: "YYYY-MM-DD"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _withDirectives(_createVNode(_component_q_btn, {
                        label: "Close",
                        color: "primary",
                        flat: ""
                      }, null, 512 /* NEED_PATCH */), [
                        [_directive_close_popup]
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 512 /* NEED_PATCH */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}