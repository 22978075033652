
import { mapActions, mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ProductReference } from "../../types/product-references";

@Options({
  name: "AddOrUpdateProductReferencePopin",
  props: {
    isEditMode: {
      type: Boolean,
      required: false,
    },
    currentProductReference: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions([
      "addProductReference",
      "displayAddOrUpdateProductReferencePopin",
      "updateProductReference",
    ]),
  },
  computed: {
    ...mapGetters(["getDisplayAddOrUpdateProductReferencePopin"]),
  },
})
export default class AddOrUpdateProductReferencePopin extends Vue {
  isEditMode!: boolean;
  currentProductReference!: ProductReference;
  id?: number;
  linkUrl!: string;
  linkTitle!: string;
  displayAddOrUpdateProductReferencePopin!: (
    displayAddOrUpdateProductReferencePopin: boolean
  ) => void;
  $q: any;
  addProductReference!: (
    productReference: ProductReference
  ) => Promise<ProductReference>;

  updateProductReference!: (
    productReference: ProductReference
  ) => Promise<ProductReference>;

  setup(): void {
    this.$q = useQuasar();
  }

  beforeShow(): void {
    this.linkUrl = this.isEditMode ? this.currentProductReference.linkUrl : "";
    this.linkTitle = this.isEditMode
      ? this.currentProductReference.linkTitle
      : "";
    this.id = this.isEditMode ? this.currentProductReference.id : -1;
  }

  async onSubmit(): Promise<void> {
    if (this.linkTitle?.trim() !== "" && this.linkUrl?.trim() !== "") {
      let productReference = undefined;
      let reference = {
        id: this.id,
        linkTitle: this.linkTitle,
        linkUrl: this.linkUrl,
      } as ProductReference;
      if (this.isEditMode) {
        productReference = await this.updateProductReference(reference);
      } else {
        productReference = await this.addProductReference(reference);
      }
      this.onClose();
      if (productReference) {
        this.showNotif();
      }
    }
  }

  onClose(): void {
    this.linkTitle = "";
    this.linkUrl = "";
    this.displayAddOrUpdateProductReferencePopin(false);
    this.$emit("onClose");
  }

  showNotif(): void {
    if (this.isEditMode) {
      this.$q.notify({
        message: "The product reference has been updated",
        color: "positive",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
    } else {
      this.$q.notify({
        message: `A new product reference ${this.linkTitle} has been added.`,
        color: "positive",
        textColor: "black",
        actions: [{ icon: "close", color: "black" }],
      });
    }
  }
}
