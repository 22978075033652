
import { Options, Vue } from "vue-class-component";
import type { Equipment } from "../types/equipment";
import { mapActions, mapGetters } from "vuex";
import EquipmentStatusHistoryCard from "../components/equipment-details/StatusHistoryCard.vue";
import EquipmentLinksCard from "../components/equipment-details/LinksCard.vue";
import EquipmentKPIsCard from "../components/equipment-details/KPIsCard.vue";
import EquipmentEventsCard from "../components/equipment-details/EventsCard.vue";
import EquipmentStatusCurrentCard from "../components/equipment-details/StatusCurrentCard.vue";
import PageTitle, { PageEnum } from "../components/common/PageTitle.vue";
import EquipmentBarchartKPIsCard from "../components/equipment-details/BarchartKPIsCard.vue";
import { EquipmentSystem } from "../types/equipment-model";
import SparePartsCard from "../components/equipment-details/SparePartsCard.vue";

@Options({
  props: {
    equipmentModelName: {
      type: String,
    },
    equipmentTag: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
    equipmentModelNameAndEquipmentTag() {
      return `${this.equipmentModelName}|${this.equipmentTag}`;
    },
  },
  methods: {
    ...mapActions([
      "equipmentDetailsPageLoaded",
      "equipmentDetailsReset",
      "updateSelectedSiteAndUserRole",
      "loadSpareParts",
      "updateSelectedCountryBloc",
    ]),
    async reloadEquipmentDetailsPage() {
      this.$q.loading.show();
      if (this.equipmentModelName)
        await this.equipmentDetailsPageLoaded({
          equipmentModelName: this.equipmentModelName,
          equipmentTag: this.equipmentTag,
        });
      this.$q.loading.hide();
      this.loadSpareParts(this.getEquipmentDetails);
    },
  },
  components: {
    PageTitle,
    EquipmentStatusHistoryCard,
    EquipmentLinksCard,
    EquipmentKPIsCard,
    EquipmentEventsCard,
    EquipmentStatusCurrentCard,
    EquipmentBarchartKPIsCard,
    SparePartsCard,
  },
  name: "EquipmentDetails",
  watch: {
    async equipmentModelNameAndEquipmentTag(newValues, oldValues) {
      const [newEquipmentModelName, newEquipmentTag] = newValues.split("|");
      const [oldEquipmentModelName, oldEquipmentTag] = oldValues.split("|");
      if (
        newEquipmentModelName !== oldEquipmentModelName ||
        newEquipmentTag !== oldEquipmentTag
      ) {
        this.reloadEquipmentDetailsPage();
      }
    },
  },
  data() {
    return {
      clickedStatusId: undefined,
    };
  },
})
export default class EquipmentDetails extends Vue {
  equipmentDetailsPageLoaded!: ({
    equipmentModelName,
    equipmentTag,
  }: any) => Promise<void>;
  equipmentDetailsReset!: () => void;
  getEquipmentDetails!: Equipment;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  loadSpareParts!: (equipment: Equipment) => void;
  id?: string = undefined;
  pageEnum = PageEnum;
  equipmentSystemEnum = EquipmentSystem;
  equipmentModelName?: string = undefined;
  equipmentTag?: string = undefined;
  updateSelectedCountryBloc!: (countryBloc: string) => void;
  clickedStatusId!: number | undefined;

  async created(): Promise<void> {
    this.updateSelectedCountryBloc(this.$route.params.countryBloc.toString());
    this.updateSelectedSiteAndUserRole(this.$route.params.site.toString());
  }
  async mounted(): Promise<void> {
    if (this.equipmentModelName) {
      this.$q.loading.show();
      await this.equipmentDetailsPageLoaded({
        equipmentModelName: this.equipmentModelName,
        equipmentTag: this.equipmentTag,
      });
      this.$q.loading.hide();
      this.loadSpareParts(this.getEquipmentDetails);
    }
  }
  unmounted(): void {
    this.equipmentDetailsReset();
  }

  onClickTimelineStatus(statusId: number | undefined): void {
    this.clickedStatusId = statusId;
  }
  onFilterEventsReset(): void {
    this.clickedStatusId = undefined;
  }
}
