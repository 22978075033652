
import { Options, Vue } from "vue-class-component";
import { StatusIcon } from "../../helpers/equipment-status-helpers";
import { EquipmentStatuses } from "../../types/equipment-status";

@Options({
  props: {
    status: {
      type: String,
      required: false,
    },
  },
  name: "EquipmentStatusIcon",
})
export default class EquipmentStatusIcon extends Vue {
  status!: EquipmentStatuses;

  getClass(): any {
    return {
      "bg-red": this.status === EquipmentStatuses.UNSCH,
      "bg-orange": this.status === EquipmentStatuses.SCH,
      "bg-green":
        this.status === EquipmentStatuses.RUN_LIMITED ||
        this.status === EquipmentStatuses.RUN,
      "bg-darkgrey": this.status === EquipmentStatuses.ABNR,
      "status-badge": true,
    };
  }

  get icon(): StatusIcon {
    if (this.status) {
      return StatusIcon[this.status];
    }
    return StatusIcon.missing;
  }
}
