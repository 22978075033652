
import { Options, Vue } from "vue-class-component";
import { EquipmentStatuses } from "../../types/equipment-status";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { mapActions, mapGetters } from "vuex";

@Options({
  components: { EquipmentStatusIcon },
  methods: {
    ...mapActions(["updateFilters"]),
  },
  computed: {
    ...mapGetters([
      "getSelectedDateFilter",
      "getCriticalCheckboxFilter",
      "getSelectedStatusFilter",
      "getSelectedEquipmentFilter",
      "getSelectedEquipmentAlertsFilter",
      "getSelectedSectorFilter",
      "getStatusCheckboxFilter",
    ]),
  },
  name: "StatusChangeFromFilter",
})
export default class StatusChangeFromFilter extends Vue {
  status!: EquipmentStatuses;
  updateFilters!: ({
    selectedDateFilter,
    criticalCheckboxFilter,
    selectedStatusFilter,
    selectedEquipmentFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
    statusCheckboxFilter,
  }: {
    selectedDateFilter: string | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    selectedEquipmentFilter: string[] | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;
  getSelectedDateFilter!: string;
  getCriticalCheckboxFilter!: boolean;
  getSelectedStatusFilter!: EquipmentStatuses[];
  getSelectedEquipmentFilter!: string[];
  getSelectedEquipmentAlertsFilter!: string[];
  getSelectedSectorFilter!: string[];
  getStatusCheckboxFilter!: boolean;

  set selectedDateFilter(selectedDateFilter: string | undefined) {
    this.updateFilters({
      selectedDateFilter,
      criticalCheckboxFilter: this.getCriticalCheckboxFilter,
      selectedStatusFilter: this.getSelectedStatusFilter,
      selectedEquipmentFilter: this.getSelectedEquipmentFilter,
      selectedEquipmentAlertsFilter: this.getSelectedEquipmentAlertsFilter,
      selectedSectorFilter: this.getSelectedSectorFilter,
      statusCheckboxFilter: this.getStatusCheckboxFilter,
    });
  }

  get selectedDateFilter(): string | undefined {
    return this.getSelectedDateFilter;
  }
}
