
import { Options, Vue } from "vue-class-component";
import SiteHomepage from "../components/site-homepage/SiteHomepage.vue";
import ProductReferenceCard from "../components/site-homepage/ProductReferenceCard.vue";

@Options({
  components: {
    SiteHomepage,
    ProductReferenceCard,
  },
  name: "Home",
})
export default class Home extends Vue {}
