import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createBlock(_component_q_tooltip, {
    anchor: "top middle",
    self: "bottom middle",
    class: "text-body2",
    offset: [10, 10]
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.tooltipText), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }))
}