
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

@Options({
  computed: {
    ...mapGetters(["getAuthInfoMSGraph", "getUserPicture"]),
  },
  name: "userAvatar",
})
export default class Avatar extends Vue {}
