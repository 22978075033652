import { Ref } from "vue";
import { EquipmentCommentFile } from "./equipment-comment-file";
import { EquipmentStatuses } from "./equipment-status";
import { StatusEventEnum } from "./status-events";

export enum InformationType {
  OIL = "oil",
  VIBRATION = "vibration",
  PERFORMANCE = "performance",
  OTHER = "other",
  STATUS_CHANGE = "status_change",
  OFFSHORE = "offshore",
  INSULATION = "insulation",
  REPORT = "report",
  DOWNGRADED_SITUATION = "downgraded_situation",
  RAID = "raid",
  DRY_GAS_SEAL = "dry_gas_seal",
  SERVICE_BULLETINS = "service_bulletins",
  SERIAL_NUMBER = "serial_number",
}

export enum AnalysisType {
  ROUTINE = "routine",
  ISSUE = "issue",
}

export enum QualityKPI {
  CRITICAL = "critical",
  GOOD = "good",
  FAIR = "fair",
}

export enum FailureType {
  CORONA = "corona",
  ARCING = "arcing",
  THERMAL_DECOMPOSITION = "thermal_decomposition",
  LOW_TEMPERATURE_OVERHEATING = "low_temperature_overheating",
  OVERHEATING_LESS_THAN_700 = "overheating_less_than_700",
  THERMAL_FAULT_OVER_THAN_700 = "thermal_fault_over_than_700",
}

export enum OilType {
  MLO = "mineral_lube_oil",
  SLO = "synthetic_lube_oil",
  HYD_OIL = "hydraulic_oil",
  LUBE_OIL = "lube_oil",
  GREASE = "grease",
  SEAL_OIL = "seal_oil",
}

export enum DryGasSealType {
  S1DE = "stage_1_DE",
  S1NDE = "stage_1_NDE",
  S2DE = "stage_2_DE",
  S2NDE = "stage_2_NDE",
  S3DE = "stage_3_DE",
  S3NDE = "stage_3_NDE",
}

export type EquipmentComment = EquipmentCommentBase &
  ElecOilAnalysis &
  ElecInsulationAnalysis;

export type EquipmentCommentBase = {
  id?: number;
  date: string;
  comment: Ref<string>;
  woNumber: number;
  woStatus?: Ref<string>;
  equipmentCommentFiles: EquipmentCommentFile[];
  author: Ref<string>;
  isLastCommentOfStatus?: boolean;
  status?: EquipmentStatusWithCommentId;
  statusStartDate?: string;
  statusEndDate?: string;
  event?: StatusEventEnum;
  informationType: InformationType;
  analysisType?: AnalysisType;
  qualityKpi?: QualityKPI;
  oilType?: OilType;
  dryGasSealComponent?: DryGasSealType;
  addToWeeklyReport: boolean;
  isAlert: boolean;
};

export type EquipmentStatusWithCommentId = {
  id: number;
  date: string;
  status: EquipmentStatuses;
  event: StatusEventEnum;
  equipmentMajorFailure: boolean | undefined;
  gasOrOilLeak: boolean | undefined;
  fireConfirmed: boolean | undefined;
  equipmentDetailsId: number | undefined;
  tradeTypeId: number | undefined;
  productionShortfall: number | undefined;
  waterInjectShortfall: number | undefined;
  flaring: number | undefined;
  commentId: number;
  serialNumber?: string | null;
  installationDate?: string | null;
};

export type ElecOilAnalysis = {
  elecMoistureWater?: number;
  elecDissolvedGasHydrogen?: number;
  elecDissolvedGasMethane?: number;
  elecDissolvedGasAcethylene?: number;
  elecDissolvedGasEthylene?: number;
  elecDissolvedGasEthane?: number;
  elecDissolvedGasMonoxide?: number;
  elecDissolvedGasDioxide?: number;
  elecDissolvedGasTdcg?: number;
  failureType?: FailureType[];
};

export type ElecInsulationAnalysis = {
  mean?: number;
  standardDeviation?: number;
  ratioMeanStandardDeviation?: number;
};
