import { UserRole } from "@/types/user-info";

export function isUserOffshore(role: UserRole): boolean {
  return role === UserRole.OFFSHORE;
}

export function isUserAdmin(role: UserRole): boolean {
  return role === UserRole.ADMIN;
}

export function isUserOnshore(role: UserRole): boolean {
  return role === UserRole.ONSHORE;
}

export function isUserGuest(role: UserRole): boolean {
  return role === UserRole.GUEST;
}

export function isUserUnknown(role: UserRole): boolean {
  return role === UserRole.UNKNOWN;
}
