
import { mapActions } from "vuex";
import { Options, Vue } from "vue-class-component";

export enum PopinType {
  LINK,
  FILE,
  OIL,
  VIBRATION,
  PRODUCT_REFERENCE,
}

@Options({
  name: "AddButton",
  props: {
    popinType: Number,
  },
  methods: {
    ...mapActions([
      "displayAddLinkPopin",
      "displayAddOilAnalysisPopin",
      "displayAddVibrationAnalysisPopin",
      "displayAddOrUpdateProductReferencePopin",
    ]),
  },
})
export default class AddButton extends Vue {
  displayAddLinkPopin!: (displayAddLinkPopin: boolean) => void;
  displayAddOilAnalysisPopin!: (displayAddOilAnalysisPopin: boolean) => void;
  displayAddVibrationAnalysisPopin!: (
    displayAddVibrationAnalysisPopin: boolean
  ) => void;
  displayAddOrUpdateProductReferencePopin!: (
    displayAddOrUpdateProductReferencePopin: boolean
  ) => void;
  popinType!: PopinType;

  displayPopin(): void {
    switch (this.popinType) {
      case PopinType.LINK:
        this.displayAddLinkPopin(true);
        break;
      case PopinType.OIL:
        this.displayAddOilAnalysisPopin(true);
        break;
      case PopinType.VIBRATION:
        this.displayAddVibrationAnalysisPopin(true);
        break;
      case PopinType.PRODUCT_REFERENCE:
        this.displayAddOrUpdateProductReferencePopin(true);
        break;
    }
  }
}
