import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "chart" }
const _hoisted_2 = { class: "q-ma-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dateRange), 1 /* TEXT */),
    _createVNode(_component_apexchart, {
      "data-test": "timeline-chart",
      options: _ctx.chartOptions,
      series: _ctx.serie,
      height: "150px",
      onBeforeResetZoom: _ctx.beforeResetZoom,
      onZoomed: _ctx.zoomed,
      onDataPointSelection: _ctx.onClickChart
    }, null, 8 /* PROPS */, ["options", "series", "onBeforeResetZoom", "onZoomed", "onDataPointSelection"])
  ]))
}