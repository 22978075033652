import { treatErrors } from "@/helpers/http-status-helper";
import { UserInfo } from "@/types/user-info";
import axios from "axios";

export default class UserApi {
  static async getUserInfo(): Promise<{
    user: UserInfo | undefined;
    httpStatus: number;
  }> {
    try {
      const response = await axios.get("/api/v1/user");
      return { user: response.data, httpStatus: response.status };
    } catch (err: any) {
      return { user: undefined, httpStatus: treatErrors(err) };
    }
  }
}
