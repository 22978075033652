
import { Options, Vue } from "vue-class-component";
import {
  DryGasSealType,
  EquipmentComment,
  InformationType,
  OilType,
  QualityKPI,
} from "../../types/equipment-comment";
import {
  getClassForQualityKpiChip,
  getQualityKpiChipIcon,
  convertEnumInformationType,
  convertEnumOilType,
} from "../../helpers/equipment-comment-helper";
import _ from "lodash";
import { Equipment } from "../../types/equipment";
import { mapActions, mapGetters } from "vuex";

@Options({
  props: {
    informationType: {
      type: String,
      required: false,
    },
    dryGasSealType: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      qualityKpi: "",
    };
  },
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
  },
  methods: {
    ...mapActions([
      "displayEditCommentPopin",
      "updateEquipmentCommentDetailsState",
    ]),
  },
  name: "QualityKpi",
})
export default class QualityKpi extends Vue {
  qualityKpi?: QualityKPI;
  linkedComment!: EquipmentComment | undefined;
  informationTypeLabel!: string | undefined;
  getEquipmentDetails!: Equipment;
  informationType!: InformationType;
  dryGasSealType!: DryGasSealType;
  updateEquipmentCommentDetailsState!: (
    equipmentComment: EquipmentComment
  ) => void;
  displayEditCommentPopin!: ({
    displayEditCommentPopin,
    isEditPopinReadOnly,
  }: any) => void;

  get getLastStatusAnalysis(): {
    qualityKpi: QualityKPI | undefined;
    informationTypeLabel: string | undefined;
  } {
    let commentsFiltered;
    if (this.informationType === InformationType.DRY_GAS_SEAL) {
      commentsFiltered = _.orderBy(
        _.filter(
          this.getEquipmentDetails?.equipmentComments,
          (comment: EquipmentComment) => {
            return comment.dryGasSealComponent == this.dryGasSealType;
          }
        ),
        "date",
        "desc"
      );
    } else {
      commentsFiltered = _.orderBy(
        _.filter(
          this.getEquipmentDetails?.equipmentComments,
          (comment: EquipmentComment) => {
            return comment.informationType == this.informationType;
          }
        ),
        "date",
        "desc"
      );
    }

    if (this.informationType === InformationType.DRY_GAS_SEAL) {
      if (commentsFiltered.length > 0) {
        this.qualityKpi = commentsFiltered[0].qualityKpi;
        this.linkedComment = commentsFiltered[0];
      } else {
        this.qualityKpi = undefined;
        this.linkedComment = undefined;
      }
      this.informationTypeLabel = convertEnumInformationType(
        this.dryGasSealType
      );
    } else if (this.informationType === InformationType.OIL) {
      this.getOilTypeAndQualityKPI(commentsFiltered);
    } else {
      if (commentsFiltered.length > 0) {
        this.qualityKpi = commentsFiltered[0].qualityKpi;
        this.linkedComment = commentsFiltered[0];
      } else {
        this.qualityKpi = undefined;
        this.linkedComment = undefined;
      }
      this.informationTypeLabel = convertEnumInformationType(
        this.informationType
      );
    }
    return {
      qualityKpi: this.qualityKpi,
      informationTypeLabel: this.informationTypeLabel,
    };
  }

  getOilTypeAndQualityKPI(commentsFiltered: EquipmentComment[]): void {
    const groupedByOilTypes = _.mapValues(
      _.groupBy(commentsFiltered, "oilType"),
      (v) => _.orderBy(v, (v) => new Date(v.date), "desc")
    );

    const allTypes = [];
    if (groupedByOilTypes[OilType.MLO])
      allTypes.push(groupedByOilTypes[OilType.MLO][0]);
    if (groupedByOilTypes[OilType.SLO])
      allTypes.push(groupedByOilTypes[OilType.SLO][0]);
    if (groupedByOilTypes[OilType.HYD_OIL])
      allTypes.push(groupedByOilTypes[OilType.HYD_OIL][0]);
    if (groupedByOilTypes[OilType.LUBE_OIL])
      allTypes.push(groupedByOilTypes[OilType.LUBE_OIL][0]);
    if (groupedByOilTypes[OilType.GREASE])
      allTypes.push(groupedByOilTypes[OilType.GREASE][0]);
    if (groupedByOilTypes[OilType.SEAL_OIL])
      allTypes.push(groupedByOilTypes[OilType.SEAL_OIL][0]);

    if (allTypes.length === 0) {
      this.qualityKpi = commentsFiltered[0]?.qualityKpi;
      this.linkedComment = commentsFiltered[0];
      this.informationTypeLabel = convertEnumOilType(
        commentsFiltered[0]?.qualityKpi
      );
      return;
    }
    const criticalOilComments = allTypes.filter(
      (equipmentComment: EquipmentComment) => {
        return equipmentComment.qualityKpi === QualityKPI.CRITICAL;
      }
    );
    if (criticalOilComments.length > 0) {
      this.qualityKpi = QualityKPI.CRITICAL;
      this.linkedComment = _.maxBy(
        criticalOilComments,
        (v) => new Date(v.date)
      );
      this.informationTypeLabel = convertEnumOilType(
        this.linkedComment?.oilType
      );
    } else {
      const fairOilComments = allTypes.filter(
        (equipmentComment: EquipmentComment) => {
          return equipmentComment.qualityKpi === QualityKPI.FAIR;
        }
      );
      if (fairOilComments.length > 0) {
        this.qualityKpi = QualityKPI.FAIR;
        this.linkedComment = _.maxBy(fairOilComments, (v) => new Date(v.date));
        this.informationTypeLabel = convertEnumOilType(
          this.linkedComment?.oilType
        );
      } else {
        this.qualityKpi = QualityKPI.GOOD;
        this.linkedComment = commentsFiltered[0];
        this.informationTypeLabel = convertEnumOilType(
          this.linkedComment?.oilType
        );
      }
    }
  }

  get getClassQualityIcon(): any {
    return getClassForQualityKpiChip(this.qualityKpi);
  }

  get icon(): string {
    return getQualityKpiChipIcon(this.qualityKpi);
  }

  async editEquipmentComment(): Promise<void> {
    if (this.linkedComment) {
      this.updateEquipmentCommentDetailsState(this.linkedComment);
      this.displayEditCommentPopin({
        displayEditCommentPopin: true,
        isEditPopinReadOnly: true,
      });
    }
  }
}
