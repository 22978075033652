import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-md q-pt-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_checkbox, {
      modelValue: _ctx.criticalCheckboxFilter,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criticalCheckboxFilter) = $event)),
      "data-test": "critical-selector",
      label: "Critical only"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}