import {
  AnalysisType,
  FailureType,
  InformationType,
  OilType,
  QualityKPI,
} from "./equipment-comment";
import { EquipmentCommentFile } from "./equipment-comment-file";
import { StatusEventEnum } from "./status-events";

export enum EquipmentStatuses {
  UNSCH = "unscheduled",
  SCH = "scheduled",
  RUN_LIMITED = "run_limited",
  RUN = "run",
  ABNR = "abnr",
}

export type EquipmentStatus = {
  id: number;
  date: string;
  status: EquipmentStatuses;
  event: StatusEventEnum;
  equipmentMajorFailure: boolean | undefined;
  gasOrOilLeak: boolean | undefined;
  fireConfirmed: boolean | undefined;
  equipmentDetailsId: number | undefined;
  tradeTypeId: number | undefined;
  productionShortfall: number | undefined;
  waterInjectShortfall: number | undefined;
  flaring: number | undefined;
  comment: CommentSection;
  commentId: number | undefined;
  serialNumber: string | null;
  installationDate?: string | null;
  automaticStatus: boolean | undefined;
};

export type CommentSection = {
  id?: number;
  date: string;
  comment: string;
  woNumber: string;
  woStatus?: string;
  equipmentCommentFiles: EquipmentCommentFile[];
  author: string;
  isLastCommentOfStatus?: boolean;
  status?: EquipmentStatuses;
  statusStartDate?: string;
  statusEndDate?: string;
  event?: StatusEventEnum;
  informationType: InformationType;
  analysisType?: AnalysisType;
  qualityKpi?: QualityKPI;
  oilType?: OilType;
  addToWeeklyReport: boolean;
  isAlert: boolean;
  elecMoistureWater?: number;
  elecDissolvedGasHydrogen?: number;
  elecDissolvedGasMethane?: number;
  elecDissolvedGasAcethylene?: number;
  elecDissolvedGasEthylene?: number;
  elecDissolvedGasEthane?: number;
  elecDissolvedGasMonoxide?: number;
  elecDissolvedGasDioxide?: number;
  elecDissolvedGasTdcg?: number;
  failureType?: FailureType[];
};
