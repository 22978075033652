import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "col-10 q-mx-md q-mt-md" }
const _hoisted_3 = { class: "col-10 q-mx-md q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ReportingFilters = _resolveComponent("ReportingFilters")!
  const _component_ReportingCard = _resolveComponent("ReportingCard")!
  const _component_ReportExport = _resolveComponent("ReportExport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, {
      "data-test": "reporting-title",
      title: "Reporting",
      page: _ctx.pageEnum.Reporting
    }, null, 8 /* PROPS */, ["page"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ReportingFilters)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_ReportingCard, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.getReportingView !== _ctx.ReportingViewType.EXPORT]
      ]),
      _withDirectives(_createVNode(_component_ReportExport, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.getReportingView === _ctx.ReportingViewType.EXPORT]
      ])
    ])
  ]))
}