import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "text-h6 text-blue-10",
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_HttpErrorDialog = _resolveComponent("HttpErrorDialog")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    class: "main-page",
    view: "lHh Lpr fff"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Header, { account: _ctx.account }, null, 8 /* PROPS */, ["account"]),
      (_ctx.account)
        ? (_openBlock(), _createBlock(_component_q_page_container, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_q_page, { padding: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view),
                  _createVNode(_component_HttpErrorDialog)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.account)
        ? (_openBlock(), _createBlock(_component_q_page_container, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.pageLoadingInfo), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_Footer, { account: _ctx.account }, null, 8 /* PROPS */, ["account"])
    ]),
    _: 1 /* STABLE */
  }))
}