
import { Options, Vue } from "vue-class-component";
import type { EquipmentAndStatistics } from "../../types/equipment-statistics";
import { mapActions, mapGetters } from "vuex";
import EquipmentSystem from "../equipment/EquipmentSystem.vue";
import EquipmentModel from "../equipment/EquipmentModel.vue";
import EquipmentTag from "../equipment/EquipmentTag.vue";
import { ReportingViewType } from "../../types/reporting-view";
import _ from "lodash";
import fp from "lodash/fp";
import { Equipment } from "../../types/equipment";
import EditEquipmentCommentPopin from "../equipment-details/EditEquipmentCommentPopin.vue";
import { EquipmentComment } from "../../types/equipment-comment";
import moment from "moment-timezone";
import { UserRole } from "../../types/user-info";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  components: {
    EquipmentSystem,
    EquipmentModel,
    EquipmentTag,
    EditEquipmentCommentPopin,
  },
  methods: {
    ...mapActions([
      "reportPageLoaded",
      "reportReset",
      "equipmentDetailsReset",
      "updateCommentsInReportingList",
      "reportsUpdated",
      "displayHttpErrorDialog",
      "reportPageLoadedKPI",
    ]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentStatisticsList",
      "getReportingView",
      "getEquipmentListForWeeklyReport",
      "getSelectedSite",
      "getEquipmentDetails",
      "EquipmentDetails",
      "getSelectedSite",
      "getUserRole",
      "getSelectedCountryBloc",
      "getIsReportingWeeklyReportExpanded",
    ]),
  },
  data() {
    return {
      expansion_items: {},
      startDate: "",
      endDate: "",
      showPageLoadingInfo: false,
      pageLoadingInfo: "Loading weekly report data...",
    };
  },
  watch: {
    getIsReportingWeeklyReportExpanded(newVal) {
      Object.keys(this.expansion_items).forEach((key) => {
        this.expansion_items[key] = newVal;
      });
    },
  },
  name: "ReportingCard",
})
export default class ReportingCard extends Vue {
  ReportingViewType = ReportingViewType;
  getEquipmentStatisticsList!: EquipmentAndStatistics;
  getEquipmentListForWeeklyReport!: Equipment[];
  getReportingView!: string;
  pageLoadingInfo!: string;
  showPageLoadingInfo!: boolean;
  getIsReportingWeeklyReportExpanded!: boolean;
  reportPageLoaded!: () => void;
  reportReset!: () => void;
  expansion_items!: { [key: string]: boolean };
  equipmentDetailsReset!: () => void;
  updateCommentsInReportingList!: ({
    equipmentId,
    equipmentComment,
  }: {
    equipmentId: number;
    equipmentComment: EquipmentComment;
  }) => void;
  reportsUpdated!: ({ fromDate, toDate }: any) => Promise<void>;
  reportPageLoadedKPI!: ({ fromDate, toDate }: any) => Promise<void>;
  getSelectedSite!: string;
  updateEquipmentDetails!: (equipment: Equipment) => Promise<void>;
  getEquipmentDetails!: Equipment;
  equipment!: Equipment;
  manualSortList = [
    "Critical and safety systems",
    "Critical systems",
    "Safety systems",
    "Electric energy",
    "Produced gas processing",
    "Produced liquid processing",
    "Produced water system",
    "Water processing",
    "Subsea processing",
    "Chemical injection",
    "Drain system",
    "Other utilities",
    "Electrical equipment",
    "HVAC",
    "Lifting equipment",
    "Marine",
    "Buoy",
    "Drilling equipment",
    "Dan Bravo",
    "Dagmar",
    "Kraka",
    "Rolf",
    "Roar",
    "Svend",
    "Tyra South East A",
    "Tyra South East B",
    "Valdemar",
  ];
  startDate!: Date;
  endDate!: Date;
  getUserRole!: UserRole;
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  getSelectedCountryBloc!: string;

  get equipmentsGroupedBySystemAndModelAndDesc(): EquipmentAndStatistics | any {
    if (this.getReportingView === ReportingViewType.WEEKLY) {
      let eqList = this.getEquipmentListForWeeklyReport;

      eqList = eqList.filter(
        (eq: Equipment) =>
          eq.hasWeeklyReport || eq.weeklyReportComments?.length > 0
      );
      const equipmentsGroupedBySystem = _.groupBy(
        eqList,
        "equipmentModel.system"
      );

      const equipmentsGroupedBySystemManualOrdered = Object.keys(
        equipmentsGroupedBySystem
      )
        .sort(
          (a, b) =>
            this.manualSortList.indexOf(a) - this.manualSortList.indexOf(b)
        )
        .reduce((obj: any, key) => {
          obj[key] = equipmentsGroupedBySystem[key];
          return obj;
        }, {});

      let equipmentsGroupedBySystemOrderedTagId: any = {};
      Object.keys(equipmentsGroupedBySystemManualOrdered).forEach((key) => {
        equipmentsGroupedBySystemOrderedTagId[key] = _.sortBy(
          equipmentsGroupedBySystemManualOrdered[key],
          "tagId"
        );
      });
      const equipmentsGroupedBySystemandModelandDesc = _.mapValues(
        equipmentsGroupedBySystemOrderedTagId,
        fp.groupBy(function (obj: Equipment) {
          return [obj.equipmentModel.name + "(" + obj.name_description + ")"];
        })
      );

      return equipmentsGroupedBySystemandModelandDesc;
    } else if (this.getReportingView === ReportingViewType.BARCHART) {
      const equipmentsGroupedBySystemManualOrdered: EquipmentAndStatistics =
        Object.keys(this.getEquipmentStatisticsList)
          .sort(
            (a, b) =>
              this.manualSortList.indexOf(a) - this.manualSortList.indexOf(b)
          )
          .reduce((obj: any, key: any) => {
            obj[key] = this.getEquipmentStatisticsList[key];
            return obj;
          }, {});
      return equipmentsGroupedBySystemManualOrdered;
    }
    return {};
  }

  async mounted(): Promise<void> {
    this.reportPageLoaded();
    if (this.getReportingView === ReportingViewType.WEEKLY) {
      this.showPageLoadingInfo = true;
      this.pageLoadingInfo = "Loading weekly report data...";
      this.$q.loading.show();
      const toDate = moment().toDate();
      const fromDate = moment(toDate).subtract(12, "months").toDate();
      //this await function results in slowing down the reporting page.
      this.pageLoadingInfo =
        "Loading comments and 12 months avl/rel data for each equipment in weekly report section...";
      await this.reportsUpdated({
        fromDate,
        toDate,
      });
      this.pageLoadingInfo = "data populated...";
      Object.keys(this.equipmentsGroupedBySystemAndModelAndDesc).forEach(
        (system: string) => {
          this.expansion_items["weekly-report-" + system] =
            this.getIsReportingWeeklyReportExpanded;
        }
      );
      this.showPageLoadingInfo = false;
      this.$q.loading.hide();
    }
  }

  beforeUnmount(): void {
    this.reportReset();
  }

  expand(): void {
    window.dispatchEvent(new Event("resize"));
  }

  onClosedEditEquipmentCommentPopin(): void {
    this.equipmentDetailsReset();
  }

  updatedEquipmentComment(
    equipmentId: number,
    equipmentComment: EquipmentComment
  ): void {
    this.updateCommentsInReportingList({ equipmentId, equipmentComment });
  }
  startDateOptions(date: any): boolean {
    const currentDate = moment(moment()).format("YYYY/MM/DD");
    if (this.endDate != null && this.endDate.toLocaleString() != "") {
      let localEndDate = new Date(this.endDate);
      const minStartDatePossible = moment(
        localEndDate.setMonth(localEndDate.getMonth() - 18)
      ).format("YYYY/MM/DD");
      return (
        date <= currentDate &&
        date >= minStartDatePossible &&
        date <= this.endDate
      );
    } else {
      return date <= currentDate;
    }
  }
  endDateOptions(date: any): boolean {
    let endDate = new Date(this.startDate);
    const currentDate = moment(moment()).format("YYYY/MM/DD");
    const endDates = moment(endDate.setMonth(endDate.getMonth() + 18)).format(
      "YYYY/MM/DD"
    );
    let startDateFormatted = moment(this.startDate).format("YYYY/MM/DD");
    return (
      date >= startDateFormatted && date <= currentDate && date <= endDates
    );
  }
  async apply(): Promise<void> {
    if (this.startDate && this.endDate) {
      this.$q.loading.show();
      const fromDate = this.startDate;
      const toDate = this.endDate;
      await this.reportPageLoadedKPI({
        fromDate,
        toDate,
      });
      this.$q.loading.hide();
    }
  }
}
