import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "status-selector row" }
const _hoisted_2 = {
  key: 0,
  class: "q-ml-md q-mt-lg",
  style: {"font-weight":"bold"},
  "data-test": "serial-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EquipmentStatusIcon = _resolveComponent("EquipmentStatusIcon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_AddLastStatusDialog = _resolveComponent("AddLastStatusDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_select, {
      modelValue: _ctx.selectedStatus,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatus) = $event)),
      "data-test": "status-selector",
      style: {"min-width":"200px"},
      options: _ctx.equipmentStatus,
      "option-label": "label",
      "option-value": "value",
      "label-color": "white",
      outlined: "",
      standout: "",
      "map-options": "",
      "emit-value": "",
      "bottom-slots": "",
      class: _normalizeClass(_ctx.getClass()),
      disable: _ctx.isUserUnknownOrGuest()
    }, {
      prepend: _withCtx(() => [
        _createVNode(_component_EquipmentStatusIcon, {
          status: 
            _ctx.getEquipmentDetails?.lastEquipmentStatus &&
            _ctx.getEquipmentDetails.lastEquipmentStatus.status
          
        }, null, 8 /* PROPS */, ["status"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options", "class", "disable"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.getClassForLegend())
    }, _toDisplayString(_ctx.getLegendforStatus()) + " from " + _toDisplayString(_ctx.startDate), 3 /* TEXT, CLASS */),
    (_ctx.showSerialNumber())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " S/N: " + _toDisplayString(_ctx.getLastSerialNumberFn.serialNumber
          ? _ctx.getLastSerialNumberFn.serialNumber
          : "unknown") + " since " + _toDisplayString(_ctx.getLastSerialNumberFn.installationDate
          ? _ctx.getLastSerialNumberFn.installationDate
          : "unknown"), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_AddLastStatusDialog)
  ]))
}