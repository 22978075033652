import axios from "axios";

export enum HttpErrorEnum {
  HTTP_400 = "An error has occured, please try again and if the error persists, contact the administrators",
  HTTP_500 = "An error has occured, please try again and if the error persists, contact the administrators",
  HTTP_401 = "You are not authenticated for OneCBM, please try to refresh the page, or contact the administrators.",
  HTTP_403 = "You are not authenticated for OneCBM, please try to refresh the page, or contact the administrators.",
  HTTP_408 = "Connection timeout, please confirm that your network is available.",
  HTTP_404 = "Not found.",
  NETWORK = " An error has occured, please confirm your network connection and try again, if the error persists, contact the administrators",
  DEFAULT = " An error has occured, please try again and if the error persists, contact the administrators.",
}

export function getMessageByStatusCode(httpStatus: number): string {
  switch (httpStatus) {
    case 400:
      return HttpErrorEnum.HTTP_400;
    case 500:
      return HttpErrorEnum.HTTP_500;
    case 401:
      return HttpErrorEnum.HTTP_401;
    case 403:
      return HttpErrorEnum.HTTP_403;
    case 404:
      return HttpErrorEnum.HTTP_404;
    case 408:
      return HttpErrorEnum.HTTP_408;
    case 0:
      return HttpErrorEnum.NETWORK;
    default:
      return HttpErrorEnum.DEFAULT;
  }
}

export function isSuccess(httpStatus: number): boolean {
  return httpStatus >= 200 && httpStatus < 300;
}

export function treatErrors(err: any): number {
  if (axios.isAxiosError(err)) {
    if (err.response) return err.response?.status;
    else return 0;
  } else {
    return 0;
  }
}
