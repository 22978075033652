import { FeedbackEnum } from "@/types/equipment-ml-status";

export const feedbacks = [
  {
    label: "Real Alert",
    value: FeedbackEnum.REAL,
  },
  {
    label: "Normal behaviour",
    value: FeedbackEnum.NORMAL_BEHAVIOR,
  },
  {
    label: "Spurious",
    value: FeedbackEnum.SPURIOUS,
  },
  {
    label: "Empty/Unknown",
    value: undefined,
  },
];

export const alertFilterOptions = [
  {
    label: "Real Alert",
    value: FeedbackEnum.REAL,
  },
  {
    label: "Normal behaviour",
    value: FeedbackEnum.NORMAL_BEHAVIOR,
  },
  {
    label: "Spurious",
    value: FeedbackEnum.SPURIOUS,
  },
  {
    label: "Empty/Unknown",
    value: undefined,
  },
];
