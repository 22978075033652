
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    system: {
      type: Object,
      required: false,
    },
  },
  name: "EquipmentSystem",
})
export default class EquipmentSystem extends Vue {}
