import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-test": "information-type-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "text-weight-thin cursor-pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editEquipmentComment && _ctx.editEquipmentComment(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getLastStatusAnalysis.informationTypeLabel), 1 /* TEXT */),
    _createVNode(_component_q_chip, {
      "data-test": "info-kpi",
      square: "",
      icon: _ctx.icon,
      class: _normalizeClass([_ctx.getClassQualityIcon, "text-uppercase"]),
      label: _ctx.getLastStatusAnalysis.qualityKpi,
      "text-color": "white"
    }, null, 8 /* PROPS */, ["icon", "class", "label"])
  ]))
}