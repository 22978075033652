
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import EquipmentSelector from "./EquipmentSelector.vue";

@Options({
  components: {
    EquipmentSelector,
  },
  computed: {
    ...mapGetters(["getEquipmentDetails"]),
  },
  name: "Title",
})
export default class EquipmentTitle extends Vue {}
